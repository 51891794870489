import React from "react";
//import DateTimePicker from "react-datetime-picker";
import { createAuction } from "../../models/auctions";
import { getAllMasks } from "../../models/masks";
import { getAllGroups, getGroup } from "../../models/groups";
import swal from "sweetalert";
import i18n from "../../i18n";
import { Map } from "../helpers/map";
import ToggleButton from "react-toggle-button";
import { getDepartments } from "../../models/departments";
import { getLocations } from "../../models/locations";

const lng = localStorage.getItem("I18N_LANGUAGE");
export class CreateAuctionGeneral extends React.Component {
  constructor() {
    super();
    this.state = {
      lat: 23.588,
      lng: 58.3829,
      input: {
        is_extra_time: false,
        is_featured: true,
        is_zakath: false,
        auto_approval: false,
        is_vat_included: false,
        is_service_charge_included: false,
        is_service_charge_vat_included: false,
      },
      tempArray: {},
      errors: {},
      masks: {},
      groups: [],
      departments: [],
      locations: [],
      categories: {},
      organizations: {},
      enquiries: [{ label: "", value: "" }],
      jsonLabel: "",
      jsonValue: "",
      categoryList: "",
      places: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.toggleZakath = this.toggleZakath.bind(this);
    this.toggleFeatured = this.toggleFeatured.bind(this);
    this.toggleAutoApproval = this.toggleAutoApproval.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let input = this.state.input;
    input.category = this.props.category;
    input.organization = this.props.organization;
    this.loadAllMasks();
    this.loadAllGroups();
    this.loadAllDeps();
    this.loadAllLocs();
  }

  async loadAllMasks() {
    const response = await getAllMasks();
    let masks = response.data;
    this.setState({ masks });
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAllDeps() {
    const response = await getDepartments();
    let departments = response.data;
    this.setState({ departments });
  }

  async loadAllLocs() {
    const response = await getLocations();
    let locations = response.data;
    this.setState({ locations });
  }

  async getGroup(id) {
    const response = await getGroup(id);
    let group = response.data;
    let input = this.state.input;
    input.start_date = group.start_date;
    input.end_date = group.end_date;
    input.reg_start_date = group.reg_start_date;
    input.reg_end_date = group.reg_end_date;
    input.description = group.description;
    input.description_ar = group.description_ar;
    input.terms = "";
    input.terms_arabic = "";
    input.payment_type = group.payment_type;
    input.payment_amount = group.amount;
    input.is_extra_time = group.is_extra_time;
    input.extra_time = group.extra_time;
    input.has_auto_bidding = group.has_auto_bidding;
    input.is_vat_included = group.is_vat_included;
    input.is_service_charge_included = group.is_service_charge_included;
    input.is_service_charge_vat_included = group.is_service_charge_vat_included;
    this.setState({ input });
  }

  handleChange(event) {
    let { input, locations } = this.state;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.getGroup(event.target.value);
    }
    if (event.target.name === "location_id") {
      const loc = locations.find(
        (location) => Number(location.id) === Number(event.target.value)
      );
      input.location = loc.name;
      input.location_ar = loc.name_ar;
      input.latitude = loc.latitude;
      input.longitude = loc.longitude;
      this.setState({ input, lat: loc.latitude, lng: loc.longitude });
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  toggleChange = () => {
    let input = this.state.input;
    input.is_extra_time = !this.state.input.is_extra_time;
    this.setState({
      input,
    });
  };

  toggleZakath = () => {
    let input = this.state.input;
    input.is_zakath = !this.state.input.is_zakath;
    this.setState({
      input,
    });
  };

  toggleFeatured = () => {
    let input = this.state.input;
    input.is_featured = !this.state.input.is_featured;
    this.setState({
      input,
    });
  };

  toggleAutoApproval = () => {
    let input = this.state.input;
    input.auto_approval = !this.state.input.auto_approval;
    this.setState({
      input,
    });
  };

  handleSelect = (selectedOption) => {
    let updateExtraTime = "";
    const checkCategory = (obj) => obj.label === "phoneNumber";
    if (!selectedOption.some(checkCategory)) {
      updateExtraTime = true;
    }
    const categoryArr = selectedOption.map((opt) => opt.value);
    let input = this.state.input;
    input.is_extra_time = updateExtraTime;
    this.setState({ categoryList: categoryArr, input });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.enquiries = this.state.enquiries;
      payload.categories = this.state.categoryList;
      payload.category = this.props.category;
      payload.organization = this.props.organization;
      payload.latitude = this.state.lat;
      payload.longitude = this.state.lng;
      const response = await createAuction(payload);
      if (response && response.data && response.success) {
        swal(i18n.t("success"), response.message, "success");
        setTimeout(() => {
          //this.context.router.push("/image_uploader/" + response.data.id);
          window.location.href = "/image_uploader/" + response.data.id;
        }, 3000);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  getLocation(lat, lng) {
    this.setState({ lat: lat, lng: lng });
  }

  render() {
    //let masks = this.state.masks;
    let { groups, departments, locations } = this.state;
    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-md-12">
          <form
            id="UpdateProfile"
            className="mb-0"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      {i18n.t("auction_group")}
                    </label>
                    <select
                      className="form-control"
                      data-placeholder="Select Group"
                      name="group"
                      value={this.state.input.group}
                      onChange={this.handleChange}
                    >
                      <option value="">--{i18n.t("group")}--</option>
                      {groups &&
                        groups.length > 0 &&
                        groups.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {lng === "ar"
                                ? item?.group_name_ar
                                : item.group_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">{i18n.t("department")}</label>
                    <select
                      className="form-control"
                      data-placeholder="Select Department"
                      name="department_id"
                      value={this.state.input.department_id}
                      onChange={this.handleChange}
                    >
                      <option value="">--{i18n.t("department")}--</option>
                      {departments &&
                        departments.length > 0 &&
                        departments.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {lng === "ar" ? item.name_ar : item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">{i18n.t("location")}</label>
                    <select
                      className="form-control"
                      data-placeholder="Select Location"
                      name="location_id"
                      value={this.state.input.location_id}
                      onChange={this.handleChange}
                    >
                      <option value="">--{i18n.t("location")}--</option>
                      {locations &&
                        locations.length > 0 &&
                        locations.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {lng === "ar" ? item.name_ar : item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                {/* <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">{i18n.t("mask")}</label>
                    <select
                      className="form-control"
                      data-placeholder="Select Mask"
                      name="mask"
                      value={this.state.input.selected_mask}
                      onChange={this.handleChange}
                    >
                      <option value="">--{i18n.t("mask")}--</option>
                      {masks.length > 0 &&
                        masks.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.mask_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div> */}

                <div className="col-sm-12 col-md-6">
                  <div className="form-group d-flex pt-4 mb-0">
                    <label className="form-label">
                      {i18n.t("featured")}&nbsp;
                    </label>
                    <ToggleButton
                      inactiveLabel={i18n.t("no")}
                      activeLabel={i18n.t("yes")}
                      value={this.state.input.is_featured}
                      className="form-control m-auto"
                      onToggle={(value) => {
                        let { input } = this.state;
                        input.is_featured = !value;
                        this.setState({
                          input,
                        });
                      }}
                    />
                    {/* <input
                      name="is_zakath"
                      type="checkbox"
                      checked={this.state.input.is_zakath}
                      onChange={this.toggleZakath}
                      style={{ float: "left", margin: "4px" }}
                    />
                    <label
                      className="form-label"
                      style={{ float: "left", marginRight: "1rem" }}
                    >
                      Is Zakath
                    </label> */}
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="row">
                    <div className="d-flex align-items-center gap-3 mx-2">
                      {/* <input
                          name="is_extra_time"
                          type="checkbox"
                          checked={this.state.input.is_extra_time}
                          onChange={this.toggleChange}
                          style={{ float: "left", margin: "4px" }}
                        /> */}
                      <label className="form-label">
                        {i18n.t("extra_time")}
                      </label>
                      <ToggleButton
                        inactiveLabel={i18n.t("no")}
                        activeLabel={i18n.t("yes")}
                        value={this.state.input.is_extra_time}
                        className="form-control m-auto"
                        onToggle={(value) => {
                          let { input } = this.state;
                          input.is_extra_time = !value;
                          this.setState({
                            input,
                          });
                        }}
                      />

                      <input
                        name="extra_time"
                        type="number"
                        className="form-control "
                        value={this.state.input.extra_time}
                        onChange={this.handleChange}
                        placeholder={i18n.t("in_minutes")}
                        disabled={!this.state.input.is_extra_time}
                        style={{ width: "200px" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      {i18n.t("contract_number")}
                    </label>
                    <input
                      type="text"
                      name="contract_number"
                      className="form-control"
                      placeholder={i18n.t("contract_number")}
                      value={this.state.input.contract_number}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      {i18n.t("payment_type")}
                    </label>
                    <select
                      className="form-control"
                      data-placeholder="Select Group"
                      name="payment_type"
                      value={this.state.input.payment_type}
                      onChange={this.handleChange}
                    >
                      <option value="">--Select--</option>
                      <option value="Online">{i18n.t("online")}</option>
                      <option value="Offline">{i18n.t("offline")}</option>
                      <option value="Both">
                        {i18n.t("online")}+{i18n.t("offline")}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      {i18n.t("enroll_amount")}
                    </label>
                    <input
                      type="text"
                      name="payment_amount"
                      className="form-control"
                      placeholder={i18n.t("enroll_amount")}
                      value={this.state.input.payment_amount}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-3">
                  <div className="form-group">
                    <label className="form-label">
                      {i18n.t("auto_approval")}
                    </label>
                    {/* <input
                      name="auto_approval"
                      type="checkbox"
                      checked={this.state.input.auto_approval}
                      onChange={this.toggleAutoApproval}
                      style={{ float: "left", margin: "4px" }}
                    /> */}
                    <ToggleButton
                      inactiveLabel={"No"}
                      activeLabel={"Yes"}
                      value={this.state.input.auto_approval}
                      className="form-control m-auto"
                      onToggle={(value) => {
                        let { input } = this.state;
                        input.auto_approval = !value;
                        this.setState({
                          input,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-3">
                  <div className="form-group">
                    <label className="form-label">
                      {i18n.t("auto_bidding")}
                    </label>
                    <ToggleButton
                      inactiveLabel={"No"}
                      activeLabel={"Yes"}
                      value={this.state.input.has_auto_bidding}
                      className="form-control m-auto"
                      onToggle={(value) => {
                        let { input } = this.state;
                        input.has_auto_bidding = !value;
                        this.setState({
                          input,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="container  mt-5">
                  <div className="row bg bg-light pt-5">
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">{i18n.t("title")}</label>
                        <input
                          type="text"
                          required={true}
                          name="title"
                          className="form-control"
                          placeholder={i18n.t("title")}
                          value={this.state.input.title}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("title_ar")}
                        </label>
                        <input
                          type="text"
                          required={true}
                          name="title_ar"
                          className="form-control"
                          placeholder={i18n.t("title_ar")}
                          value={this.state.input.title_ar}
                          onChange={this.handleChange}
                          style={{ direction: "rtl" }}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("description")}
                        </label>
                        <textarea
                          type="text"
                          required={true}
                          name="description"
                          className="form-control"
                          placeholder={i18n.t("description")}
                          value={this.state.input.description}
                          onChange={this.handleChange}
                          rows={10}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("description")}(Arabic)
                        </label>
                        <textarea
                          type="text"
                          required={true}
                          name="description_ar"
                          className="form-control"
                          placeholder={i18n.t("description")}
                          value={this.state.input.description_ar}
                          onChange={this.handleChange}
                          style={{ direction: "rtl" }}
                          rows={10}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">{i18n.t("price")}</label>
                        <input
                          type="text"
                          name="start_amount"
                          className="form-control"
                          placeholder={i18n.t("price")}
                          value={this.state.input.start_amount}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("gurantee_amount")}
                        </label>
                        <input
                          type="text"
                          name="guarantee_amount"
                          className="form-control"
                          placeholder={i18n.t("gurantee_amount")}
                          value={this.state.input.guarantee_amount}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("target_amount")}
                        </label>
                        <input
                          type="text"
                          name="target_amount"
                          className="form-control"
                          placeholder={i18n.t("target_amount")}
                          value={this.state.input.target_amount}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("visit_amount")}
                        </label>
                        <input
                          type="text"
                          name="visit_amount"
                          className="form-control"
                          placeholder={i18n.t("visit_amount")}
                          value={this.state.input.visit_amount}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("incr_amount")}
                        </label>
                        <input
                          type="text"
                          name="bid_increment"
                          className="form-control"
                          placeholder={i18n.t("incr_amount")}
                          value={this.state.input.bid_increment}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("incr_amount")} ({i18n.t("csv")})
                        </label>
                        <input
                          type="text"
                          name="increment_numbers"
                          className="form-control"
                          placeholder="eg: 100,200,300..."
                          value={this.state.input.increment_numbers}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("start_date")}{" "}
                          {this.state.input.start_date
                            ? "(" + this.state.input.start_date + ")"
                            : ""}
                        </label>

                        <input
                          type="datetime-local"
                          className="form-control"
                          placeholder={i18n.t("start_date")}
                          name="start_date"
                          value={this.state.input.start_date}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("end_date")}{" "}
                          {this.state.input.end_date
                            ? "(" + this.state.input.end_date + ")"
                            : ""}
                        </label>
                        <input
                          type="datetime-local"
                          name="end_date"
                          className="form-control"
                          placeholder={i18n.t("end_date")}
                          value={this.state.input.end_date}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("reg_start_date")}{" "}
                          {this.state.input.reg_start_date
                            ? "(" + this.state.input.reg_start_date + ")"
                            : ""}
                        </label>

                        <input
                          type="datetime-local"
                          className="form-control"
                          placeholder={i18n.t("reg_start_date")}
                          name="reg_start_date"
                          value={this.state.input.reg_start_date}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("reg_end_date")}{" "}
                          {this.state.input.reg_end_date
                            ? "(" + this.state.input.reg_end_date + ")"
                            : ""}
                        </label>
                        <input
                          type="datetime-local"
                          name="reg_end_date"
                          className="form-control"
                          placeholder={i18n.t("reg_end_date")}
                          value={this.state.input.reg_end_date}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-sm-4 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("is_vat_included")}
                            </label>
                            <ToggleButton
                              inactiveLabel={i18n.t("no")}
                              activeLabel={i18n.t("yes")}
                              value={this.state.input.is_vat_included}
                              className="form-control m-auto"
                              onToggle={(value) => {
                                let { input } = this.state;
                                input.is_vat_included = !value;
                                this.setState({
                                  input,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("is_service_charge_included")}
                            </label>
                            <ToggleButton
                              inactiveLabel={i18n.t("no")}
                              activeLabel={i18n.t("yes")}
                              value={
                                this.state.input.is_service_charge_included
                              }
                              className="form-control m-auto"
                              onToggle={(value) => {
                                let { input } = this.state;
                                input.is_service_charge_included = !value;
                                this.setState({
                                  input,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("is_service_charge_vat_included")} -{" "}
                            </label>
                            <ToggleButton
                              inactiveLabel={i18n.t("no")}
                              activeLabel={i18n.t("yes")}
                              value={
                                this.state.input.is_service_charge_vat_included
                              }
                              className="form-control m-auto"
                              onToggle={(value) => {
                                let { input } = this.state;
                                input.is_service_charge_vat_included = !value;
                                this.setState({
                                  input,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("terms_conditions")}
                        </label>
                        <textarea
                          type="text"
                          required={true}
                          name="terms"
                          className="form-control"
                          placeholder={i18n.t("terms_conditions")}
                          value={this.state.input.terms}
                          onChange={this.handleChange}
                          rows={10}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("terms_conditions")} ({i18n.t("arabic")})
                        </label>
                        <textarea
                          type="text"
                          required={true}
                          name="terms_arabic"
                          className="form-control"
                          placeholder={i18n.t("terms_conditions")}
                          value={this.state.input.terms_arabic}
                          onChange={this.handleChange}
                          style={{ direction: "rtl" }}
                          rows={10}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 ">
                  <div className="row">
                    <div className="col-sm-6 col-md-6 d-none">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("location")}
                        </label>
                        <input
                          type="text"
                          name="location"
                          className="form-control"
                          placeholder={i18n.t("location")}
                          value={this.state.input.location}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 d-none">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("location") + " (" + i18n.t("arabic") + ")"}
                        </label>
                        <input
                          type="text"
                          name="location_ar"
                          className="form-control"
                          placeholder={
                            i18n.t("location") + " (" + i18n.t("arabic") + ")"
                          }
                          value={this.state.input.location_ar}
                          onChange={this.handleChange}
                          style={{ direction: "rtl" }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("location")}
                        </label>

                        {this.state.lat && this.state.lng && (
                          <Map
                            getLocation={this.getLocation}
                            defaultLocation={{
                              lat: this.state.lat,
                              lng: this.state.lng,
                            }}
                          />
                        )}

                        <div className="row mt-3">
                          <div className="col-sm-6 col-md-6">
                            <input
                              type="text"
                              name="latitude"
                              value={this.state.lat}
                              className="form-control"
                              onChange={(event) => {
                                this.setState({
                                  lat: event.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <input
                              type="text"
                              name="longitude"
                              value={this.state.lng}
                              className="form-control"
                              onChange={(event) => {
                                this.setState({
                                  lng: event.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer" style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-primary">
                {i18n.t("save_continue")}
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
