import React from "react";

export class CustomTitle extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.need_mzad_icon && (
          <div className="d-flex align-items-center justify-content-center mb-3">
            <span style={{ display: "flex", gap: "5px" }}>
              <div
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#F23838",
                }}
              ></div>
              <div
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#25A6D9",
                }}
              ></div>
              <span
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#D3D923",
                }}
              ></span>
            </span>
          </div>
        )}
        <div style={{ direction: "ltr" }}>
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <button className="auction-button">
              <div className="image-container">
                {this.props.icon && this.props.icon_type === "img" && (
                  <img
                    src="./assets/images/ri_auction-fill.png"
                    height="37"
                    width="45"
                    alt="Title Icon"
                  />
                )}
              </div>

              <span
                id="auction-head"
                style={{
                  fontSize: "22px",
                  fontWeight: "500",
                  lineHeight: "41px",
                  letterSpacing: "0em",
                }}
              >
                {this.props.title}
              </span>
            </button>
          </div>
        </div>
        {/* 
        <h2>{this.props.title}</h2> */}
      </React.Fragment>
    );
  }
}
