import React from "react";
import i18n from "../../../i18n";
//import { getLoggedUser } from "../../../state/user";

//const user = getLoggedUser();
export class DashboardWalletData extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let { wallet_data } = this.props;
    return (
      <React.Fragment>
        <div className="container mb-3 px-0">
          <div className="row bg-white rounded-4">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="">
                <div className="px-1 py-2">
                  <div className="p-2 user-brd rounded-4">
                    <div className="grid_container grid_mob_container">
                      <div
                        className="outer_round3 flex_container"
                        onClick={() => {
                          window.location.href = "/users-wallet";
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="inner_round">
                          <span>{i18n.t("deposit")}</span>
                        </div>
                        <div className=" outer_text lh20">
                          <span>{wallet_data.credit_sum || 0}</span>
                          {i18n.t("omr")}
                        </div>
                      </div>
                      <div
                        className="outer_round4 flex_container"
                        onClick={() => {
                          window.location.href = "/users-wallet";
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="inner_round">
                          <span>{i18n.t("client_due")}</span>
                        </div>
                        <div className=" outer_text lh20">
                          <span>{wallet_data.client_due || 0}</span>
                          {i18n.t("omr")}
                        </div>
                      </div>
                      <div
                        className="outer_round1 flex_container"
                        onClick={() => {
                          window.location.href = "/users-wallet";
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="inner_round">
                          <span>{i18n.t("hold")}</span>
                        </div>
                        <div className=" outer_text lh20">
                          <span>{wallet_data.hold_sum || 0}</span>
                          {i18n.t("omr")}
                        </div>
                      </div>
                      <div
                        className="outer_round2 flex_container"
                        onClick={() => {
                          window.location.href = "/users-wallet";
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="inner_round">
                          <span>{i18n.t("withdraw")}</span>
                        </div>
                        <div className=" outer_text lh20">
                          <span>{wallet_data.debit_sum || 0}</span>
                          {i18n.t("omr")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
