import React from "react";
import { Dropdown } from "react-bootstrap";
import i18n from "../../../i18n";
import { getCategories } from "../../../models/categories";
import { getDepartments } from "../../../models/departments";
import { getLocations } from "../../../models/locations";

let current_lng = localStorage.getItem("I18N_LANGUAGE");

export class CategorySearch extends React.Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      departments: [],
      locations: [],
      cat: null,
      cat_name: i18n.t("categories"),
      dep_name: i18n.t("department"),
      loc_name: i18n.t("location"),
      input: {
        title: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.gotoAuctions = this.gotoAuctions.bind(this);
  }
  componentDidMount() {
    this.loadAllCategories();
    this.loadAllDepartments();
    this.loadAllLocations();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async loadAllCategories() {
    const response = await getCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  async loadAllDepartments() {
    const response = await getDepartments();
    let departments = response.data;
    this.setState({ departments });
  }

  async loadAllLocations(dep = "") {
    let q = "";
    if (dep) {
      q = `department=${dep}`;
    }
    const response = await getLocations(q);
    let locations = response.data;
    this.setState({ locations, loc_name: i18n.t("location") });
  }

  gotoAuctions() {
    let url = `auctions?sec=main`;
    url += this.state.cat ? `&category=${this.state.cat}` : "";
    url += this.state.input.title ? `&text=${this.state.input.title}` : "";
    url += this.state.dep ? `&department=${this.state.dep}` : "";
    url += this.state.loc ? `&location=${this.state.loc}` : "";
    window.location.href = url;
  }

  render() {
    let { categories, departments, locations } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="">
                <div className="row">
                  <div className="d-flex home-search justify-content-center align-items-center gap-3 bg-lt-secondary p-2 px-3 mt-5 m-auto rounded-4">
                    <button
                      className="btn btn-primary rounded-5 d-mob-none"
                      type="button"
                    >
                      <i
                        className="mdi mdi-filter text-white p-0"
                        style={{ fontSize: "1.7rem" }}
                      />
                    </button>
                    <Dropdown className="d-block bg-secondary rounded-pill">
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        style={{ width: "150px" }}
                      >
                        <span>{this.state.cat_name}</span>{" "}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {categories.map((item, i) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                this.setState({
                                  cat: item.id,
                                  cat_name:
                                    current_lng === "ar" &&
                                    item.category_name_ar
                                      ? item.category_name_ar
                                      : item.category_name,
                                });
                              }}
                              key={item.id}
                            >
                              {current_lng === "ar" && item.category_name_ar
                                ? item.category_name_ar
                                : item.category_name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown className="d-block bg-secondary rounded-pill d-mob-none">
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        style={{ width: "150px" }}
                        className="overflow-hidden"
                      >
                        <span>{this.state.dep_name}</span>{" "}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {departments.map((item, i) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                this.setState({
                                  dep: item.id,
                                  dep_name:
                                    current_lng === "ar" && item.name_ar
                                      ? item.name_ar
                                      : item.name,
                                });
                                this.loadAllLocations(item.id);
                              }}
                              key={item.id}
                            >
                              {current_lng === "ar" && item.name_ar
                                ? item.name_ar
                                : item.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown className="d-block bg-secondary rounded-pill d-mob-none">
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        style={{ width: "150px" }}
                        className="overflow-hidden"
                      >
                        <span>{this.state.loc_name}</span>{" "}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {locations.map((item, i) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                this.setState({
                                  loc: item.id,
                                  loc_name:
                                    current_lng === "ar" && item.name_ar
                                      ? item.name_ar
                                      : item.name,
                                });
                              }}
                              key={item.id}
                            >
                              {current_lng === "ar" && item.name_ar
                                ? item.name_ar
                                : item.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>

                    <div
                      className="d-flex justify-content-center align-items-center border-1 rounded-pill bg-secondary px-2"
                      style={{ width: "50%" }}
                    >
                      <input
                        type="text"
                        className="form-control cat-search-text border-0 rounded-0 bg-secondary px-3 m-0"
                        placeholder={i18n.t("search")}
                        name="title"
                        value={this.state.input.title}
                        onChange={this.handleChange}
                      />
                      <span className="input-group-btn">
                        <button
                          className="btn bg-none rounded-0 m-0 px-3 rounded-pill"
                          type="button"
                          onClick={this.gotoAuctions}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </span>
                    </div>

                    <button
                      className="btn btn-secondary rounded-5  d-mob-none"
                      type="button"
                    >
                      <span>{i18n.t("sort")}</span>&nbsp;
                      <i className="mdi mdi-sort text-primary p-0" />
                    </button>

                    <button
                      className="btn btn-none border-o rounded-1 p-0 m-0  d-mob-none"
                      type="button"
                    >
                      <i
                        className="mdi mdi-view-grid p-0 text-primary m-0"
                        style={{ fontSize: "2rem" }}
                      />
                    </button>
                    <button
                      className="btn btn-none border-o rounded-1 p-0 m-0  d-mob-none"
                      type="button"
                    >
                      <i
                        className="mdi mdi-view-list p-0 text-primary m-0"
                        style={{ fontSize: "2.5rem" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
