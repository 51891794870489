import React from "react";
import Lightbox from "react-awesome-lightbox";
import Countdown from "react-countdown";
import i18n from "../../i18n";
import { getGroupedAuctions } from "../../models/auctions";
import { AuctionListGrouped } from "../helpers/skeletons/AuctionListGrouped";
import { AuctionRowGrouped } from "./helpers/auctionRowGrouped";
import { HomeBanner } from "./homeBanner";
import { getCategories } from "../../models/categories";
import { AuctionCard } from "./helpers/auctionCard";
//import { baseUrl } from "../../constants/global";
import DatePicker from "react-date-picker";
import { getOrganizations } from "../../models/organization";
import { CustomEnroll } from "../helpers/customEnroll";
//import { getLoggedUser } from "../../state/user";
const CalendarIcon = () => {
  return (
    <span className="date-icon">
      <i className="mdi mdi-calendar text-primary" />
    </span>
  );
};
const ClearIcon = () => {
  return <fa className="mdi mdi-close" />;
};
const FinishBid = () => (
  <span className="text-danger">{i18n.t("time_ends")}</span>
);
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    let warning =
      days === 0 && hours === 0 && minutes === 0 && seconds < 10 ? true : false;
    console.log(warning);
    return (
      <span
        className={
          warning
            ? "text-danger countdown-listing-pg countdwn-warning"
            : "text-info countdown-listing-pg"
        }
      >
        {days}
        {i18n.t("D")} : {hours}
        {i18n.t("H")} : {minutes}
        {i18n.t("M")} : {seconds}
        {i18n.t("S")}
      </span>
    );
  }
};

//const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
let lng = localStorage.getItem("I18N_LANGUAGE");
let group_limit = localStorage.getItem("group_limit");
export class AuctionsListGrouped extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let groupID = path.substring(path.lastIndexOf("/") + 1, path.length);

    let urlParams = new URLSearchParams(window.location.search);
    let param1Value = urlParams.get("type");

    this.state = {
      section: "main",
      catArrs: [],
      page_type: param1Value,
      categories: [],
      input: {
        auction: "",
      },
      organizations: [],
      auctions: {},
      limit: 30,
      meta: {},
      loading: false,
      group: groupID,
      gpData: {},
      orgData: {},
      enrolledUsers: 0,
      lightboxImages: [],
      is_active_light_box: false,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadGroupedAuctions = this.loadGroupedAuctions.bind(this);
    this.getImagePopup = this.getImagePopup.bind(this);
    this.searchAuctions = this.searchAuctions.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let lt = group_limit || 30;
    this.loadGroupedAuctions(lt);
    this.loadAllCategories(params);
    this.loadAllOrganizations(params);
  }

  handleDateChange(date, name) {
    let input = this.state.input;
    if (date) {
      const dateObject = new Date(date);
      const day = dateObject.getDate();
      const month = dateObject.getMonth() + 1;
      const year = dateObject.getFullYear();
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDate =
        `${year}-${formattedMonth}-${formattedDay}`.toString();
      input[name] = formattedDate;
      this.setState({ input, [name]: date });
    } else {
      input[name] = "";
      this.setState({ input, [name]: null });
    }
  }

  async loadAllOrganizations(params) {
    this.setState({ is_org_loading: true });
    if (typeof params.organization !== "undefined") {
      var org = params.organization;
      this.setState({ orgArrs: [parseInt(org)] });
    }
    const response = await getOrganizations();
    if (response) {
      let organizations = response.data;
      this.setState({ organizations });
      this.setState({ is_org_loading: false });
    }
  }

  async loadAllCategories(params) {
    this.setState({ is_cat_loading: true });
    if (typeof params.category !== "undefined") {
      var category = params.category;
      this.setState({ catArrs: [parseInt(category)] });
    }
    const response = await getCategories();
    if (response) {
      let categories = response.data;
      this.setState({ categories });
      this.setState({ is_cat_loading: false });
    }
  }

  createCategory(id, sub = false) {
    if (sub) {
      var subCatArrs = this.state.catArrs;
      if (subCatArrs.includes(id)) {
        const index = subCatArrs.indexOf(parseInt(id));
        if (index > -1) {
          subCatArrs.splice(index, 1);
        }
      } else {
        subCatArrs.push(parseInt(id));
      }
      this.setState({ subCatArrs: subCatArrs });
      this.applyFilters();
    } else {
      if (!id) {
        this.setState({ catArrs: [] });
        this.applyFilters();
        return;
      }
      var catArrs = this.state.catArrs;
      if (catArrs.includes(id)) {
        const index = catArrs.indexOf(parseInt(id));
        if (index > -1) {
          catArrs.splice(index, 1);
        }
      } else {
        catArrs.push(parseInt(id));
      }
      this.setState({ catArrs: catArrs });
      this.applyFilters();
    }
  }

  applyFilters() {
    //window.scrollTo(0, 0);
    var type = this.state.type;
    var section = this.state.section;
    //let { input } = this.state;
    var catArrs = this.state.catArrs;
    var cats = catArrs.join(",");
    if (catArrs.length < 1) {
      cats = "";
    }

    // var orgArrs = this.state.orgArrs;
    // var orgs = orgArrs.join(",");
    // if (orgArrs.length < 1) {
    //   orgs = "";
    // }

    this.setState({
      category: cats,
      type: type,
      section: section,
      //organization: orgs,
    });

    this.loadGroupedAuctions({
      sec: section,
      type: type,
      category: cats,
      //organization: orgs,
    });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async loadGroupedAuctions(params) {
    localStorage.setItem("group_limit", params.limit);
    this.setState({ limit: params.limit });
    let { group } = this.state;
    let q = "";
    if (group) {
      q += "group=" + group;
    }
    if (!isNaN(params.limit)) {
      q += "&limit=" + params.limit;
    }
    if (typeof params.category !== "undefined") {
      q += "&category=" + params.category;
    }

    this.setState({ loading: true });
    const response = await getGroupedAuctions(q);
    let auctions = response.data;
    let meta = response.meta;
    if (auctions) {
      this.setState({ auctions: auctions, meta, loading: false });
    }
    if (meta.group) {
      this.setState({ gpData: meta.group });
    }
    if (meta.organization) {
      this.setState({ orgData: meta.organization });
    }
    if (meta.enrolledUsers) {
      this.setState({ enrolledUsers: meta.enrolledUsers });
    }
  }

  async searchAuctions() {
    let { group } = this.state;
    let q = "";
    if (group) {
      q += "group=" + group;
    }
    if (this.state.input.auction) {
      q += "&auction=" + this.state.input.auction;
    }
    if (this.state.input?.orderBy) {
      q += "&orderBy=" + this.state.input.orderBy;
    }
    q += "&lang=" + lng;

    this.setState({ loading: true });
    const response = await getGroupedAuctions(q);
    let auctions = response.data;
    let meta = response.meta;
    if (auctions) {
      this.setState({ auctions: auctions, meta, loading: false });
    }
    if (meta.group) {
      this.setState({ gpData: meta.group });
    }
    if (meta.organization) {
      this.setState({ orgData: meta.organization });
    }
    if (meta.enrolledUsers) {
      this.setState({ enrolledUsers: meta.enrolledUsers });
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  getImagePopup(active, images) {
    let lightboxImages = [];
    if (images) {
      images.forEach(function (arrayItem) {
        lightboxImages.push({
          url: arrayItem.image,
        });
      });
      this.setState({
        is_active_light_box: active,
        lightboxImages: lightboxImages,
      });
    }
  }

  sortByOrder(data) {
    let result = data;
    if (data.length) {
      result = data.sort((a, b) => a.order - b.order);
    }
    return result;
  }

  render() {
    let { categories, auctions, gpData, now } = this.state;
    //catArrs, organizations
    let can_show_custom_enroll = false;
    let groupRegEndDate = new Date(this.createDate(gpData.reg_end_date));
    //console.log(now() + "-" + groupRegEndDate);
    if (
      gpData.is_auctions_grouped &&
      !gpData.is_grouped_enroll &&
      is_logged &&
      groupRegEndDate > now()
    ) {
      can_show_custom_enroll = true;
    }
    auctions = this.sortByOrder(auctions);
    //let total_auctions = this.state.meta.total;
    // let limit =
    //   isNaN(this.state.limit) || !this.state.limit
    //     ? false
    //     : Number(this.state.limit);
    // let user_role = user ? user.role : null;
    // let is_enrolled = this.props.is_enrolled;
    let endDate = this.state.gpData.reg_end_date;
    let endTime = new Date(this.createDate(endDate)).getTime();
    let visit_amount = Number(this.state.gpData.visit_amount);
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let page_title = {
      active: i18n.t("active_auctions"),
      previous: i18n.t("previous_auctions"),
      featured: i18n.t("featured_auctions"),
      upcoming: i18n.t("upcoming_auctions"),
      direct: i18n.t("direct_auctions"),
      all: i18n.t("auctions"),
      "": i18n.t("auctions"),
    };

    let vehicle_only = false;
    if (
      auctions.length > 0 &&
      auctions[0].hasOwnProperty("is_vehicle") &&
      auctions[0].is_vehicle === 1
    ) {
      vehicle_only = true;
    }
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        {this.state.loading && (
          <div className="container" id="auction-grouped">
            <AuctionListGrouped />
          </div>
        )}

        {!this.state.loading && (
          <div className="container" id="auction-grouped">
            <div className="row mob-padd-9">
              <div className="license-plate m-auto mt-5">
                <section>
                  <i className="mdi mdi-gavel" />
                </section>
                <span>
                  {this.state.page_type
                    ? page_title[this.state.page_type]
                    : i18n.t("auctions")}
                </span>
              </div>
              {/* <div
                className={
                  is_logged
                    ? "section-title center-block text-center active-auc-title"
                    : "section-title center-block text-center"
                }
              >
                <p className="mt-5">
                  <button
                    className="btn btn-outline-info"
                    style={{ borderRadius: "50%" }}
                    onClick={(e) => {
                      this.loadGroupedAuctions(this.state.limit);
                    }}
                  >
                    <i className="fa fa-refresh" />
                  </button>
                </p>
              </div> */}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="">
                  <div className="">
                    <div className="item2-gl ">
                      {/* <div className="showing-auction m-auto">
                        <span className="text-primary">
                          <b>{total_auctions}</b> {i18n.t("auctions")}
                        </span>
                      </div> */}
                      <div className="reg-tabs m-auto mt-3">
                        <div className="reg-tab-item">
                          <div className="reg-tab-inner-item">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/clock.png"
                              }
                              alt="clock"
                              className="icon-size"
                            />
                          </div>
                          <p className="sa-details">
                            {i18n.t("reg_start")} :{" "}
                            {this.state.gpData.reg_start_date_formatted}
                          </p>
                        </div>
                        <hr className="vertical-line" />
                        <div className="reg-tab-item">
                          <div className="reg-tab-inner-item">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/clock.png"
                              }
                              alt="clock"
                              className="icon-size"
                            />
                          </div>
                          <p className="sa-details">
                            {i18n.t("reg_end")} :{" "}
                            {endDate && (
                              <Countdown
                                date={endTime}
                                renderer={renderer}
                                now={now}
                              />
                            )}
                          </p>
                        </div>
                        <hr className="vertical-line" />
                        <div className="reg-tab-item">
                          <div className="reg-tab-inner-item">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/user.png"
                              }
                              alt="clock"
                              className="icon-size"
                            />
                          </div>
                          <p className="sa-details">
                            {i18n.t("enrolled_users")} {":"}{" "}
                            {this.state.enrolledUsers}
                          </p>
                        </div>
                      </div>
                      <div className="button-sort-section m-auto">
                        <div className="dropdown search-section-dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle burger-menu"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                              this.setState({
                                show_cat_dd: !this.state.show_cat_dd,
                              });
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/burger menu.png"
                              }
                              width="20"
                              alt=""
                            />
                          </button>
                          <ul
                            className={
                              this.state.show_cat_dd
                                ? "dropdown-menu d-block"
                                : "dropdown-menu"
                            }
                          >
                            {categories.map((item, i) => {
                              return (
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href={true}
                                    onClick={() => {
                                      this.createCategory(item.id);
                                      this.setState({
                                        show_cat_dd: false,
                                      });
                                    }}
                                  >
                                    {current_lng === "ar"
                                      ? item.category_name_ar
                                      : item.category_name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        {/* {vehicle_only && (
                          <div className="buttons-section justify-content-start">
                            <div className="category-buttons">
                              <button
                                type="button"
                                className={
                                  catArrs.includes(8)
                                    ? "btn btn-primary sort-box-btn active"
                                    : "btn btn-primary sort-box-btn"
                                }
                                onClick={() => {
                                  this.createCategory(8);
                                }}
                              >
                                <img
                                  src={
                                    baseUrl +
                                    "services/public/uploads/category/vehicles.png"
                                  }
                                  width="20"
                                  alt="All"
                                />
                                <span>
                                  {current_lng === "ar" ? "مركبات" : "Vehicles"}
                                </span>
                              </button>
                            </div>
                          </div>
                        )} */}
                        {/* {!vehicle_only && (
                          <div className="buttons-section justify-content-start">
                            {/* <button
                              className="sort-button sbtn-1"
                              onClick={() => {
                                this.setState({
                                  show_filter_pop: !this.state.show_filter_pop,
                                });
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/sort.png"
                                }
                                width="20"
                                alt="sort"
                              />
                            </button> 
                            <button
                              className={
                                catArrs.length === 0
                                  ? "btn btn-primary sort-box-btn active"
                                  : "btn btn-primary sort-box-btn"
                              }
                              type="button"
                              onClick={() => {
                                this.createCategory(false);
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/All btn.png"
                                }
                                width="20"
                                alt="All"
                              />
                              <span>{i18n.t("all")}</span>
                            </button>
                            <div className="category-buttons">
                              {categories.map((item, i) => {
                                return (
                                  <button
                                    type="button"
                                    className={
                                      catArrs.includes(item.id)
                                        ? "btn btn-primary sort-box-btn active"
                                        : "btn btn-primary sort-box-btn"
                                    }
                                    onClick={() => {
                                      this.createCategory(item.id);
                                    }}
                                  >
                                    <img
                                      src={item.file_category_image}
                                      width="20"
                                      alt="All"
                                    />
                                    <span>
                                      {current_lng === "ar"
                                        ? item.category_name_ar
                                        : item.category_name}
                                    </span>
                                  </button>
                                );
                              })}
                            </div> 
                          </div>
                        )} */}
                        <div className="sort-section">
                          <button
                            className="sort-button sbtn-1"
                            onClick={() => {
                              this.setState({
                                show_filter_pop: !this.state.show_filter_pop,
                              });
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/sort.png"
                              }
                              width="20"
                              alt="sort"
                            />
                          </button>
                          <div className="search">
                            <input
                              type="text"
                              name="auction"
                              placeholder={i18n.t("search")}
                              value={this.state.input.auction}
                              onChange={this.handleChange}
                            />
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/search.png"
                              }
                              width="20"
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={this.searchAuctions}
                            />
                          </div>
                          <button
                            className="sort-button-right px-3"
                            onClick={() => {
                              let { input } = this.state;
                              if (!input?.orderBy) {
                                input.orderBy = "priceHighest";
                              } else {
                                input.orderBy =
                                  input.orderBy === "priceHighest"
                                    ? "priceLowest"
                                    : "priceHighest";
                              }
                              this.setState({ input });
                              this.searchAuctions();
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/sort-icon.png"
                              }
                              width="20"
                              alt=""
                            />
                            <span>{i18n.t("sort")}</span>
                          </button>
                          <button
                            className={
                              this.state.grid
                                ? "sort-button sbtn-1 d-mob-none"
                                : "sort-button sbtn-2 d-mob-none"
                            }
                            onClick={() => {
                              this.setState({ grid: true });
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/grid.png"
                              }
                              width="15"
                              alt="sort"
                              className={this.state.grid ? "grid-invert" : ""}
                            />
                          </button>
                          <button
                            className={
                              !this.state.grid
                                ? "sort-button sbtn-1 d-mob-none"
                                : "sort-button sbtn-2 d-mob-none"
                            }
                            onClick={() => {
                              this.setState({ grid: false });
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/burger menu.png"
                              }
                              width="15"
                              alt="sort"
                              className={!this.state.grid ? "" : "grid-invert"}
                            />
                          </button>
                        </div>
                      </div>

                      {this.state.show_filter_pop && (
                        <div className="search-result m-auto bg-none border-0 mt-1 p-0 m-0">
                          <div className="row border rounded-1 bg-white py-1">
                            <div className="col-xl-3 col-lg-3 col-md-3 d-flex justify-content-center align-items-center">
                              <label>{i18n.t("from_date")}</label>
                              <DatePicker
                                placeholder={i18n.t("from_date")}
                                name="fdate"
                                className="p-1"
                                calendarClassName="calendar-wrapper p-1"
                                value={this.state.fdate}
                                selected={this.state.input.fdate}
                                onChange={(e) => {
                                  this.handleDateChange(e, "fdate");
                                }}
                                format="dd/MM/y"
                                calendarIcon={<CalendarIcon />}
                                clearIcon={<ClearIcon />}
                              />
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-3 d-flex justify-content-center align-items-center">
                              <label>{i18n.t("to_date")}</label>
                              <DatePicker
                                placeholder={i18n.t("from_date")}
                                name="tdate"
                                className="p-1"
                                calendarClassName="calendar-wrapper "
                                value={this.state.tdate}
                                selected={this.state.input.tdate}
                                onChange={(e) => {
                                  this.handleDateChange(e, "tdate");
                                }}
                                format="dd/MM/y"
                                calendarIcon={<CalendarIcon />}
                                clearIcon={<ClearIcon />}
                              />
                            </div>
                            {/* <div className="col-xl-3 col-lg-3 col-md-3">
                              <select
                                className="form-control border rounded-5"
                                data-placeholder="Select organization"
                                name="organization"
                                value={this.state.input.organization}
                                onChange={this.handleChange}
                              >
                                <option value="">{i18n.t("seller")}</option>
                                {!this.state.is_org_loading &&
                                  organizations.map((item, i) => {
                                    return (
                                      <option value={item.id}>
                                        {current_lng === "ar"
                                          ? item.organization_name_ar
                                          : item.organization_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div> */}
                            <div className="col-xl-3 col-lg-3 col-md-3 d-flex justify-content-center align-items-center">
                              {(this.state.input.fdate ||
                                this.state.input.tdate ||
                                this.state.input.organization) && (
                                <button
                                  className="btn btn-outline-danger btn-sm  p-1 px-2"
                                  onClick={() => {
                                    let { input } = this.state;
                                    input.fdate = "";
                                    input.tdate = "";
                                    input.organization = "";
                                    this.setState({
                                      input,
                                      fdate: "",
                                      tdate: "",
                                    });
                                    this.applyFilters();
                                  }}
                                >
                                  <i className="fa fa-times" />
                                </button>
                              )}

                              <a
                                href={true}
                                className="btn btn-primary btn-block rounded-1"
                                onClick={() => {
                                  this.applyFilters();
                                }}
                              >
                                {i18n.t("search")}
                              </a>
                            </div>
                          </div>
                        </div>
                      )}

                      {can_show_custom_enroll && (
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12 w-desk-75 m-auto">
                            <div className="pagination mx-2 my-3">
                              <CustomEnroll group={gpData.id} />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="content overflow-hidden pt-1">
                        <div className="p-1 mb-9">
                          <div
                            className={`cards-section m-auto ${
                              !this.state.grid ? "list" : ""
                            }`}
                          >
                            {auctions.length < 1 && (
                              <div className="alert alert-danger text-center">
                                {i18n.t("no_auctions_found")}
                              </div>
                            )}
                            {!this.state.grid &&
                              auctions.length > 0 &&
                              auctions.map((item, i) => {
                                let key = parseInt(i) + 1;
                                let _data = {
                                  screen: "groupList",
                                  auction: item,
                                  auctionId: item.id,
                                  total_items: auctions.length,
                                  company: "Mzadcom",
                                  price: item.start_amount,
                                  title: item.title,
                                  title_ar: item.title_ar,
                                  description: "Diesel Generators",
                                  curr_amount: item.current_amount,
                                  end_date: item.end_date_formatted,
                                  start_date: item.start_date_formatted,
                                  images: item.images,
                                  total_views: item.total_views,
                                  type: this.state.page_type,
                                  can_bid: false,
                                  status: item.status,
                                  visit_amount: visit_amount,
                                  group: this.state.gpData,
                                };
                                return (
                                  <AuctionRowGrouped
                                    keys={key}
                                    getImagePopup={this.getImagePopup}
                                    {..._data}
                                  />
                                );
                              })}

                            <div className="row">
                              {this.state.grid &&
                                auctions.length > 0 &&
                                auctions.map((item, i) => {
                                  let key = parseInt(i) + 1;
                                  return (
                                    <AuctionCard
                                      keys={key}
                                      auction={item}
                                      images={item.images}
                                      getImagePopup={this.getImagePopup}
                                      screen="groupList"
                                      visit_amount={visit_amount}
                                      group={this.state.gpData}
                                      type={this.state.page_type}
                                      auctionId={item.id}
                                      total_items={auctions.length}
                                      company={"Mzadcom"}
                                      price={item.start_amount}
                                      title={item.title}
                                      title_ar={item.title_ar}
                                      curr_amount={item.current_amount}
                                      end_date={item.end_date_formatted}
                                      start_date={item.start_date_formatted}
                                      total_views={item.total_views}
                                      can_bid={true}
                                      status={item.status}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.is_active_light_box && (
          <Lightbox
            images={this.state.lightboxImages}
            onClose={() => {
              this.setState({ is_active_light_box: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
