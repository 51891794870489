import React from "react";
import { Link } from "react-router-dom";
import { updatePayment } from "../models/auctions";
import i18n from "../i18n";

export class PaymentSuccess extends React.Component {
  constructor(props) {
    super(props);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());

    let groupId = params.gid;
    let auctionId = params.auc || "";
    let amount = params.amt;
    let enrollId = params.eid || "";
    let type = params.type || "enroll"; //sitevisit || enroll
    let gatePass = params.gate_pass || "";
    let ptype = params.ptype || "";
    let is_custom = params.is_custom || false;
    let invoice = params.invoice || false;
    let ref = params.ref || false;
    this.state = {
      group_id: groupId,
      auction_id: auctionId,
      amount: amount,
      enroll: enrollId,
      ptype: ptype,
      type: type,
      gatePass: gatePass,
      is_custom: is_custom,
      invoice: invoice,
      ref: ref,
    };
  }

  async componentDidMount() {
    const response = await updatePayment({
      group_id: this.state.group_id,
      auction_id: this.state.auction_id,
      enroll_id: this.state.enroll,
      amount: this.state.amount,
      invoice: this.state.invoice || localStorage.getItem("invoice") || null,
      type: this.state.type,
      gate_pass_id: this.state.gatePass,
      is_custom: this.state.is_custom,
      reference: this.state.ref || null,
    });
    console.log(response);
  }
  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            {/* <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">Payment Success!!!</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        Home
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="container text-center">
              <div className="card p-5 m-5">
                <h1 className="h2 mb-7 text-success py-3">
                  {i18n.t("payment_success_msg_title")}
                </h1>
                <p className="h4 font-weight-Automatic text-success mb-8 leading-Automatic py-5">
                  {i18n.t("payment_success_msg")}
                </p>
                <Link
                  className="btn btn-warning m-auto rounded-5 text-white"
                  style={{ width: "200px" }}
                  to="/"
                >
                  {i18n.t("home")}
                </Link>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
