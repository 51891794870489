import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const user = getLoggedUser();
const endpoint = `${apiUrl}auction_request`;

async function createRequest(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(endpoint, payload, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllRequests(q) {
  return fetch(`${endpoint}?${q}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getRequest(id) {
  return fetch(`${endpoint}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateRequest(id, payload) {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      `${endpoint}/${id}`,
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteRequest(id) {
  return fetch(`${endpoint}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createRequest,
  getAllRequests,
  getRequest,
  updateRequest,
  deleteRequest,
};
