import React from "react";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import {
  getAllCategories,
  createCategory,
  deleteCategory,
} from "../../models/categories";
import i18n from "../../i18n";
import { HomeBanner } from "../home/homeBanner";
import SignatureCanvas from "react-signature-canvas";

export class AuctionApproveClient extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      categories: [],
      file_category_image: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllCategories();
  }

  async loadAllCategories() {
    const response = await getAllCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  onFileChange = (event) => {
    if (event.target.name === "file_category_image") {
      this.setState({ file_category_image: event.target.files[0] });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();

    if (
      !this.state.input.category_name ||
      !this.state.input.category_name_ar ||
      !this.state.input.description ||
      !this.state.input.description_ar ||
      !this.state.file_category_image
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("category_name", this.state.input.category_name);
    formData.append("category_name_ar", this.state.input.category_name_ar);
    formData.append("description", this.state.input.description);
    formData.append("description_ar", this.state.input.description_ar);
    formData.append("file_category_image", this.state.file_category_image);

    const response = await createCategory(formData);
    if (response.success) {
      this.loadAllCategories();
    } else {
      swal("Failed", response.message, "error");
    }
  }

  deleteRow(id) {
    const response = deleteCategory(id);
    console.log(response?.success);

    swal(i18n.t("success"), "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.loadAllCategories();
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  render() {
    //let categories = this.state.categories;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">
                      {i18n.t("auction_approval")}/{i18n.t("signature")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("name")}</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder={i18n.t("name")}
                            value={this.state.input.name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("employee_no")}
                          </label>
                          <input
                            type="text"
                            name="emp_code"
                            className="form-control rtl-dir"
                            placeholder={i18n.t("employee_no")}
                            value={this.state.input.emp_code}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("signature")}
                          </label>
                          <SignatureCanvas
                            penColor="black"
                            canvasProps={{
                              width: 450,
                              height: 200,
                              className: "sigCanvas",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 mt-4">
                        <div className="form-group mt-1">
                          <label className="form-label">&nbsp;</label>
                          <button type="submit" className="btn btn-primary">
                            {i18n.t("save")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
