import React from "react";
import { SideBar } from "../sidebar";
import {
  getAllAuctionsByGroup,
  getAllBidderPositions,
} from "../../../models/auctions";
import { getAllGroups } from "../../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../../i18n";
import { Spinner } from "react-bootstrap";
import { apiUrl } from "../../../constants/global";
import { HomeBanner } from "../../home/homeBanner";
import { getLoggedUser } from "../../../state/user";

const user = getLoggedUser();
export class ReportsPosition extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_menu: false,
      input: {},
      groups: {},
      auctions: {},
      bidders: {},
      phone_loader: false,
      open_popup: false,
      bidder_loading: false,
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#dadada",
            color: "#000000",
          },
        },
      },
      //actionsMemo : React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []),
      columns: [
        {
          name: i18n.t("bidder_id"),
          selector: "enroll_number",
          sortable: true,
          width: "70px",
        },
        {
          name: "User ID",
          selector: "user_id",
          sortable: true,
          width: "100px",
        },
        {
          name: i18n.t("username"),
          selector: "username",
          cell: (row) => <span>{row.username}</span>,
        },
        {
          name: "Email",
          selector: "email",
          cell: (row) => <span>{row.email}</span>,
        },
        {
          name: "Phone",
          selector: "mobile",
          cell: (row) => <span>{row.mobile}</span>,
        },
        {
          name: i18n.t("Auction"),
          selector: "auction_name_ar",
          sortable: true,
        },
        {
          name: i18n.t("Auction"),
          selector: "auction_name",
          sortable: true,
        },
        {
          name: "Auction ID",
          selector: "auction_id",
          sortable: true,
        },
        {
          name: "Position",
          selector: "position",
          sortable: true,
        },
        {
          name: i18n.t("latest_bid_amount"),
          selector: "bid_amount",
          sortable: true,
        },
        {
          name: "Bid Date",
          selector: "created_at",
          sortable: true,
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadPhoneNumbers = this.loadPhoneNumbers.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      input.auction = false;
      this.setState({ input });
      this.loadPhoneNumbers(event.target.value);
    }
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadPhoneNumbers(group) {
    if (group) {
      this.setState({ phone_loader: true });
      const response = await getAllAuctionsByGroup(group);
      if (response.data) {
        this.setState({ phone_loader: false });
        this.setState({ auctions: response.data });
      }
    }
  }

  async searchSubmit() {
    this.setState({ bidders: {} });
    this.setState({ bidder_loading: true });
    const response = await getAllBidderPositions(this.state.input);
    if (response.data) {
      this.setState({ bidder_loading: false });
      this.setState({ bidders: response.data });
    }
  }

  render() {
    let groups = this.state.groups;
    let auctions = this.state.auctions;
    let bidders = this.state.bidders;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div
                className={
                  this.state.hide_menu
                    ? "d-none"
                    : "col-xl-3 col-lg-12 col-md-12"
                }
              >
                <SideBar />
              </div>
              <div
                className={
                  this.state.hide_menu
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                >
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        onClick={() => {
                          this.setState({ hide_menu: !this.state.hide_menu });
                        }}
                      >
                        <i
                          class={
                            this.state.hide_menu
                              ? "fa fa-arrow-right"
                              : "fa fa-arrow-left"
                          }
                          aria-hidden="true"
                        ></i>
                        &nbsp;
                      </span>
                      {i18n.t("search")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_name")}
                          </label>

                          <div className="input-group">
                            <select
                              className="form-control"
                              data-placeholder="Select Class"
                              name="auction"
                              value={this.state.input.auction}
                              onChange={this.handleChange}
                            >
                              <option value="">--{i18n.t("auction")}--</option>
                              {auctions.length > 0 &&
                                auctions.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.title}
                                    </option>
                                  );
                                })}
                            </select>

                            {this.state.phone_loader && (
                              <span
                                className="input-group-addon"
                                style={{
                                  position: "absolute",
                                  marginLeft: "54%",
                                  marginTop: "10px",
                                }}
                              >
                                <i className="fa fa-refresh fa-spin"></i>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("rank")}</label>
                          {/* <input
                            className="form-control"
                            name="rank"
                            value={this.state.input.rank}
                            onChange={this.handleChange}
                          /> */}
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="rank"
                            value={this.state.input.rank}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("rank")}--</option>
                            {Array.from(Array(11), (e, i) => {
                              return i > 0 && <option value={i}>{i}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={this.searchSubmit}
                            disabled={!this.state.input.group}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("result")}</h3>
                    {bidders.length > 0 && (
                      <div style={{ position: "absolute", right: "5px" }}>
                        <a
                          href={`${apiUrl}
                            report/positions/pdf?${new URLSearchParams(
                              this.state.input
                            ).toString()}&auth=${user.token}`}
                          className="btn btn-dark"
                          target="_blank"
                          rel="noreferrer"
                        >
                          PDF
                        </a>{" "}
                        <a
                          href={`${apiUrl}
                            report/positions/excel?${new URLSearchParams(
                              this.state.input
                            ).toString()}&auth=${user.token}`}
                          className="btn btn-dark"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Excel
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {this.state.bidder_loading && (
                        <div className="text-center">
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!bidders.length && (
                        <div className="alert alert-danger">
                          {i18n.t("no_result")}
                        </div>
                      )}
                      {bidders.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            title=""
                            columns={this.state.columns}
                            data={bidders}
                            customStyles={this.state.customStyles}
                            highlightOnHover
                            pagination
                            paginationPerPage={15}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Bidders per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            //actions={this.state.actionsMemo}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
