import React from "react";
import i18n from "../../../i18n";

const ICONS = {
  pdf: { icon: "fa fa-file-pdf-o", color: "#FF0000" },
  png: { icon: "fa fa-picture-o", color: "#FF0000" },
  jpg: { icon: "fa fa-picture-o", color: "#FF0000" },
  jpeg: { icon: "fa fa-picture-o", color: "#FF0000" },
  bmp: { icon: "fa fa-picture-o", color: "#FF0000" },
  gif: { icon: "fa fa-picture-o", color: "#FF0000" },
  doc: { icon: "fa fa-file", color: "#db9c5f" },
  docx: { icon: "fa fa-file", color: "#db9c5f" },
  xls: { icon: "fa fa-file", color: "#db9c5f" },
  xlsx: { icon: "fa fa-file", color: "#db9c5f" },
};

export class AuctionDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getFileIcon(url) {
    if (url) {
      let extension = url.split(/[#?]/)[0].split(".").pop().trim();
      return (
        ICONS[extension.toLowerCase()] || {
          icon: "fa fa-file-pdf-o",
          color: "#FF0000",
        }
      );
    }
    return { icon: "fa fa-file-pdf-o", color: "#FF0000" };
  }

  render() {
    let auction = this.props.auction;
    let lng = this.props.lng;
    let downloads = auction.downloads;
    let downloads_length =
      (auction && auction.downloads && auction.downloads.length) || 0;
    return (
      <React.Fragment>
        {downloads_length > 0 && (
          <div className="row mt-3 mb-3 documents" style={{ width: "110%" }}>
            {downloads.map((item, i) => {
              //let details = this.getFileIcon(item.file);
              return (
                <div className="col-md-6 col-sm-12 mb-2">
                  <div className="border p-0 px-1 d-flex my-2 justify-content-between bg-secondary rounded-2 align-items-center">
                    <div className="d-flex">
                      <img
                        src={process.env.PUBLIC_URL + "/assets/icons/pdf.png"}
                        alt=""
                        style={{
                          padding: "0.2rem",
                          height: "50px",
                        }}
                      />
                      <div className="p-2 w-100 p-1">
                        <span className="pt-1 d-flex text-gray">
                          {lng === "ar" && item.label_ar
                            ? item.label_ar
                            : item.label}
                        </span>
                      </div>
                    </div>
                    <div className="p-2 flex-shrink-1">
                      <div>
                        <a
                          className="btn btn-primary mt-2 d-flex"
                          href={item.file}
                          target="_new"
                        >
                          <i
                            className="fa fa-download"
                            style={{ fontSize: "1rem" }}
                          />
                          <span className="ms-1 custom_text12">
                            {i18n.t("download")}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}
