import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "../sidebar";
import {
  getAllAuctionsByGroup,
  getAllWinners,
  sendWinnerNotification,
} from "../../../models/auctions";
import { getAllGroups } from "../../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../../i18n";
import { Spinner } from "react-bootstrap";
import { apiUrl } from "../../../constants/global";
import { getLoggedUser } from "../../../state/user";
import swal from "sweetalert";
import { HomeBanner } from "../../home/homeBanner";

const user = getLoggedUser();
const lng = localStorage.getItem("I18N_LANGUAGE");

export class Winners extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        downloadDoc: "pdf",
      },
      groups: {},
      titles: {},
      bidders: {},
      phone_loader: false,
      open_popup: false,
      bidder_loading: false,
      notification_progress: false,
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#dadada",
            color: "#000000",
          },
        },
      },
      columns: [
        {
          name: i18n.t("bidder_id"),
          selector: "id",
          sortable: true,
          wrap: true,
        },
        {
          name: i18n.t("auction"),
          selector: "auction_id",
          sortable: true,
          wrap: true,
        },
        {
          name: i18n.t("title"),
          selector: "title",
        },
        {
          name: i18n.t("user"),
          selector: "user_id",
          sortable: true,
          wrap: true,
        },
        {
          name: i18n.t("username"),
          selector: "username",
        },
        {
          name: i18n.t("latest_bid_amount"),
          selector: "bid_amount",
          sortable: true,
        },
        {
          name: i18n.t("date"),
          selector: "created_at",
          sortable: true,
          wrap: true,
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadAuctions = this.loadAuctions.bind(this);
    this.loadData = this.loadData.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
  }

  componentDidMount() {
    this.loadAllGroups();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.loadAuctions(event.target.value);
    }
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAuctions(group) {
    if (group) {
      this.setState({ phone_loader: true });
      const response = await getAllAuctionsByGroup(group);
      if (response.data) {
        this.setState({ phone_loader: false });
        this.setState({ titles: response.data });
      }
    }
  }

  async loadData() {
    this.setState({ bidder_loading: true });
    const response = await getAllWinners(this.state.input);
    if (response.data) {
      this.setState({ bidder_loading: false });
      this.setState({ bidders: response.data });
    }
  }

  async sendNotification() {
    this.setState({ notification_progress: true });
    const response = await sendWinnerNotification(this.state.input);
    if (response.success) {
      this.setState({ notification_progress: false });
      let message = response.message; //lang === "ar" ? response.message_ar : response.message;
      swal(i18n.t("success"), message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
    }
  }

  render() {
    let groups = this.state.groups;
    let titles = this.state.titles;
    let bidders = this.state.bidders;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                >
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      {i18n.t("search")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {lng === "ar"
                                      ? item.group_name_ar
                                      : item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_name")}
                          </label>

                          <div className="input-group">
                            <select
                              className="form-control"
                              data-placeholder="Select Class"
                              name="auction"
                              value={this.state.input.auction}
                              onChange={this.handleChange}
                            >
                              <option value="">--{i18n.t("auction")}--</option>
                              {titles.length > 0 &&
                                titles.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {lng === "ar"
                                        ? item.title_ar
                                        : item.title}
                                    </option>
                                  );
                                })}
                            </select>

                            {this.state.phone_loader && (
                              <span
                                className="input-group-addon"
                                style={{
                                  position: "absolute",
                                  marginLeft: "54%",
                                  marginTop: "10px",
                                }}
                              >
                                <i className="fa fa-refresh fa-spin"></i>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.loadData}
                            disabled={!this.state.input.group}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h3 className="card-title" style={{ width: "35%" }}>
                      {i18n.t("result")} ({i18n.t("winners")})
                    </h3>

                    {bidders.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-info btn-sm"
                        style={{ margin: "auto", width: "30%" }}
                        onClick={this.sendNotification}
                      >
                        Send Notification to winner(s)
                      </button>
                    )}

                    {bidders.length > 0 && (
                      <form
                        className="form-inline d-flex align-items-center justify-content-between"
                        action=""
                        method="post"
                        target="_new"
                        style={{ width: "35%" }}
                        onSubmit={this.downloadDoc}
                      >
                        <div className="form-group mx-sm-3">
                          <select
                            className="form-control"
                            name="downloadType"
                            value={this.state.input.downloadType}
                            onChange={this.handleChange}
                          >
                            <option value={""}>Download As</option>
                            <option value={"pdf"}>PDF</option>
                            <option value={"excel"}>Excel</option>
                          </select>
                        </div>
                        {this.state.input.downloadType !== "" && (
                          <a
                            type="submit"
                            target="_new"
                            href={
                              apiUrl +
                              "winners/export?q=" +
                              this.state.input.downloadType +
                              "&group=" +
                              this.state.input.group +
                              "&auction=" +
                              this.state.input.auction +
                              "&u=" +
                              user.role
                            }
                            className="btn btn-outline-danger"
                          >
                            Download
                          </a>
                        )}
                      </form>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {this.state.bidder_loading && (
                        <div className="text-center">
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!this.state.bidder_loading && !bidders.length && (
                        <div className="alert alert-danger">
                          {i18n.t("no_result")}
                        </div>
                      )}
                      {bidders.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            title=""
                            columns={this.state.columns}
                            data={bidders}
                            customStyles={this.state.customStyles}
                            highlightOnHover
                            pagination
                            paginationPerPage={15}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Bidders per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            //actions={this.state.actionsMemo}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
