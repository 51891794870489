import React from "react";
import { SideBar } from "./sidebar";
import {
  getAllAuctionsByGroup,
  getAllBidderPositions,
  setFirstRankWinner,
  setWinner,
} from "../../models/auctions";
import { getAllEndedGroups } from "../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { HomeBanner } from "../home/homeBanner";
import { getLoggedUser } from "../../state/user";

const user = getLoggedUser();
const lng = localStorage.getItem("I18N_LANGUAGE");

export class WinnersApproval extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_menu: false,
      input: {},
      groups: {},
      auctions: {},
      bidders: {},
      phone_loader: false,
      open_popup: false,
      auctions_loading: false,
      bidder_loading: false,
      groups_loading: false,
      winner_updating: false,
      selected_groups: [],
      selected_group: null,
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#dadada",
            color: "#000000",
          },
        },
      },
      modal_auctions: false,
      group_columns: [
        {
          name: i18n.t("id"),
          sortable: true,
          width: "70px",
          cell: (row) => row.id,
        },
        {
          name: i18n.t("name"),
          sortable: true,
          cell: (row) => row?.group_name,
        },
        {
          name: "",
          width: "150px",
          cell: (row) => {
            return (
              <button
                type="button"
                className="p-0 btn btn-primary rounded-0 btn-sm px-2 py-1"
                onClick={() => {
                  this.setState({ modal_auctions: true });
                  this.setState({ selected_group: row?.id });
                  this.loadAuctions(row?.id);
                }}
              >
                {i18n.t("set_winner")}
              </button>
            );
          },
        },
      ],
      columns: [
        {
          name: i18n.t("bidder_id"),
          selector: "enroll_number",
          sortable: true,
          width: "70px",
        },
        {
          name: i18n.t("user"),
          selector: "user_id",
          sortable: true,
          width: "100px",
        },
        {
          name: i18n.t("username"),
          selector: "username",
          cell: (row) => <span>{row.username}</span>,
        },
        {
          name: i18n.t("email"),
          selector: "email",
          cell: (row) => <span>{row.email}</span>,
        },
        {
          name: i18n.t("mobile"),
          selector: "mobile",
          cell: (row) => <span>{row.mobile}</span>,
        },
        {
          name: i18n.t("auction"),
          selector: "auction_name",
          sortable: true,
          cell: (row) => {
            return (
              <div>
                <div>
                  {lng === "ar" ? row?.auction_name_ar : row.auction_name}
                </div>
                <div>
                  <small className="text-dark">
                    {i18n.t("ID")}:{row?.auction_id}
                  </small>
                </div>
              </div>
            );
          },
        },
        {
          name: i18n.t("rank"),
          selector: "position",
          sortable: true,
        },
        {
          name: i18n.t("latest_bid_amount"),
          selector: "bid_amount",
          sortable: true,
        },
        {
          name: i18n.t("bid_date"),
          selector: "created_at",
          sortable: true,
        },
        {
          name: i18n.t("winner"),
          sortable: true,
          cell: (row) => {
            return row.winner ? i18n.t("yes") : i18n.t("no");
          },
        },
        {
          name: i18n.t("winner_status"),
          sortable: true,
          cell: (row) => {
            if (row.winner_status === "R") return i18n.t("requested");
            if (row.winner_status === "A") return i18n.t("approved");
          },
        },
        {
          name: "",
          width: "150px",
          cell: (row) => {
            if (user.role === "C") {
              return (
                <button
                  type="button"
                  className="p-0 btn btn-info btn-sm px-2 py-1"
                  onClick={() => {
                    this.updateData(row?.id);
                  }}
                >
                  {i18n.t("set_winner")}
                </button>
              );
            }
            if (
              (user.role === "A" ||
                user.role === "M" ||
                user.role === "PM" ||
                user.role === "OM") &&
              row.winner_status === "R"
            ) {
              return (
                <button
                  type="button"
                  className="p-0 btn btn-success btn-sm px-2 py-1"
                  onClick={() => {
                    this.updateData(row?.id);
                  }}
                >
                  {i18n.t("approve")}
                </button>
              );
            }
          },
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadAuctions = this.loadAuctions.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      input.auction = false;
      this.setState({ input });
      this.loadAuctions(event.target.value);
    }
  }

  async loadAllGroups() {
    this.setState({ groups_loading: true });
    const response = await getAllEndedGroups();
    if (response.success) {
      let groups = response.data;
      console.log(groups);

      this.setState({ groups });
      this.setState({ groups_loading: false });
    }
  }

  async loadAuctions(group) {
    if (group) {
      this.setState({ auctions_loading: true });
      this.setState({ phone_loader: true });
      const response = await getAllAuctionsByGroup(group);
      if (response.data) {
        this.setState({ phone_loader: false });
        this.setState({ auctions: response.data });
        this.setState({ auctions_loading: false });
      } else {
        this.setState({ auctions_loading: false });
      }
    }
  }

  async searchSubmit() {
    this.setState({ bidders: {} });
    this.setState({ bidder_loading: true });
    const response = await getAllBidderPositions(this.state.input);
    if (response.data) {
      this.setState({ bidder_loading: false });
      this.setState({ bidders: response.data });
    }
  }

  async updateData(id) {
    swal(i18n.t("confirm_msg_continue"), {
      buttons: {
        yes: {
          text: i18n.t("yes"),
          value: "yes",
        },
        no: {
          text: i18n.t("no"),
          value: "no",
        },
      },
    }).then(async (value) => {
      let { input, selected_group } = this.state;
      if (value === "yes") {
        this.setState({ winner_updating: true });
        const response = await setWinner({ id: id, auction: input.auction });
        if (response.data) {
          this.setState({ winner_updating: false });
          this.searchSubmit();
          this.loadAuctions(selected_group);
        }
      }
      return;
    });
  }

  async setFirstRankWinners() {
    let { selected_groups } = this.state;
    if (selected_groups.length < 1) {
      swal(i18n.t("failed"), i18n.t("no_data_found"), "error");
      return;
    }
    swal(i18n.t("confirm_msg_continue"), {
      buttons: {
        yes: {
          text: i18n.t("yes"),
          value: "yes",
        },
        no: {
          text: i18n.t("no"),
          value: "no",
        },
      },
    }).then(async (value) => {
      if (value === "yes") {
        this.setState({ winner_updating: true });
        const response = await setFirstRankWinner(selected_groups);
        if (response.data) {
          this.setState({ winner_updating: false });
          swal(i18n.t("sucess"), i18n.t("first_rank_success"), "success");
        }
      }
      return;
    });
  }

  render() {
    let { groups, auctions, bidders } = this.state;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div
                className={
                  this.state.hide_menu
                    ? "d-none"
                    : "col-xl-3 col-lg-12 col-md-12"
                }
              >
                <SideBar />
              </div>
              <div
                className={
                  this.state.hide_menu
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                {/* <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                >
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        onClick={() => {
                          this.setState({ hide_menu: !this.state.hide_menu });
                        }}
                      >
                        <i
                          class={
                            this.state.hide_menu
                              ? "mdi mdi-arrow-collapse-all"
                              : "mdi mdi-arrow-expand-all"
                          }
                          aria-hidden="true"
                        ></i>
                        &nbsp;
                      </span>
                      {i18n.t("winners_approval")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {lng === "ar"
                                      ? item.group_name_ar
                                      : item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_name")}
                          </label>

                          <div className="input-group">
                            <select
                              className="form-control"
                              data-placeholder="Select Class"
                              name="auction"
                              value={this.state.input.auction}
                              onChange={this.handleChange}
                            >
                              <option value="">--{i18n.t("auction")}--</option>
                              {auctions.length > 0 &&
                                auctions.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {lng === "ar"
                                        ? item.title_ar
                                        : item.title}
                                    </option>
                                  );
                                })}
                            </select>

                            {this.state.phone_loader && (
                              <span
                                className="input-group-addon"
                                style={{
                                  position: "absolute",
                                  marginLeft: "54%",
                                  marginTop: "10px",
                                }}
                              >
                                <i className="fa fa-refresh fa-spin"></i>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={() => {
                              let { input } = this.state;
                              if (!input.group) {
                                return;
                              }
                              this.searchSubmit();
                            }}
                            disabled={!this.state.input.group}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr /> */}

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("groups")}</h3>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive ">
                      {this.state.groups_loading && (
                        <div className="text-center">
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!this.state.groups_loading && !groups.length && (
                        <div className="alert alert-danger">
                          {i18n.t("no_result")}
                        </div>
                      )}

                      {!this.state.groups_loading && groups.length > 0 && (
                        <>
                          <div>
                            <button
                              className="btn btn-primary"
                              disabled={this.state.selected_groups?.length < 1}
                              onClick={() => {
                                this.setFirstRankWinners();
                              }}
                            >
                              {i18n.t("set_winners_with_first_rank")}
                            </button>
                          </div>
                          <div className="table-wrapper">
                            <DataTable
                              title=""
                              columns={this.state.group_columns}
                              data={groups}
                              customStyles={this.state.customStyles}
                              highlightOnHover
                              pagination
                              paginationPerPage={15}
                              paginationRowsPerPageOptions={[15, 25, 50, 100]}
                              paginationComponentOptions={{
                                rowsPerPageText: "Groups per page:",
                                rangeSeparatorText: i18n.t("out"),
                              }}
                              selectableRows={true}
                              onSelectedRowsChange={(e) => {
                                let { selectedRows } = e;
                                const selectedIds = selectedRows.map(
                                  (row) => row.id
                                );
                                console.log(selectedIds);
                                this.setState({
                                  selected_groups: selectedIds,
                                });
                              }}
                            />
                          </div>
                        </>
                      )}
                      {user.role !== "C" && bidders.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            title=""
                            columns={this.state.columns}
                            data={bidders}
                            customStyles={this.state.customStyles}
                            highlightOnHover
                            pagination
                            paginationPerPage={15}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Participants per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.modal_auctions}
          onHide={() => {
            this.setState({ modal_auctions: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("auctions_with_first_positions")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.auctions_loading}
            {this.state.auctions_loading && (
              <div className="text-center">
                <Spinner animation="border" />
              </div>
            )}
            {!this.state.auctions_loading &&
              auctions.length > 0 &&
              auctions.map((item, index) => {
                return (
                  <div className="card m-0" key={item.id}>
                    <div
                      className="card-header d-flex gap-3 justify-content-between align-items-center p-2 rounded-0 mb-0 mt-1"
                      onClick={() => {
                        let updatedAuctions = [...this.state.auctions];
                        updatedAuctions[index].isExpanded =
                          !updatedAuctions[index].isExpanded;
                        this.setState({ auctions: updatedAuctions });
                      }}
                    >
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <div className="text-primary">
                          <b>
                            {i18n.t("ID")} : {item.id}
                          </b>
                        </div>
                        <div>
                          {}
                          {lng === "ar" ? item.title_ar : item.title}
                        </div>
                      </div>
                      <div className="mx-2">
                        <i
                          className={`fa fa-${
                            item?.isExpanded ? "chevron-up" : "chevron-down"
                          } fs-1x`}
                        />
                      </div>
                    </div>
                    {item?.isExpanded && (
                      <div className="card-body p-0 m-0 mb-1 rounded-0">
                        {!item?.participants.length && (
                          <div>
                            <div className="alert alert-danger">
                              {i18n.t("no_result")}
                            </div>
                          </div>
                        )}
                        {item?.participants.length > 0 && (
                          <div className="table-responsive">
                            <table className="table table-striped table-bordered table-sm">
                              <thead>
                                <tr>
                                  <th>{i18n.t("sl_no")}</th>
                                  <th>{i18n.t("bidder_id")}</th>
                                  <th>{i18n.t("user")}</th>
                                  <th>{i18n.t("username")}</th>
                                  <th>{i18n.t("email")}</th>
                                  <th>{i18n.t("mobile")}</th>
                                  <th>{i18n.t("latest_bid_amount")}</th>
                                  <th>{i18n.t("date")}</th>
                                  <th>
                                    {i18n.t("winner")} {i18n.t("status")}
                                  </th>
                                  <th>{i18n.t("winner")}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              {item?.participants.map((bidder, i) => {
                                return (
                                  <tbody>
                                    <tr>
                                      <td className="text-nowrap">{i}</td>
                                      <td className="text-nowrap">
                                        {bidder?.enroll_number}
                                      </td>
                                      <td className="text-nowrap">
                                        {bidder?.user_id}
                                      </td>
                                      <td className="text-nowrap">
                                        {bidder?.username}
                                      </td>
                                      <td className="text-nowrap">
                                        {bidder?.email}
                                      </td>
                                      <td className="text-nowrap">
                                        {bidder?.mobile}
                                      </td>
                                      <td className="text-nowrap">
                                        {bidder?.bid_amount}
                                      </td>
                                      <td className="text-nowrap">
                                        {bidder?.created_at}
                                      </td>
                                      <td className="text-nowrap">
                                        {bidder?.winner_status === "R"
                                          ? i18n.t("requested")
                                          : ""}
                                        {bidder?.winner_status === "A"
                                          ? i18n.t("approved")
                                          : ""}
                                      </td>
                                      <td>
                                        {bidder?.winner
                                          ? i18n.t("yes")
                                          : i18n.t("no")}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="p-0 btn btn-info btn-sm px-2 py-1"
                                          disabled={
                                            user.role !== "C" &&
                                            bidder?.winner_status !== "R"
                                          }
                                          onClick={() => {
                                            let { input } = this.state;
                                            input.auction = item?.id;
                                            this.updateData(bidder?.id);
                                          }}
                                        >
                                          {i18n.t("set_winner")}
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
