import React from "react";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import {
  getAllLocs,
  createLoc,
  deleteLoc,
  updateLoc,
} from "../../models/locations";
import i18n from "../../i18n";
import { HomeBanner } from "../home/homeBanner";
import { Modal } from "react-bootstrap";
import { getDepartments } from "../../models/departments";
export class Locations extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      edit: {},
      errors: {},
      locations: [],
      departments: [],
      file_category_image: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllData();
    this.loadAllDeps();
  }

  async loadAllData() {
    const response = await getAllLocs();
    let locations = response.data;
    this.setState({ locations });
  }

  async loadAllDeps() {
    const response = await getDepartments();
    let departments = response.data;
    this.setState({ departments });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleEditChange(event) {
    let { edit } = this.state;
    edit[event.target.name] = event.target.value;
    this.setState({ edit });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (!this.state.input.name || !this.state.input.name_ar) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("name", this.state.input.name);
      formData.append("name_ar", this.state.input.name_ar);
      formData.append("latitude", this.state.input.latitude);
      formData.append("longitude", this.state.input.longitude);
      formData.append("department", this.state.input.department);
      const response = await createLoc(formData);
      if (response.success) {
        this.loadAllData();
        swal("Success", "Saved successfully.", "success");
      } else {
        swal("Failed", response.message, "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      swal(
        "Error",
        "Unable to create department. Please try again later.",
        "error"
      );
    }
  }

  async handleUpdate(event) {
    event.preventDefault();
    if (!this.state.edit.name || !this.state.edit.name_ar) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("id", this.state.edit.id);
      formData.append("name", this.state.edit.name);
      formData.append("name_ar", this.state.edit.name_ar);
      formData.append("latitude", this.state.edit.latitude);
      formData.append("longitude", this.state.edit.longitude);
      formData.append("department", this.state.edit.department);
      formData.append("_method", "put");
      const response = await updateLoc(this.state.edit.id, formData);
      if (response.success) {
        this.loadAllData();
        swal("Success", "Updated successfully.", "success");
      } else {
        swal("Failed", response.message, "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      swal(
        "Error",
        "Unable to create department. Please try again later.",
        "error"
      );
    }
  }

  deleteRow(id) {
    const response = deleteLoc(id);
    swal(i18n.t("success"), "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.loadAllData();
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  render() {
    let { locations, departments } = this.state;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id=""
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div
                    className="card-header d-flex justify-content-between align-items-center"
                    onClick={() => {
                      this.setState({ can_add: !this.state.can_add });
                    }}
                  >
                    <h3 className="card-title">Create Location</h3>
                    <span style={{ fontSize: "1.2rem" }}>
                      <b>
                        <i
                          className={`mdi mdi-${
                            this.state.can_add ? "minus" : "plus"
                          }`}
                        />
                      </b>
                    </span>
                  </div>
                  <div
                    className={`card-body ${
                      this.state.can_add ? "" : "d-none"
                    }`}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            value={this.state.input.name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">Name (Arabic)</label>
                          <input
                            type="text"
                            name="name_ar"
                            className="form-control rtl-dir"
                            placeholder="Name"
                            value={this.state.input.name_ar}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">Department</label>
                          <select
                            className="form-control form-select"
                            data-placeholder="Select department"
                            name="department"
                            value={this.state.input.department}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("department")}--</option>
                            {departments.length > 0 &&
                              departments.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">Latitude</label>
                          <input
                            type="text"
                            name="latitude"
                            className="form-control"
                            placeholder="Latitude"
                            value={this.state.input.latitude}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">Longitude</label>
                          <input
                            type="text"
                            name="longitude"
                            className="form-control rtl-dir"
                            placeholder="Longitude"
                            value={this.state.input.longitude}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button type="submit" className="btn btn-primary">
                            Add Location
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">Locations</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {locations.length < 1 && (
                        <div>{i18n.t("no_data_found")}</div>
                      )}
                      {locations.length > 0 && (
                        <div className="table-wrapper">
                          <table className="table table-bordered table-hover text-nowrap">
                            <thead>
                              <tr>
                                <th> Department</th>
                                <th> Name</th>
                                <th> Name (Arabic)</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Edit</th>
                                <th>{i18n.t("delete")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {locations.map((item, i) => {
                                return (
                                  <tr>
                                    <td>
                                      {item?.department_info?.name}
                                      <br />({item?.department_info?.name_ar})
                                    </td>
                                    <td>{item.name}</td>
                                    <td>{item.name_ar}</td>
                                    <td>{item.latitude}</td>
                                    <td>{item.longitude}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-warning btn-sm text-white m-1"
                                        onClick={() => {
                                          let { edit } = this.state;
                                          edit.id = item.id;
                                          edit.name = item.name;
                                          edit.name_ar = item.name_ar;
                                          edit.latitude = item.latitude;
                                          edit.longitude = item.longitude;
                                          edit.department = item.department;
                                          this.setState({
                                            show_edit: true,
                                            edit,
                                          });
                                        }}
                                      >
                                        Edit
                                      </button>
                                    </td>
                                    <td>
                                      {item.can_delete && (
                                        <button
                                          className="btn btn-danger btn-sm text-white m-1"
                                          onClick={(e) => {
                                            this.deleteRow(item.id);
                                          }}
                                        >
                                          <i className="fa fa-times"></i>&nbsp;
                                          {i18n.t("delete")}
                                        </button>
                                      )}
                                      {!item.can_delete && (
                                        <small className="text text-muted">
                                          Linked to an auction. Can't delete.
                                        </small>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.show_edit}
          onHide={() => this.setState({ show_edit: false })}
          size="lg"
        >
          <Modal.Title closeButton>
            <div className="d-flex justify-content-between align-items-center px-3 py-2">
              <h5 className="text-primary">Update Location</h5>
              <button
                className="btn btn-sm bg-none rounded-pill border border-danger py-0 px-1"
                onClick={() => {
                  this.setState({ show_edit: false });
                }}
              >
                <i className="mdi mdi-close" />
              </button>
            </div>
          </Modal.Title>
          <Modal.Body>
            <form
              id=""
              className="card mb-0"
              autoComplete="off"
              onSubmit={this.handleUpdate}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        value={this.state.edit.name}
                        onChange={this.handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <div className="form-group">
                      <label className="form-label">Name (Arabic)</label>
                      <input
                        type="text"
                        name="name_ar"
                        className="form-control rtl-dir"
                        placeholder="Name"
                        value={this.state.edit.name_ar}
                        onChange={this.handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <div className="form-group">
                      <label className="form-label">Department</label>
                      <select
                        className="form-control form-select"
                        data-placeholder="Select department"
                        name="department"
                        value={this.state.edit.department}
                        onChange={this.handleEditChange}
                      >
                        <option value="">--{i18n.t("department")}--</option>
                        {departments.length > 0 &&
                          departments.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <div className="form-group">
                      <label className="form-label">Latitude</label>
                      <input
                        type="text"
                        name="latitude"
                        className="form-control"
                        placeholder="Latitude"
                        value={this.state.edit.latitude}
                        onChange={this.handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <div className="form-group">
                      <label className="form-label">Longitude</label>
                      <input
                        type="text"
                        name="longitude"
                        className="form-control rtl-dir"
                        placeholder="Longitude"
                        value={this.state.edit.longitude}
                        onChange={this.handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-2">
                    <div className="form-group">
                      <label className="form-label">&nbsp;</label>
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
