import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import {
  getAuctionImages,
  uploadImage,
  getAuctionVideo,
  uploadVideo,
  removeImage,
  makeCover,
} from "../../models/auctions";
import { AuctionTabs } from "./helper/auction_tabs";
import { Spinner } from "react-bootstrap";
import { HomeBanner } from "../home/homeBanner";
import i18n from "../../i18n";
import FileInput from "../helpers/fileInput";
export class ImagePanel extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      auction: {},
      is_loading_video: false,
      is_loading_images: false,
      auctionId: auctionId,
      input: {},
      errors: {},
      videoFile: null,
      imageFiles: null,
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.uploadVideo = this.uploadVideo.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.makeCover = this.makeCover.bind(this);
  }

  componentDidMount() {
    this.loadAllImages();
    this.loadVideo();
  }

  onFileChange = (event) => {
    if (event.target.name === "videoFile") {
      this.setState({ videoFile: event.target.files[0] });
    }
    if (event.target.name === "imageFiles") {
      this.setState({ imageFiles: event.target.files });
    }
  };

  async loadAllImages() {
    this.setState({ is_loading_images: true });
    const response = await getAuctionImages(this.state.auctionId);
    if (response) {
      let images = response.data;
      this.setState({ images });
      this.setState({ is_loading_images: false });
    }
  }

  async loadVideo() {
    this.setState({ is_loading_video: true });
    const response = await getAuctionVideo(this.state.auctionId);
    let auction = response.data;
    if (auction) {
      this.setState({ auction });
      this.setState({ is_loading_video: false });
    }
  }

  async uploadVideo(event) {
    event.preventDefault();
    if (!this.state.auctionId || !this.state.videoFile) {
      swal("Failed", "File Missing", "error");
      return;
    }

    this.setState({ is_loading_video: true });
    const formData = new FormData();
    formData.append("auction_id", this.state.auctionId);
    if (this.state.videoFile) {
      formData.append(
        "videoFile",
        this.state.videoFile,
        this.state.videoFile.name
      );
    }
    const response = await uploadVideo(formData);
    if (response) {
      this.setState({ is_loading_video: false });
      if (response.success) {
        this.setState({ auction: response.data });
      } else {
        swal(
          "Failed",
          "Some error occured!! Please make sure that you are uploading video file.",
          "error"
        );
      }
    }
  }

  async uploadImage(event) {
    event.preventDefault();
    let images = this.state.imageFiles;
    if (!this.state.auctionId || !images.length) {
      swal("Failed", "File Missing", "error");
      return;
    }
    this.setState({ is_loading_images: true });
    const formData = new FormData();
    formData.append("auction_id", this.state.auctionId);
    if (images.length) {
      for (let i = 0; i < images.length; i++) {
        formData.append("imageFiles[]", images[i]);
      }
    }
    const response = await uploadImage(formData);
    if (response.success) {
      this.setState({ is_loading_images: false });
      this.loadAllImages();
    } else {
      // let error_message = response.message;
      // let resObject = response.data;
      // for (const key in resObject) {
      //   if (Object.hasOwnProperty.call(resObject, key)) {
      //     let element = resObject[key];
      //     error_message += "\n" + element;
      //   }
      // }
      swal(
        "Failed",
        "Please make sure the file formats before uploading.",
        "error"
      );
    }
  }

  async removeImage(id) {
    const response = await removeImage({
      image_id: id,
      auction: this.state.auctionId,
    });
    if (response.success) {
      this.loadAllImages();
    }
  }

  async makeCover(id) {
    const response = await makeCover({
      image_id: id,
      auction: this.state.auctionId,
    });
    if (response.success) {
      this.loadAllImages();
    }
  }

  render() {
    let images = this.state.images;
    let video = this.state.auction.video;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div
                className="col-xl-9 col-lg-12 col-md-12"
                style={{ padding: 0 }}
              >
                <AuctionTabs activeTab="uploader" />

                <div className="container mt-2 bg-white border-4 p-2">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <h4>
                      {i18n.t("media_files")}
                      <span style={{ fontSize: "0.8rem" }}>
                        {i18n.t("allowed_formats")} : .jpg, .jpeg, .png, .gif,
                        .bmp)
                      </span>
                    </h4>
                    <div className="form-group ">
                      <div className="form-group d-flex">
                        <label
                          for="form-label"
                          className="form-label"
                          style={{ textAlign: "left" }}
                        >
                          &nbsp;
                        </label>
                        <FileInput
                          wrapperClass=""
                          placeholder={i18n.t("choose_file")}
                          id="imageFiles"
                          name="imageFiles"
                          onChange={this.onFileChange}
                          className="form-control"
                          multiple={true}
                        />
                        <button
                          className="btn btn-primary"
                          onClick={this.uploadImage}
                        >
                          {i18n.t("upload")}
                        </button>
                      </div>
                    </div>
                  </div>

                  {this.state.is_loading_images && (
                    <div
                      style={{
                        width: "90%",
                        height: "150px",
                        background: "#f4f4f4",
                        marginLeft: "2rem",
                        marginRight: "2rem",
                        textAlign: "center",
                        padding: "4rem",
                      }}
                    >
                      <Spinner animation="border" />
                    </div>
                  )}

                  {!this.state.is_loading_images &&
                    images &&
                    images.length > 0 && (
                      <div className="row">
                        {images.map((item, i) => {
                          return (
                            <div
                              className="card m-5"
                              style={{ width: "15rem" }}
                              key={i}
                            >
                              {item.is_cover === 1 && (
                                <div className="ribbon-3">
                                  <span>{i18n.t("cover")}</span>
                                </div>
                              )}
                              <img
                                src={item.image}
                                alt=""
                                className="imp-image"
                              />
                              <div
                                className="card-body d-flex"
                                style={{ flexDirection: "column" }}
                              >
                                <a
                                  href={item.image}
                                  className="btn btn-primary btn-sm m-1 p-1"
                                  target="_new"
                                >
                                  <i className="fa fa-info-circle"></i>
                                  <span>{i18n.t("view")}</span>
                                </a>
                                <button
                                  type="button"
                                  className="btn btn-info btn-sm m-1 p-1"
                                  onClick={() => {
                                    this.makeCover(item.id);
                                  }}
                                >
                                  <i className="fa fa-desktop"></i>
                                  <span>{i18n.t("make_cover")}</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm m-1 p-1"
                                  onClick={() => {
                                    this.removeImage(item.id);
                                  }}
                                >
                                  <i className="fa fa-times"></i>
                                  <span>{i18n.t("delete")}</span>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>

                <div className="container mt-2 bg-white border-4 p-2">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <h4>
                      {i18n.t("video")}{" "}
                      <span style={{ fontSize: "12px" }}>
                        ({i18n.t("allowed_formats")} : mp4,ogx,oga,ogv,ogg,webm)
                      </span>
                    </h4>
                    <div className="form-group ">
                      <div className="form-group d-flex">
                        <label
                          for="form-label"
                          className="form-label"
                          style={{ textAlign: "left" }}
                        >
                          &nbsp;
                        </label>
                        <FileInput
                          wrapperClass=""
                          placeholder={i18n.t("choose_file")}
                          id="videoFile"
                          name="videoFile"
                          onChange={this.onFileChange}
                          className="form-control"
                        />
                        <button
                          className="btn btn-primary"
                          onClick={this.uploadVideo}
                        >
                          {i18n.t("upload")}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-5">
                    {this.state.is_loading_video && (
                      <div
                        style={{
                          width: "200px",
                          height: "150px",
                          background: "#f4f4f4",
                          marginLeft: "2rem",
                          marginRight: "2rem",
                          textAlign: "center",
                          padding: "4rem",
                        }}
                      >
                        <Spinner animation="border" />
                      </div>
                    )}

                    {!this.state.is_loading_video && video && (
                      <video
                        controls
                        style={{
                          width: "30%",
                          height: "auto",
                          position: "relative",
                        }}
                      >
                        <source src={video} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
