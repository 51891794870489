import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import i18n from "../../../i18n";
export class AuctionListGrouped extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let baseColor = "#284780";
    return (
      <React.Fragment>
        <div className="container">
          <div className="row mob-padd-9">
            <div className="license-plate m-auto mt-5 d-flex align-items-center m-0">
              <section>
                <i className="mdi mdi-gavel" />
              </section>
              <span>
                <Skeleton
                  height={20}
                  width={100}
                  baseColor={baseColor}
                  className="m-0"
                />
              </span>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className=" mt-5">
                <div className="">
                  <div className="item2-gl ">
                    <div className="table-responsive auctions-list">
                      <div className="bg-white p-1 mb-9">
                        <div className="table-responsive-sm table-condensed">
                          <table className="table table-sm table-striped table-primary font-1vw">
                            <thead>
                              <tr>
                                <th
                                  className="text-center font-75rem text-center"
                                  style={{ width: "50px" }}
                                  colSpan={1}
                                >
                                  <Skeleton
                                    height={30}
                                    width={30}
                                    baseColor={"#FFF"}
                                    className="card m-1"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </th>

                                <th className="font-75rem">
                                  <Skeleton
                                    height={20}
                                    width={50}
                                    baseColor="#FFF"
                                    className="card p-0 m-0"
                                    style={{ borderRadius: "1px" }}
                                  />
                                </th>
                                <th style={{ width: "15rem" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Skeleton
                                    height={200}
                                    width={300}
                                    baseColor={baseColor}
                                    className="card"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </td>
                                <td>
                                  <Skeleton
                                    height={30}
                                    width={100}
                                    baseColor={baseColor}
                                    className="card"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <Skeleton
                                      height={30}
                                      width={100}
                                      baseColor={baseColor}
                                      className="card"
                                      style={{ borderRadius: "5px" }}
                                    />
                                    &nbsp;
                                    <Skeleton
                                      height={30}
                                      width={100}
                                      baseColor={baseColor}
                                      className="card"
                                      style={{ borderRadius: "5px" }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
