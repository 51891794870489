import React from "react";
//import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { getPaymentsByType, updateRefundStatus } from "../../models/payments";
import { getLoggedUser } from "../../state/user";
import i18n from "../../i18n";
import { ListTable } from "../helpers/skeletons/ListTable";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { getAllGroups } from "../../models/groups";
import { HomeBanner } from "../home/homeBanner";
import { RefundRow } from "./helper/refundRow";

const lng = localStorage.getItem("I18N_LANGUAGE");
const user = getLoggedUser();
const statuses = {
  A: "Waiting for PM approval",
  PA: "Refund Approved",
  GA: "Waiting for CEO approval",
  CA: "Refund Approved by CEO",
  RR: "Refund Rejected",
  P: "Paid",
  N: "Not Paid",
  R: "Refunded",
};
const statusColors = {
  A: "danger",
  PA: "success",
  GA: "info",
  CA: "success",
  RR: "danger",
  P: "success",
  N: "danger",
  R: "warning",
};
const types = {
  enroll: i18n.t("enroll"),
  sitevisit: i18n.t("site_visit"),
  online: i18n.t("online"),
  offline: i18n.t("offline"),
  wallet: i18n.t("wallet"),
};
const approve_statuses = {
  PM: "PA",
  GM: "GA",
  CE: "CA",
};
const allowed_roles = ["PM", "GM", "CE", "OM"];
const approval_messages = {
  PM: i18n.t("refund_approve_text_ceo"), //i18n.t("refund_approve_text_pm"),
  GM: i18n.t("refund_approve_text_gm"),
  CE: i18n.t("refund_approve_text_ceo"),
};
export class RefundPayment extends React.Component {
  constructor() {
    super();
    this.state = {
      selected_user: {},
      is_completed: false,
      groups: [],
      search: {},
      input: {},
      approve_popup: false,
      reject_popup: false,
      status: "",
      currentPage: 1,
      total: 0,
      limit: 25,
      payments: {},
      selected_ids: [],
      selected_payments: [],
      type: "enroll",
      pay_type: "online",
      classes: {
        type_active: "btn btn-warning btn-lg border-0 rounded-0",
        type_inactive: "btn btn-outline-warning btn-lg rounded-0",
        pay_type_active: "btn btn-danger btn-lg border-0 rounded-0",
        pay_type_inactive: "btn btn-outline-danger btn-lg rounded-0",
      },
      is_loading: false,
      is_search_clicked: false,
      isChecked: false,
      isAllChecked: false,
    };
    this.updateSelected = this.updateSelected.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
    if (user.role === "CE" || user.role === "GM") {
      let { search } = this.state;
      search.type = "enroll";
      search.pay_type = "online";
      this.setState({ search });
      this.loadPayments(0, this.state.is_completed);
    }
  }

  handleSearchChange(event) {
    let { search } = this.state;
    search[event.target.name] = event.target.value;
    this.setState({ search, payments: [] });
    if (event.target.name === "type") {
      this.setState({ type: event.target.value });
    }
    const { type, pay_type, group } = this.state.search;
    if (type && pay_type && group) {
      this.loadPayments(0, this.state.is_completed);
    }
  }

  handleChange(event) {
    let { input } = this.state;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleCheckboxChange = (itemId) => {
    this.setState((prevState) => {
      const updatedPayments = prevState.payments.map((item) =>
        item.id === itemId ? { ...item, isChecked: !item.isChecked } : item
      );

      const selectedIds = updatedPayments
        .filter((item) => item.isChecked)
        .map((item) => item.id);

      const selectedPayments = selectedIds.map((id) => {
        const payment = updatedPayments.find((item) => item.id === id);
        let { type, pay_type } = this.state;
        let amount = 0;
        if (type === "enroll" && payment.group && payment.group.amount) {
          amount = payment.auction.guarantee_amount;
          if (pay_type === "wallet") {
            amount = payment.group.amount;
          }
        }
        if (type === "site_visit" && payment.amount) {
          amount = payment.amount;
        }
        return {
          id: id,
          amount: amount,
        };
      });

      return {
        payments: updatedPayments,
        selected_ids: selectedIds,
        selected_payments: selectedPayments,
      };
    });
  };

  handleCheckAll = () => {
    this.setState((prevState) => {
      const updatedPayments = prevState.payments.map((item) => ({
        ...item,
        isChecked: !prevState.isAllChecked,
      }));

      const visibleItems = updatedPayments.filter(
        (item) => !(user.role === "A" || item.rank === 1 || item.rank === 2)
      );

      const selectedIds = visibleItems
        .filter((item) => item.isChecked)
        .map((item) => item.id);

      const selectedPayments = selectedIds.map((id) => {
        const payment = updatedPayments.find((item) => item.id === id);
        let { type, pay_type } = this.state;
        let amount = 0;
        if (type === "enroll" && payment.group && payment.group.amount) {
          amount = payment.auction.guarantee_amount;
          if (pay_type === "wallet") {
            amount = payment.group.amount;
          }
        }
        if (type === "site_visit" && payment.amount) {
          amount = payment.amount;
        }
        return {
          id: id,
          amount: amount,
        };
      });
      console.log(selectedPayments);
      return {
        payments: updatedPayments,
        isAllChecked: !prevState.isAllChecked,
        selected_ids: selectedIds,
        selected_payments: selectedPayments,
      };
    });
  };

  handleNextPage = () => {
    let currentPage = Number(this.state.currentPage) + 1;
    this.setState({ currentPage });
    this.loadPayments(currentPage, this.state.is_completed);
  };

  handlePrevPage = () => {
    let currentPage = Number(this.state.currentPage) - 1;
    this.setState({ currentPage });
    this.loadPayments(currentPage, this.state.is_completed);
  };

  async loadPayments(currentPage, is_completed) {
    this.setState({ is_completed: is_completed });
    let { limit } = this.state;
    let { type, pay_type, group } = this.state.search;
    if (!type || !pay_type) {
      return;
    }
    this.setState({
      is_loading: true,
      isAllChecked: false,
      isChecked: false,
      selected_ids: [],
    });
    let q =
      "type=" +
      type +
      "&pay_type=" +
      pay_type +
      "&limit=" +
      limit +
      "&page=" +
      currentPage;
    if (group) {
      q += "&group=" + group;
    }
    if (is_completed) {
      q += "&is_completed=1";
    }
    let response = await getPaymentsByType(q);
    let payments = response.data;
    payments = payments.map((item) => ({
      ...item,
      isChecked: false,
    }));

    if (response.success) {
      this.setState({ payments: payments, total: response.meta.total });
      this.setState({ is_search_clicked: false });
    }
    this.setState({ is_loading: false });
  }

  async updateSelected(event, reject) {
    event.preventDefault();
    let status = approve_statuses[user.role];
    if (reject) {
      status = "RR";
    }
    let { selected_ids, input, type, currentPage, selected_payments } =
      this.state;
    let payload = {
      ids: selected_ids,
      amounts: selected_payments,
      status: status,
      refund_reject_reason: reject ? input.refund_reject_reason : "",
      type: type,
      refund_amount: input.refund_amount,
    };

    const response = await updateRefundStatus(payload);
    if (response.success) {
      swal(i18n.t("success"), "Staus updated for refund process", "success", {
        buttons: false,
        timer: 1000,
      })
        .then((value) => {
          this.loadPayments(currentPage, this.state.is_completed);
          this.setState({ withdraw_popup: false });
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      let error_message = response.message;
      swal("Failed", error_message, "error");
    }

    input.refund_reject_reason = "";
    this.setState({ reject_popup: false, approve_popup: false, input });
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
    let meta = response.meta;
    if (meta && meta.active_group) {
      let { input } = this.state;
      input.group = meta.active_group.id;
      this.setState({
        input: input,
      });
    }
  }

  render() {
    const {
      payments,
      isAllChecked,
      currentPage,
      total,
      selected_ids,
      //selected_payments,
      groups,
      limit,
      is_completed,
    } = this.state;
    const { type, pay_type, group } = this.state.search;
    const totalPages = Math.ceil(total / limit);
    let completed_class = is_completed
      ? "btn btn-success rounded-0 mx-0"
      : "btn btn-outline-success rounded-0 mx-0";
    let pending_class = !is_completed
      ? "btn btn-success rounded-0 mx-0"
      : "btn btn-outline-success rounded-0 mx-0";
    //console.log(selected_payments);
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between rounded-5">
                    <h3 className="card-title">{i18n.t("payments")}</h3>
                    <div>
                      <button
                        className={pending_class}
                        onClick={() => {
                          this.setState({ is_completed: false });
                          this.loadPayments(0, false);
                        }}
                      >
                        {i18n.t("pending")}
                      </button>
                      <button
                        className={completed_class}
                        onClick={() => {
                          this.setState({ is_completed: true });
                          this.loadPayments(0, true);
                        }}
                      >
                        {i18n.t("completed")}
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0 pb-3">
                    <div className="row mb-3 px-3">
                      <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="d-flex">
                          <select
                            className="form-select select fs14 text-black"
                            data-placeholder="Select"
                            name="type"
                            value={this.state.search.type}
                            onChange={this.handleSearchChange}
                          >
                            <option value="">--{i18n.t("type")}--</option>
                            <option value="enroll">{i18n.t("enroll")}</option>
                            <option value="site_visit">
                              {i18n.t("site_visit")}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="d-flex">
                          <select
                            className="form-select select fs14 text-black"
                            data-placeholder="Select"
                            name="pay_type"
                            value={this.state.search.pay_type}
                            onChange={this.handleSearchChange}
                          >
                            <option value="">--{i18n.t("type")}--</option>
                            <option value="online">{i18n.t("online")}</option>
                            <option value="offline">{i18n.t("offline")}</option>
                            <option value="wallet">{i18n.t("wallet")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="d-flex">
                          <select
                            className="form-select select fs14 text-black"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.search.group}
                            onChange={this.handleSearchChange}
                          >
                            <option value="">
                              --{i18n.t("auction_group")}--
                            </option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        <button
                          className="btn btn-outline-dark rounded-5"
                          onClick={(e) => {
                            this.setState({
                              is_search_clicked: true,
                              currentPage: 1,
                            });
                            this.loadPayments(0, this.state.is_completed);
                          }}
                          disabled={!type || !pay_type || !group}
                        >
                          <i className="fa fa-search" />
                          &nbsp;
                          {i18n.t("search")}
                        </button>
                      </div>
                    </div>

                    <div className="table-responsive mx-3">
                      {this.state.is_loading && (
                        <div className="text-center">
                          <ListTable />
                        </div>
                      )}

                      {!this.state.is_loading &&
                        type &&
                        pay_type &&
                        group &&
                        payments.length === 0 && (
                          <div className="row pt-5">
                            <div className="col-6 m-auto">
                              <div className="alert border-danger text-danger text-center">
                                {i18n.t("no_payments_found")}
                              </div>
                            </div>
                          </div>
                        )}

                      {!this.state.is_loading && payments.length > 0 && (
                        <div>
                          <div className="d-flex justify-content-between mb-1">
                            <div
                              className={
                                lng === "ar"
                                  ? "d-flex flex-row-reverse"
                                  : "d-flex"
                              }
                            >
                              <button
                                className="btn btn-sm btn-dark rounded-0"
                                onClick={this.handlePrevPage}
                                disabled={currentPage === 1}
                              >
                                <i className="fa fa-angle-left"></i>
                              </button>
                              &nbsp;
                              <button
                                className="btn btn-sm btn-dark rounded-0"
                                onClick={this.handleNextPage}
                                disabled={currentPage === totalPages}
                              >
                                <i className="fa fa-angle-right"></i>
                              </button>
                            </div>
                            {allowed_roles.includes(user.role) && (
                              <div>
                                <button
                                  className="btn btn-sm btn-warning rounded-5"
                                  onClick={() => {
                                    this.setState({ approve_popup: true });
                                  }}
                                  disabled={selected_ids.length < 1}
                                >
                                  {i18n.t("approve")}
                                </button>
                                &nbsp;
                                {(user.role === "PM" || user.role === "OM") && (
                                  <button
                                    className="btn btn-sm btn-danger rounded-5"
                                    onClick={() => {
                                      this.setState({ reject_popup: true });
                                    }}
                                    disabled={selected_ids.length < 1}
                                  >
                                    {i18n.t("reject")}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                          <table
                            className="table table-bordered"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <thead className="thead-light">
                              <tr>
                                {!this.state.is_completed && (
                                  <th>
                                    <input
                                      type="checkbox"
                                      checked={isAllChecked}
                                      onChange={this.handleCheckAll}
                                      disabled={user.role === "A"}
                                    />
                                  </th>
                                )}
                                <th>{i18n.t("#")}</th>
                                <th>{i18n.t("ID")}</th>
                                <th>{i18n.t("pay_type")}</th>
                                <th>{i18n.t("User ID")}</th>
                                <th></th>
                                <th>{i18n.t("name")}</th>
                                <th>{i18n.t("group")}</th>
                                <th>{i18n.t("auction")}</th>
                                <th>{i18n.t("price")}</th>
                                <th>{i18n.t("payment_date")}</th>
                                <th>{i18n.t("rank")}</th>
                                <th>{i18n.t("status")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {payments.map((item, i) => {
                                return (
                                  <RefundRow
                                    index={i}
                                    item={item}
                                    type={type}
                                    pay_type={pay_type}
                                    is_completed={this.state.is_completed}
                                    showInfoPopup={(e) => {
                                      this.setState({
                                        selected_user: e,
                                      });
                                      this.setState({
                                        show_info_popup: true,
                                      });
                                    }}
                                    checkboxChange={(id) => {
                                      this.handleCheckboxChange(id);
                                    }}
                                  />
                                );
                              })}
                            </tbody>
                          </table>

                          <div className="d-flex justify-content-between">
                            <div
                              className={
                                lng === "ar"
                                  ? "d-flex flex-row-reverse"
                                  : "d-flex"
                              }
                            >
                              <button
                                className="btn btn-sm btn-dark rounded-0"
                                onClick={this.handlePrevPage}
                                disabled={currentPage === 1}
                              >
                                <i className="fa fa-angle-left"></i>
                              </button>
                              &nbsp;
                              <button
                                className="btn btn-sm btn-dark rounded-0"
                                onClick={this.handleNextPage}
                                disabled={currentPage === totalPages}
                              >
                                <i className="fa fa-angle-right"></i>
                              </button>
                            </div>
                            {allowed_roles.includes(user.role) && (
                              <div>
                                <button
                                  className="btn btn-sm btn-warning rounded-5"
                                  onClick={() => {
                                    this.setState({ approve_popup: true });
                                  }}
                                  disabled={selected_ids.length < 1}
                                >
                                  {i18n.t("approve")}
                                </button>
                                &nbsp;
                                {(user.role === "PM" || user.role === "OM") && (
                                  <button
                                    className="btn btn-sm btn-danger rounded-5"
                                    onClick={() => {
                                      this.setState({ reject_popup: true });
                                    }}
                                    disabled={selected_ids.length < 1}
                                  >
                                    {i18n.t("reject")}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.approve_popup}
          onHide={() => this.setState({ approve_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("confirm")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-4">
              <div className="col-12">{approval_messages[user.role]}</div>
            </div>
            {/* <div className="row d-flex align-items-center justify-content-center">
              <div className="col-9">
                <input
                  type="text"
                  className="form-control border border-info rounded-5 text-center"
                  placeholder={i18n.t("amount")}
                  name="refund_amount"
                  value={this.state.input.refund_amount}
                  onChange={this.handleChange}
                />
              </div>
            </div> */}
          </Modal.Body>
          <Modal.Footer className="justify-content-between px-4">
            <button
              className="btn btn-light btn-md border"
              onClick={() => this.setState({ approve_popup: false })}
            >
              {i18n.t("close")}
            </button>
            <button
              className="btn btn-success btn-md"
              onClick={this.updateSelected}
            >
              {i18n.t("confirm")}
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.reject_popup}
          onHide={() => {
            let { input } = this.state;
            input.refund_reject_reason = "";
            this.setState({ reject_popup: false, input });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("reject")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-4">
              <div className="col-12">{i18n.t("reject_text")}</div>
            </div>
            <form
              id="wallet-withdraw"
              className="row"
              tabIndex="500"
              autoComplete="off"
            >
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder={i18n.t("reject_reason")}
                    name="refund_reject_reason"
                    value={this.state.input.refund_reject_reason}
                    onChange={this.handleChange}
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <div className="col-3">
                <button
                  className="btn btn-danger btn-md btn-block"
                  onClick={(e) => {
                    this.updateSelected(e, true);
                  }}
                  disabled={!this.state.input.refund_reject_reason}
                >
                  {i18n.t("submit")}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.show_info_popup}
          onHide={() => {
            let { selected_user } = this.state;
            selected_user = "";
            this.setState({ show_info_popup: false, selected_user });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("bank_details")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2">
              <p>
                {i18n.t("bank")} :{" "}
                <b>
                  {this.state.selected_user && this.state.selected_user.bank}
                </b>
              </p>
              <p>
                {i18n.t("account")} :{" "}
                <b>
                  {this.state.selected_user &&
                    this.state.selected_user.account_number}
                </b>
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
