import React from "react";
import { Link } from "react-router-dom";
import registerUser from "../../models/register";
import swal from "sweetalert";
import contryData from "../../constants/country.json";
import i18n from "../../i18n";
import { baseUrl } from "../../constants/global";
import Dropdown from "react-bootstrap/Dropdown";
import { HomeBanner } from "../home/homeBanner";
import FileInputNew from "../helpers/fileInputNew";
import { Modal } from "react-bootstrap";
import { PasswordRules } from "../helpers/passwordRules";

let lng = localStorage.getItem("I18N_LANGUAGE");
const codeData = [...contryData];
export class CompanyRegistration extends React.Component {
  constructor() {
    super();
    this.state = {
      has_password_rules: false,
      input: {
        country_code: "+968",
      },
      is_company: true,
      selected_country: {},
      t_c_acept: false,
      errors: {},
      file_auth_letter: null,
      file_id_number: null,
      file_cr_number: null,
      file_vat_certificate: null,
      rules: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }
  componentDidMount() {
    var scrollDiv = document.getElementById("scroll-to");
    if (scrollDiv) {
      scrollDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    var defaultCodeValue = this.getObjectByKeyValue(
      codeData,
      "dial_code",
      "+968"
    );
    this.setState({ selected_country: defaultCodeValue });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
    if (
      event.target.name === "password" ||
      event.target.name === "confirm_password"
    ) {
      const lengthCriteria = input["password"]
        ? input["password"].length > 8
        : false;
      const specialCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(input["password"]);
      const numberCriteria = /\d/.test(input["password"]);
      const capitalCriteria = /[A-Z]/.test(input["password"]);
      const matchCriteria = input["password"] === input["confirm_password"];
      let errors = [];
      let rules = {
        char8: true,
        charSpecial: true,
        number: true,
        capital: true,
        match: true,
      };
      if (!lengthCriteria) {
        errors["password"] = i18n.t("password_rules_mandatory");
        rules.char8 = false;
      }
      if (!specialCriteria) {
        errors["password"] = i18n.t("password_rules_mandatory");
        rules.charSpecial = false;
      }
      if (!numberCriteria) {
        errors["password"] = i18n.t("password_rules_mandatory");
        rules.number = false;
      }
      if (!capitalCriteria) {
        errors["password"] = i18n.t("password_rules_mandatory");
        rules.capital = false;
      }
      if (!matchCriteria) {
        errors["password"] = i18n.t("password_rules_mandatory");
        rules.match = false;
      }
      this.setState({ rules, errors });
    }
  }

  onFileChange = (event) => {
    if (!this.validateFileSize(event.target.files[0])) {
      swal("Failed", "File size should be less than 5MB", "error");
      return;
    }
    if (event.target.name === "file_auth_letter") {
      this.setState({ file_auth_letter: event.target.files[0] });
    }
    if (event.target.name === "file_id_number") {
      this.setState({ file_id_number: event.target.files[0] });
    }
    if (event.target.name === "file_cr_number") {
      this.setState({ file_cr_number: event.target.files[0] });
    }
    if (event.target.name === "file_vat_certificate") {
      this.setState({ file_vat_certificate: event.target.files[0] });
    }
  };

  validateFileSize(file) {
    //const MIN_FILE_SIZE = 1024; // 1MB
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    return true;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.validate()) {
      try {
        const formData = new FormData();
        formData.append("name", this.state.input.name);
        formData.append("country_code", this.state.input.country_code);
        formData.append("mobile", this.state.input.mobile);
        formData.append("email", this.state.input.email);
        formData.append("username", this.state.input.username);
        formData.append("password", this.state.input.password);
        formData.append("confirm_password", this.state.input.confirm_password);
        formData.append(
          "resident_card_number",
          this.state.input.resident_card_number
        );
        formData.append("account_number", this.state.input.account_number);
        formData.append("beneficiary", this.state.input.beneficiary);

        formData.append("bank", this.state.input.bank);
        if (this.state.is_company) {
          formData.append("authority_name", this.state.input.authority_name);
          formData.append("cr_number", this.state.input.cr_number);
          formData.append("vat_number", this.state.input.vat_number);
        }

        formData.append("is_company", this.state.is_company ? "1" : "0");

        if (this.state.file_id_number) {
          formData.append(
            "file_id_number",
            this.state.file_id_number,
            this.state.file_id_number.name
          );
        }
        if (this.state.file_auth_letter) {
          formData.append(
            "file_auth_letter",
            this.state.file_auth_letter,
            this.state.file_auth_letter.name
          );
        }
        if (this.state.file_cr_number) {
          formData.append(
            "file_cr_number",
            this.state.file_cr_number,
            this.state.file_cr_number.name
          );
        }
        if (this.state.file_vat_certificate) {
          formData.append(
            "file_vat_certificate",
            this.state.file_vat_certificate,
            this.state.file_vat_certificate.name
          );
        }
        const response = await registerUser(formData);
        if (response.success) {
          swal(
            i18n.t("success"),
            "Registration completed successfully!!",
            "success",
            {
              buttons: false,
              timer: 2000,
            }
          )
            .then((value) => {
              console.log(response.data);
              //this.resetForm();
              //window.location.href = "/login";
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  resetForm() {
    let input = {};
    input["name"] = "";
    input["mobile"] = "";
    input["email"] = "";
    input["username"] = "";
    input["country_code"] = "+968";
    input["password"] = "";
    input["confirm_password"] = "";
    this.setState({
      input: input,
      file_auth_letter: null,
      file_id_number: null,
      file_cr_number: null,
      file_vat_certificate: null,
    });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    let msg = "";
    if (!input["name"]) {
      isValid = false;
      errors["name"] = i18n.t("validate_name");
      msg = i18n.t("validate_name");
    }
    if (!input["username"]) {
      isValid = false;
      errors["username"] = i18n.t("validate_username");
    }
    if (!input["country_code"]) {
      isValid = false;
      errors["country_code"] = i18n.t("validate_country_code");
      msg = i18n.t("validate_country_code");
    }
    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = i18n.t("validate_phone");
      msg = i18n.t("validate_phone");
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!input["email"] || !pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = i18n.t("validate_email");
      msg = i18n.t("validate_email");
    }
    const lengthCriteria = input["password"]
      ? input["password"].length > 8
      : false;
    const specialCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(input["password"]);
    const numberCriteria = /\d/.test(input["password"]);
    const capitalCriteria = /[A-Z]/.test(input["password"]);
    const matchCriteria = input["password"] === input["confirm_password"];

    let rules = {
      char8: true,
      charSpecial: true,
      number: true,
      capital: true,
      match: true,
    };
    if (!lengthCriteria) {
      isValid = false;
      errors["password"] = i18n.t("password_rules_mandatory");
      rules.char8 = false;
      msg = i18n.t("password_length");
    }
    if (!specialCriteria) {
      isValid = false;
      errors["password"] = i18n.t("password_rules_mandatory");
      rules.charSpecial = false;
      msg = i18n.t("password_special");
    }
    if (!numberCriteria) {
      isValid = false;
      errors["password"] = i18n.t("password_rules_mandatory");
      rules.number = false;
      msg = i18n.t("password_number");
    }
    if (!capitalCriteria) {
      isValid = false;
      errors["password"] = i18n.t("password_rules_mandatory");
      rules.capital = false;
      msg = i18n.t("password_capital");
    }
    if (!matchCriteria) {
      isValid = false;
      errors["password"] = i18n.t("password_rules_mandatory");
      rules.match = false;
      msg = i18n.t("password_match");
    }

    this.setState({ rules });

    if (!input["resident_card_number"]) {
      isValid = false;
      errors["resident_card_number"] = i18n.t("validate_res_card_no");
      msg = i18n.t("validate_res_card_no");
    }
    if (!input["account_number"]) {
      isValid = false;
      errors["account_number"] = i18n.t("validate_account_no");
      msg = i18n.t("validate_account_no");
    }

    if (!input["beneficiary"]) {
      isValid = false;
      errors["beneficiary"] = i18n.t("validate_beneficiary");
      msg = i18n.t("validate_beneficiary");
    }

    if (!this.state.file_id_number) {
      isValid = false;
      errors["file_id_number"] = i18n.t("validate_file_id");
      msg = i18n.t("validate_file_id");
    }

    if (this.state.is_company) {
      if (!input["authority_name"]) {
        isValid = false;
        errors["authority_name"] = i18n.t("validate_auth_name");
        msg = i18n.t("validate_auth_name");
      }

      if (!this.state.file_auth_letter) {
        isValid = false;
        errors["file_auth_letter"] = i18n.t("validate_file_auth");
        msg = i18n.t("validate_file_auth");
      }

      if (!input["cr_number"]) {
        isValid = false;
        errors["cr_number"] = i18n.t("validate_cr_number");
        msg = i18n.t("validate_cr_number");
      }

      if (!this.state.file_cr_number) {
        isValid = false;
        errors["file_cr_number"] = i18n.t("validate_file_cr");
        msg = i18n.t("validate_file_cr");
      }

      if (!input["vat_number"]) {
        isValid = false;
        errors["vat_number"] = i18n.t("validate_vat_no");
        msg = i18n.t("validate_vat_no");
      }

      if (!this.state.file_vat_certificate) {
        isValid = false;
        errors["file_vat_certificate"] = i18n.t("validate_file_vat");
        msg = i18n.t("validate_file_vat");
      }
    }

    this.setState({
      errors: errors,
    });
    if (!isValid && msg) {
      swal(i18n.t("failed"), msg, "error");
    }
    return isValid;
  }

  getObjectByKeyValue(jsonArray, key, value) {
    for (var i = 0; i < jsonArray.length; i++) {
      if (jsonArray[i][key] === value) {
        return jsonArray[i];
      }
    }
    return null;
  }

  render() {
    let selected_img =
      this.state.selected_country && this.state.selected_country.code
        ? this.state.selected_country.code.toLowerCase()
        : "";
    return (
      <React.Fragment>
        <HomeBanner />
        <div className="page-container">
          <div className="content-bg">
            <div className="container py-5">
              <div className="row justify-content-center">
                <div className="mx-auto">
                  <div className="col-lg-12 col-lg-6 col-lg-3 d-flex justify-content-center">
                    <div className="card w-65">
                      <div
                        className="card-header bg-primary"
                        style={{ zIndex: "999" }}
                      >
                        <h4>
                          <i
                            className="mdi mdi-account-plus"
                            style={{ fontSize: "1.5rem" }}
                          />
                          &nbsp;{i18n.t("signup")} - {i18n.t("company")}
                        </h4>
                      </div>
                      <div
                        className="card-body px-3"
                        style={{ zIndex: "99", marginTop: "-35px" }}
                      >
                        <form
                          id="Register"
                          className=""
                          tabIndex="500"
                          autoComplete="off"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="container container-fluid">
                            <div className="row">
                              <div className="mb-4">
                                <div className="d-flex justify-content-between align-items-center p-2">
                                  <div>
                                    <span className="registraion_type text-primary">
                                      <b>{i18n.t("reg_type")}</b>
                                    </span>
                                  </div>
                                  <div className="text-end py-4 d-flex gap-1">
                                    <a
                                      className=""
                                      target="_new"
                                      href={baseUrl + "/guide/registration.pdf"}
                                    >
                                      <img
                                        height="15.157"
                                        width="15.157"
                                        src="./assets/images/uiw_file-pdf.png"
                                        alt=""
                                      />
                                      <span className="custom-text3">
                                        {i18n.t("user_guide")}
                                      </span>
                                    </a>
                                  </div>
                                </div>
                                <div>
                                  <div className="p-0 ms-2 bg-none vertical-oval_mobile border-0">
                                    <div className="d-flex">
                                      <button
                                        type="button"
                                        className="btn rounded  flex-fill custom-button-color"
                                      >
                                        <div className="">
                                          <div className="text-white">
                                            <i className="mdi mdi-office" />
                                            {i18n.t("company")}
                                          </div>
                                        </div>
                                      </button>
                                      <Link
                                        to="/registration"
                                        className="btn rounded border border-primary  flex-fill"
                                      >
                                        <div className="text-primary">
                                          <div>
                                            <i className="mdi mdi-human-male" />
                                            {i18n.t("individual")}
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <fieldset className="scheduler-border">
                            <legend className="scheduler-border">
                              {i18n.t("reg_type")}
                            </legend>
                            <div className="btn-group d-flex">
                              <button
                                type="button"
                                className={
                                  !this.state.is_company
                                    ? "btn btn-danger w-100"
                                    : "btn btn-outline-danger w-100"
                                }
                                onClick={(event) => {
                                  this.setState({
                                    is_company: false,
                                  });
                                }}
                              >
                                {i18n.t("individual")}
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                className={
                                  this.state.is_company
                                    ? "btn btn-danger w-100"
                                    : "btn btn-outline-danger w-100"
                                }
                                onClick={(event) => {
                                  this.setState({
                                    is_company: true,
                                  });
                                }}
                              >
                                {i18n.t("company")}
                              </button>
                            </div>
                          </fieldset> */}
                            </div>

                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("company_name")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="name"
                                    value={this.state.input.name}
                                    onChange={this.handleChange}
                                    autoComplete="name"
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.name}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("auth_name")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="authority_name"
                                    value={this.state.input.authority_name}
                                    onChange={this.handleChange}
                                    autoComplete="authority_name"
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.authority_name}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    for="form-label"
                                    className="form-label"
                                    style={{ top: "inherit" }}
                                  >
                                    <span className="text-black">*</span>
                                    {i18n.t("auth_letter")}{" "}
                                  </label>

                                  <FileInputNew
                                    wrapperClass=""
                                    placeholder={i18n.t("choose_file")}
                                    className="form-control pad-adjust mt-3 custom-input "
                                    name="file_auth_letter"
                                    id="file_auth_letter"
                                    onChange={this.onFileChange}
                                  />
                                  <div className="d-flex justify-content-end">
                                    <a
                                      href="https://rop.mzadcom.om/docs/auth.pdf"
                                      target="_new"
                                    >
                                      {i18n.t("view_format")}
                                    </a>
                                  </div>
                                  <div className="text-danger text-justify">
                                    {this.state.errors.file_auth_letter}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("phone_number")}
                                  </label>
                                </div>

                                <div>
                                  <Dropdown className="width30 flag-dd">
                                    <Dropdown.Toggle variant="country">
                                      {this.state.selected_country && (
                                        <div>
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/images/flags/" +
                                              selected_img +
                                              ".svg"
                                            }
                                            alt=""
                                            style={{ width: "25px" }}
                                          />
                                          {/* {this.state.selected_country.label} */}
                                        </div>
                                      )}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {codeData.map((item, i) => {
                                        let img = item.code.toLowerCase();
                                        return (
                                          <Dropdown.Item
                                            onClick={() => {
                                              let { input } = this.state;
                                              input.country_code =
                                                item.dial_code;
                                              this.setState({
                                                input: item,
                                                selected_country: item,
                                              });
                                            }}
                                          >
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/images/flags/" +
                                                img +
                                                ".svg"
                                              }
                                              alt=""
                                              style={{ width: "25px" }}
                                            />{" "}
                                            {item.label}
                                          </Dropdown.Item>
                                        );
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>

                                  <input
                                    type="text"
                                    style={{ "z-index": 0 }}
                                    name="mobile"
                                    value={this.state.input.mobile}
                                    onChange={this.handleChange}
                                    className="form-control custom-input  px-6"
                                  />
                                </div>

                                <div className="text-danger text-justify">
                                  {this.state.errors.mobile}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("email")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="email"
                                    value={this.state.input.email}
                                    onChange={this.handleChange}
                                    style={{ direction: "ltr" }}
                                    className="form-control custom-input "
                                  />
                                </div>

                                <div className="text-danger text-justify">
                                  {this.state.errors.email}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("username")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="username"
                                    value={this.state.input.username}
                                    onChange={this.handleChange}
                                    autoComplete="username"
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.username}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div className="d-flex justify-content-start gap-2">
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("password")}
                                  </label>
                                  <button
                                    type="button"
                                    className="p-0 roundded-pill m-0 bg-none text-info border-0"
                                    onClick={() => {
                                      this.setState({
                                        has_password_rules:
                                          !this.state.has_password_rules,
                                      });
                                    }}
                                    ref={this.target}
                                  >
                                    <i className="fa fa-info-circle" />
                                  </button>
                                </div>

                                <div>
                                  <input
                                    type="password"
                                    name="password"
                                    value={this.state.input.password}
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.password}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("confirm_password")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="password"
                                    name="confirm_password"
                                    value={this.state.input.confirm_password}
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.confirm_password}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("personal_card_no")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="resident_card_number"
                                    value={
                                      this.state.input.resident_card_number
                                    }
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.resident_card_number}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label for="form-label">
                                    <span className="text-black">*</span>
                                    {i18n.t("attach_id_no")}
                                  </label>
                                </div>

                                <div>
                                  <FileInputNew
                                    wrapperClass=""
                                    className="form-control custom-input "
                                    placeholder={i18n.t("choose_file")}
                                    name="file_id_number"
                                    id="file_id_number"
                                    onChange={this.onFileChange}
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.file_id_number}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>
                                    <span className="text-black">*</span>
                                    {i18n.t("bank")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="bank"
                                    value={this.state.input.bank}
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.bank}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>
                                    <span className="text-black">*</span>
                                    {i18n.t("account_number")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="account_number"
                                    value={this.state.input.account_number}
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.account_number}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>
                                    <span className="text-black">*</span>
                                    {i18n.t("beneficiary")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="beneficiary"
                                    value={this.state.input.beneficiary}
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.beneficiary}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>
                                    <span className="text-black">*</span>
                                    {i18n.t("cr_number")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={this.state.input.cr_number}
                                    name="cr_number"
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.cr_number}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>
                                    <span className="text-black">*</span>
                                    {i18n.t("attacth_cr_copy")}
                                  </label>
                                </div>

                                <div>
                                  <FileInputNew
                                    wrapperClass=""
                                    placeholder={i18n.t("choose_file")}
                                    className="form-control custom-input "
                                    name="file_cr_number"
                                    id="file_cr_number"
                                    onChange={this.onFileChange}
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.file_cr_number}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>
                                    <span className="text-black">*</span>
                                    {i18n.t("vat_number")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    className="form-control custom-input "
                                    placeholder=""
                                    value={this.state.input.vat_number}
                                    name="vat_number"
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.vat_number}
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label>
                                    <span className="text-black">*</span>
                                    {i18n.t("attach_vat")}
                                  </label>
                                </div>

                                <div>
                                  <FileInputNew
                                    wrapperClass=""
                                    placeholder={i18n.t("choose_file")}
                                    className="form-control custom-input "
                                    name="file_vat_certificate"
                                    id="file_vat_certificate"
                                    onChange={this.onFileChange}
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.file_vat_certificate}
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                textAlign: lng === "ar" ? "right" : "left",
                              }}
                            >
                              <input
                                style={{
                                  height: "15px",
                                  padding: "0",
                                  width: "15px",
                                }}
                                type="checkbox"
                                name="t_c"
                                checked={this.state.t_c_acept}
                                onChange={(event) => {
                                  this.setState({
                                    t_c_acept: !this.state.t_c_acept,
                                  });
                                }}
                              />{" "}
                              <span className="text text-dark">
                                {i18n.t("please_accept")}{" "}
                                <a
                                  href="/terms"
                                  target="_new"
                                  className="text-blue"
                                >
                                  {i18n.t("terms_conditions")}
                                </a>
                              </span>
                            </div>

                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-color "
                                disabled={!this.state.t_c_acept}
                              >
                                {i18n.t("signup")}
                              </button>
                            </div>

                            <div className="text-center">
                              <p className="mt-3">
                                {i18n.t("alreadyhaveaccount")}{" "}
                                <Link to="/login" className="text-blue">
                                  {" "}
                                  {i18n.t("login")}{" "}
                                </Link>
                              </p>
                            </div>
                            {/* <div className="horizontal-line">
                              <span className="line-text">
                                {i18n.t("or_continue")}
                              </span>
                            </div>
                            <div className="text-center">
                              <SocialSignin />
                            </div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          size=""
          show={this.state.has_password_rules}
          onHide={() => {
            this.setState({ has_password_rules: false });
          }}
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title id="rules-1">
              <h5>
                {i18n.t("password")} {i18n.t("rules")}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PasswordRules rules={this.state.rules} />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
