import React from "react";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import { getAuction, upsertVehicle } from "../../models/auctions";
import { AuctionTabs } from "./helper/auction_tabs";
import { Spinner } from "react-bootstrap";
import { HomeBanner } from "../home/homeBanner";
import i18n from "../../i18n";

export class VehiclePanel extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      auction: {},
      is_loading: false,
      is_saving: false,
      auctionId: auctionId,
      input: {
        auction: auctionId,
        vehicle_number: "",
        make: "",
        model: "",
        model_ar: "",
        mileage: "",
        mileage_ar: "",
        information_number: "",
        transmission_type: "",
        transmission_type_ar: "",
        extras: "",
        body_type: "",
        body_type_ar: "",
        fuel_type: "",
        fuel_type_ar: "",
        warranty: "",
        color: "",
        color_ar: "",
        engine_size: "",
        no_of_keys: "",
        document_type: "",
      },
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.loadAuction();
  }

  handleChange(event) {
    let { input } = this.state;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async loadAuction() {
    this.setState({ is_loading: true });
    const response = await getAuction(this.state.auctionId);
    if (response) {
      let auction = response.data;
      this.setState({ auction });
      if (auction?.vehicle_info) {
        this.setState({ input: auction?.vehicle_info });
      }
      this.setState({ is_loading: false });
    }
  }

  async submitForm() {
    let { input } = this.state;
    this.setState({ is_saving: true });
    const response = await upsertVehicle({ ...input });
    if (response.success) {
      this.setState({ input: response.data });
      this.setState({ is_saving: false });
    } else {
      this.setState({ is_saving: false });
    }
  }

  render() {
    let { auction, is_loading } = this.state;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div
                className="col-xl-9 col-lg-12 col-md-12"
                style={{ padding: 0 }}
              >
                <AuctionTabs activeTab="vehicle" />

                {!is_loading && auction?.is_vehicle ? (
                  <div className="container mt-2 bg-white border p-2 rounded-4 m-1">
                    <div className="row">
                      <div className="container">
                        {is_loading && <Spinner animation="grow" />}
                        <div className="row bg bg-light">
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("number")}
                              </label>
                              <input
                                type="text"
                                required={true}
                                name="vehicle_number"
                                className="form-control"
                                placeholder={i18n.t("number")}
                                value={this.state.input.vehicle_number}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("make")}
                              </label>
                              <input
                                type="text"
                                name="make"
                                className="form-control"
                                placeholder={i18n.t("make")}
                                value={this.state.input?.make}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("model")}
                              </label>
                              <input
                                type="text"
                                name="model"
                                className="form-control"
                                placeholder={i18n.t("model")}
                                value={this.state.input?.model}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("model")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="model_ar"
                                className="form-control"
                                placeholder={i18n.t("model")}
                                value={this.state.input?.model_ar}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("mileage")}
                              </label>
                              <input
                                type="text"
                                name="mileage"
                                className="form-control"
                                placeholder={i18n.t("mileage")}
                                value={this.state.input?.mileage}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("mileage")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="mileage_ar"
                                className="form-control"
                                placeholder={i18n.t("mileage")}
                                value={this.state.input?.mileage_ar}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("transmission_type")}
                              </label>
                              <input
                                type="text"
                                name="transmission_type"
                                className="form-control"
                                placeholder={i18n.t("transmission_type")}
                                value={this.state.input?.transmission_type}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("transmission_type")}({i18n.t("arabic")}
                                )
                              </label>
                              <input
                                type="text"
                                name="transmission_type_ar"
                                className="form-control"
                                placeholder={i18n.t("transmission_type")}
                                value={this.state.input?.transmission_type_ar}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("body_type")}
                              </label>
                              <input
                                type="text"
                                name="body_type"
                                className="form-control"
                                placeholder={i18n.t("body_type")}
                                value={this.state.input?.body_type}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("body_type")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="body_type_ar"
                                className="form-control"
                                placeholder={i18n.t("body_type")}
                                value={this.state.input?.body_type_ar}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("fuel_type")}
                              </label>
                              <input
                                type="text"
                                name="fuel_type"
                                className="form-control"
                                placeholder={i18n.t("fuel_type")}
                                value={this.state.input?.fuel_type}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("fuel_type")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="fuel_type_ar"
                                className="form-control"
                                placeholder={i18n.t("fuel_type")}
                                value={this.state.input?.fuel_type_ar}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("information_number")}
                              </label>
                              <input
                                type="text"
                                name="information_number"
                                className="form-control"
                                placeholder={i18n.t("information_number")}
                                value={this.state.input?.information_number}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("warranty")}
                              </label>
                              <input
                                type="text"
                                name="warranty"
                                className="form-control"
                                placeholder={i18n.t("warranty")}
                                value={this.state.input?.warranty}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("color")}
                              </label>
                              <input
                                type="text"
                                name="color"
                                className="form-control"
                                placeholder={i18n.t("color")}
                                value={this.state.input?.color}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("color")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="color_ar"
                                className="form-control"
                                placeholder={i18n.t("color")}
                                value={this.state.input?.color_ar}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("engine_size")}
                              </label>
                              <input
                                type="text"
                                name="engine_size"
                                className="form-control"
                                placeholder={i18n.t("engine_size")}
                                value={this.state.input?.engine_size}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("no_of_keys")}
                              </label>
                              <input
                                type="text"
                                name="no_of_keys"
                                className="form-control"
                                placeholder={i18n.t("no_of_keys")}
                                value={this.state.input?.no_of_keys}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("document_type")}
                              </label>
                              <input
                                type="text"
                                name="document_type"
                                className="form-control"
                                placeholder={i18n.t("document_type")}
                                value={this.state.input?.document_type}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("extras")}
                              </label>
                              <input
                                type="text"
                                name="extras"
                                className="form-control"
                                placeholder={i18n.t("extras")}
                                value={this.state.input?.extras}
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-group d-flex align-items-center justify-content-center">
                              <button
                                className="btn btn-primary px-4"
                                disabled={this.state.is_saving}
                                onClick={(e) => {
                                  this.submitForm(e);
                                }}
                                type="button"
                              >
                                {this.state.is_saving && (
                                  <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}
                                {i18n.t("submit")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="container mt-2 bg-white border p-2 rounded-4 m-1">
                    <div className="row">
                      <div className="container">
                        <div className="alert alert-danger">
                          <p>This is not a vehicle auction</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
