import React from "react";
import i18n from "../../i18n";

export class PasswordRules extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    let { rules } = this.props;
    return (
      <React.Fragment>
        <div>
          <div>
            <i
              className={`mdi ${
                rules?.char8
                  ? "mdi-check-circle-outline text-success"
                  : "mdi-close-box text-danger"
              }`}
            />{" "}
            &nbsp; {i18n.t("password_length")}
          </div>
          <div>
            <i
              className={`mdi ${
                rules?.charSpecial
                  ? "mdi-check-circle-outline text-success"
                  : "mdi-close-box text-danger"
              }`}
            />{" "}
            &nbsp; {i18n.t("password_special")}
          </div>
          <div>
            <i
              className={`mdi ${
                rules?.number
                  ? "mdi-check-circle-outline text-success"
                  : "mdi-close-box text-danger"
              }`}
            />{" "}
            &nbsp; {i18n.t("password_number")}
          </div>
          <div>
            <i
              className={`mdi ${
                rules?.capital
                  ? "mdi-check-circle-outline text-success"
                  : "mdi-close-box text-danger"
              }`}
            />{" "}
            &nbsp; {i18n.t("password_capital")}
          </div>
          <div>
            <i
              className={`mdi ${
                rules?.match
                  ? "mdi-check-circle-outline text-success"
                  : "mdi-close-box text-danger"
              }`}
            />{" "}
            &nbsp; {i18n.t("password_match")}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
