import React from "react";
import { getAuctions } from "../../../../../models/auctions";
import { getCompletedGroups } from "../../../../../models/groups";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import i18n from "../../../../../i18n";
import { MzadLoader } from "../../../../helpers/mzadLoader";
import AuctionsListingPop from "../../auction_listing";

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export class DashboardCompletedAuctions extends React.Component {
  constructor() {
    super();
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    this.state = {
      show_aucs_popup: false,
      show_wallet_popup: false,
      op_type: "approve",
      group_total: 0,
      is_loading: false,
      is_group_loading: false,
      auctions: [],
      open_popup: false,
      categories: [],
      clients: [],
      groups: [],
      selected_group: {},
      totalRows: 0,
      totalGroupRows: 0,
      currentPage: 1,
      selected_location: false,
      approve: {
        approved_date: formatDate(today),
        invoice: "MZD",
      },
      reauction: {},
      counts: {
        pending: 0,
        approved: 0,
        rejected: 0,
        total: 0,
      },
      input: {
        section: "main",
        page: 1,
        limit: 15,
        client: 1,
        fdate: formatDate(startDate),
        tdate: formatDate(endDate),
      },
      rem_amount: "",
      service_inv_link: "",
      remarks_popup: false,
      remarks: {
        amount: "",
        remark: "",
      },
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#dadada",
            color: "#000000",
          },
        },
      },
      selected_id: null,
      selectedRows: [],
      columns: [
        {
          id: 1,
          name: i18n.t("sl_no"),
          sortable: true,
          wrap: true,
          width: "70px",
          cell: (row, i) => i + 1,
        },
        {
          id: 2,
          name: "ID",
          sortable: true,
          wrap: true,
          width: "70px",
          cell: (row) => row.id,
        },
        {
          id: 3,
          name: i18n.t("department"),
          sortable: true,
          wrap: true,
          cell: (row) => row?.department?.name_ar || row?.department?.name,
        },
        {
          id: 4,
          name: i18n.t("location"),
          sortable: true,
          wrap: true,
          cell: (row) => row?.location?.name_ar || row?.location?.name,
        },
        {
          id: 5,
          name: i18n.t("group_name"),
          cell: (row) => row.group_name,
        },
        {
          id: 6,
          name: i18n.t("no_of_auctions"),
          cell: (row) => {
            return (
              <a
                href={false}
                onClick={() => {
                  this.auctionsPopup(row);
                }}
                style={{ width: "100%", cursor: "pointer" }}
                className="rounded text-warning text-center border border-warning"
              >
                {row.total_auctons}
              </a>
            );
          },
          wrap: true,
        },
        {
          id: 7,
          name: i18n.t("target_amount"),
          cell: (row) => row?.target_amount,
          wrap: true,
        },
        {
          id: 8,
          name: i18n.t("total_amount"),
          cell: (row) => row?.total_amount,
          wrap: true,
        },
        {
          id: 9,
          name: i18n.t("start_date"),
          cell: (row) => row.start_date,
          wrap: true,
        },
        {
          id: 10,
          name: i18n.t("end_date"),
          cell: (row) => row.end_date,
          wrap: true,
        },
      ],
      bidder_loader: false,
      file_approved_doc: null,
      file_reauction_doc: null,
      winners: [],
      winners_loading: false,
      winners_meta: {},
      fileInputs: [{ id: 1, file: null }],
      is_individual: true,
      auc_page: 1,
      auc_limit: 5,
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadAllAuctions = this.loadAllAuctions.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.setState({ selected_location: this.props.location });
    this.loadAllGroups();
  }

  handlePageChange = (page) => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let { input } = this.state;
    input["page"] = page;
    this.setState({ currentPage: page });
    this.setState({ input: input });
    this.setState({ query: params });
    let qstring = new URLSearchParams(input).toString();
    this.loadAllGroups(qstring);
  };

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
  }

  async loadAllGroups() {
    this.setState({ is_group_loading: true });
    let { input } = this.state;
    let q = `page=${input.page}&limit=${input.limit}&group_type=auc_apprv_not_approved&`;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    if (input.fdate) {
      q += `&fdate=${input.fdate}`;
    }
    if (input.tdate) {
      q += `&tdate=${input.tdate}`;
    }
    if (input.search_type) {
      q += `&search_type=${input.search_type}`;
    }
    if (this.props.location) {
      q += `&location=${this.props.location}`;
    }
    const response = await getCompletedGroups(q);
    let groups = response.data;
    let meta = response.meta;
    this.setState({ totalGroupRows: meta.total });
    this.setState({ groups });
    if (response.success) {
      let meta = response.meta;
      this.setState({ totalRows: meta.total, counts: meta.counts });
      this.setState({ is_group_loading: false });
      if (!input.client) {
        const orgs = groups
          .map((item) => item?.organization_info)
          .filter(
            (org, index, self) =>
              index === self.findIndex((t) => t?.id === org?.id)
          );
        this.setState({ clients: orgs });
      }
    }
  }

  async loadAllAuctions(params, isFundPopup = false) {
    this.setState({ is_loading: true, auctions: [] });
    const response = await getAuctions("?" + params);
    if (response) {
      let auctions = response.data;
      let meta = response.meta;
      this.setState({
        auctions: auctions,
        is_loading: false,
        group_info: meta?.group || {},
      });
      this.setState({ group_total: meta.totalAmount });
    }

    if (isFundPopup) {
      this.setState({
        show_wallet_popup: !this.state.show_wallet_popup,
      });
    }
  }

  auctionsPopup(group) {
    if (group && group?.id) {
      this.setState({ selected_group: group, show_aucs_popup: true });
      return true;
    }
    return;
  }
  render() {
    let { groups, auctions, selected_group, show_aucs_popup } = this.state;

    return (
      <React.Fragment>
        <div className="card mb-0 ">
          <div className="card-header">
            <div className="d-flex justify-content-between">
              <h3
                className="card-title d-flex justify-content-between align-items-center"
                style={{ textWrap: "nowrap" }}
              >
                {i18n.t("completed")} {i18n.t("auctions")}
              </h3>
            </div>
          </div>

          <div className="card-body">
            <div className="search-bar d-flex">
              <div className="col-md-2">
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control textbox rounded-2"
                    placeholder={i18n.t("from_date")}
                    name="fdate"
                    value={this.state.input.fdate}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-md-2 mx-1">
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control textbox rounded-2"
                    placeholder={i18n.t("to_date")}
                    name="tdate"
                    value={this.state.input.tdate}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-sm-2 col-md-2">
                <button
                  className="btn btn-primary rounded-5"
                  type="button"
                  onClick={() => {
                    let { input } = this.state;
                    let qstring = new URLSearchParams(input).toString();
                    this.loadAllGroups(qstring);
                  }}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
            {this.state.is_loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(255, 255, 255, 0.7)",
                  zIndex: "99",
                }}
              >
                <MzadLoader
                  animation="grow"
                  size="md"
                  loading={this.state.is_loading}
                  style={{ position: "relative" }}
                />
              </div>
            )}
            <div className="table-responsive ">
              <>
                <div className="table-wrapper">
                  <MzadLoader
                    animation="grow"
                    size="md"
                    loading={this.state.is_group_loading}
                    style={{ position: "relative" }}
                  />
                  <DataTable
                    responsive={true}
                    title=""
                    fixedHeader
                    columns={this.state.columns}
                    data={groups}
                    customStyles={this.state.customStyles}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={this.state.totalGroupRows}
                    paginationPerPage={this.state.input.limit}
                    paginationDefaultPage={this.state.currentPage}
                    paginationRowsPerPageOptions={[15, 25, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Groups per page:",
                      rangeSeparatorText: i18n.t("out"),
                    }}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={(size) => {
                      this.setState({ rows_per_page: 15 });
                      let { input } = this.state;
                      input.limit = size;
                      this.setState({ input });
                      this.handlePageChange(1);
                    }}
                  />
                </div>
              </>
            </div>
          </div>
          {!this.state.is_loading && auctions.length === 0 && (
            <div className="m-3 text-center p-3 text-red">
              {i18n.t("no_auctions_found")}
            </div>
          )}
        </div>

        <Modal
          show={show_aucs_popup}
          onHide={() => this.setState({ show_aucs_popup: false })}
          size="lg"
          dialogClassName="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("auctions")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AuctionsListingPop group={selected_group} />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
