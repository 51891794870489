import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "../sidebar";
import { getUsersByRoles } from "../../../models/users";
import DataTable from "react-data-table-component";
import i18n from "../../../i18n";
import swal from "sweetalert";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  createWallet,
  getAllWallets,
  getWallet,
  updateWallet,
  updateWalletFile,
} from "../../../models/wallet";
import { getLoggedUser } from "../../../state/user";
import { HomeBanner } from "../../home/homeBanner";
import FileInput from "../../helpers/fileInput";

const user = getLoggedUser();
let lang = localStorage.getItem("I18N_LANGUAGE");
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#dadada",
      color: "#000000",
    },
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9 }),
};

const wallet_types = {
  admin: "Added by admin",
  sitevisit: "Site Visit",
  enroll: "Enroll",
  wallet_recharge: "Recharge",
  withdraw: i18n.t("withdraw"),
};
const wallet_statuses = {
  A: i18n.t("approved"),
  P: "Waiting for admin approval",
  F: i18n.t("waiting_for_finance_approval"),
  W: "Withdraw requested",
  WA: i18n.t("withdraw_request_approved_by_admin"),
  R: "Refunded",
};

export class WithdrawaReport extends React.Component {
  constructor() {
    super();
    this.state = {
      open_popup: false,
      update_popup: false,
      add: {},
      data: {},
      errors: {},
      is_loading: false,
      transactions: [],
      customers: [],
      hideSidebar: false,
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 25,
      },
      file_receipt: null,
      credit_sum: 0,
      debit_sum: 0,
      balance_sum: 0,
      hold_sum: 0,
      columns: [
        {
          name: "ID",
          selector: "id",
          sortable: true,
          wrap: true,
          width: "70px",
        },
        {
          name: i18n.t("user"),
          selector: "user",
          sortable: true,
          wrap: true,
          cell: (row) =>
            `${
              row.user && row.user_info
                ? row.user_info.name + "(" + row.user + ")"
                : row.user
            }`,
        },
        {
          name: "",
          selector: "user",
          cell: (row) => {
            return (
              <button
                className="btn rounded-circle btn-outline-info btn-sm p-1"
                onClick={() => {
                  this.setState({
                    show_info_popup: true,
                    selected_user: row.user_info,
                  });
                }}
              >
                <i className="fa fa-info" />
              </button>
            );
          },
        },
        {
          name: i18n.t("transaction"),
          selector: "transaction_id",
          sortable: true,
        },
        {
          name: i18n.t("credit"),
          selector: "credit",
        },
        {
          name: i18n.t("debit"),
          selector: "debit",
        },
        {
          name: i18n.t("balance"),
          selector: "balance",
        },
        {
          name: i18n.t("reference"),
          selector: "reference",
          wrap: true,
        },
        {
          name: i18n.t("type"),
          selector: "type",
          wrap: true,
          cell: (row) => `${row.type ? wallet_types[row.type] : ""}`,
        },
        {
          name: i18n.t("status"),
          selector: "status",
          wrap: true,
          cell: (row) => `${row.status ? wallet_statuses[row.status] : ""}`,
        },
        {
          name: i18n.t("date"),
          wrap: true,
          selector: "created_at",
        },
        {
          name: i18n.t("action"),
          wrap: true,
          cell: (row) => {
            if (user.role !== "A" && row.status === "W") {
              return (
                <small className="text-danger">
                  {"Waiting for admin approval"}
                </small>
              );
            }
            if (user.role === "A" && row.status === "W") {
              return (
                <button
                  className="btn btn-outline-info"
                  onClick={() => {
                    this.updateWalletRow("WA", row);
                  }}
                >
                  {i18n.t("approve")}
                </button>
              );
            }

            if (user.role === "F" && row.status === "WA") {
              return (
                <button
                  className="btn btn-outline-info"
                  onClick={() => {
                    this.setState({
                      show_finance_popup: true,
                      selected_row: row,
                    });
                  }}
                >
                  {i18n.t("approve")}
                </button>
              );
            }

            if (row.status === "R") {
              return (
                <small className="text-success text-center">{"Refunded"}</small>
              );
            }
          },
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddChange = this.handleAddChange.bind(this);
    this.triggerReload = this.triggerReload.bind(this);
    this.updateWalletRow = this.updateWalletRow.bind(this);
    this.updateWalletRowWithFile = this.updateWalletRowWithFile.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
    this.loadCustomers();
  }

  onFileChange = (event) => {
    if (event.target.name === "file_receipt") {
      this.setState({ file_receipt: event.target.files[0] });
    }
  };

  async loadCustomers() {
    this.setState({ is_loading: true });
    let q = `roles=U&is_role_wise=${true}`;
    const response = await getUsersByRoles(q);
    let data = response.data["U"];
    if (response.success) {
      const customers = data.map((item) => {
        return {
          value: item.id,
          label: item.name + " (" + item.username + ")",
        };
      });

      this.setState({ is_loading: false });
      this.setState({ customers: customers });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }
  handleAddChange(event) {
    let { add } = this.state;
    add[event.target.name] = event.target.value;
    this.setState({ add });
  }

  handleSearch(event) {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
  }

  async loadAllTransactions(q) {
    this.setState({ is_loading: true });
    q = q + "&type=withdraw";
    const response = await getAllWallets(q);
    let transactions = response.data;
    let meta = response.meta;
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ transactions: transactions });
      this.setState({ totalRows: meta.total });
      this.setState({
        credit_sum: meta["credit_sum"],
        debit_sum: meta["debit_sum"],
        hold_sum: meta["hold_sum"],
        balance_sum: meta["balance_sum"],
      });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async viewRow(id) {
    const response = await getWallet(id);
    let data = response.data;
    this.setState({ data, update_popup: true });
  }

  async updateWalletRow(status, row) {
    let payload = { status: status, id: row.id, user: row.user };
    const response = await updateWallet(payload, row.id);
    if (response && response.success) {
      swal(i18n.t("success"), i18n.t("approved"), "success");
      this.handleSearch();
    }
  }

  async updateWalletRowWithFile(event) {
    event.preventDefault();
    if (!this.state.file_receipt) {
      swal(i18n.t("error"), i18n.t("upload_receipt"), "error");
    }
    let { selected_row } = this.state;
    const formData = new FormData();
    formData.append("id", selected_row.id);
    formData.append("user", selected_row.user);
    formData.append("status", "R");
    if (this.state.file_receipt) {
      formData.append(
        "file_receipt",
        this.state.file_receipt,
        this.state.file_receipt.name
      );
    }

    const response = await updateWalletFile(formData, selected_row.id);
    if (response && response.success) {
      this.setState({ show_finance_popup: false });
      swal(i18n.t("success"), i18n.t("approved"), "success");
      this.handleSearch();
    }
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllTransactions(qstring);
  };

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      try {
        const response = await createWallet(this.state.add);
        if (response.success) {
          swal(i18n.t("success"), "Fund added successfully!!", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((value) => {
              this.resetForm();
              let { input } = this.state;
              let q = new URLSearchParams(input).toString();
              this.loadAllTransactions(q);
              this.setState({ open_popup: false });
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  resetForm() {
    let { add } = this.state;
    add["name"] = "";
    add["mobile"] = "";
    add["email"] = "";
    add["username"] = "";
    add["country_code"] = "+968";
    add["password"] = "";
    add["role"] = "";
    this.setState({ add: add });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.add;
    let errors = {};
    let isValid = true;
    if (!input["user"]) {
      isValid = false;
      errors["user"] = "Please select a user.";
    }
    if (!input["credit"]) {
      isValid = false;
      errors["credit"] = "Please enter an amount.";
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  triggerReload(opt) {
    if (opt) {
      let { input } = this.state;
      let qstring = new URLSearchParams(input).toString();
      this.loadAllTransactions(qstring);
    }
  }

  render() {
    let { transactions, customers } = this.state;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between">
                    <div className=" d-flex">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      <h3 className="card-title">
                        {i18n.t("wallet_transactions")} - {i18n.t("reports")}
                      </h3>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {this.state.is_loading && (
                        <div className="text-center">
                          <Spinner animation="border" size="lg" />
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <input
                              type="date"
                              className="form-control"
                              placeholder={i18n.t("from_date")}
                              name="fdate"
                              value={this.state.input.fdate}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <input
                              type="date"
                              className="form-control"
                              placeholder={i18n.t("to_date")}
                              name="tdate"
                              value={this.state.input.tdate}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <select
                              className="form-control"
                              data-placeholder="Select Type"
                              name="rtype"
                              value={this.state.input.rtype}
                              onChange={this.handleChange}
                            >
                              <option value="">--Type--</option>
                              <option value="U">
                                {i18n.t("user_requested")}
                              </option>
                              <option value="R">{i18n.t("refund")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <Select
                              options={customers}
                              onChange={(e) => {
                                let { input } = this.state;
                                input.customer = e.value;
                                this.setState({ input });
                              }}
                              name="customer"
                              styles={selectStyles}
                              menuPlacement="auto"
                              menuPortalTarget={document.body}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <button
                              className="btn btn-primary btn-block"
                              onClick={this.handleSearch}
                            >
                              {i18n.t("search")}
                            </button>
                          </div>
                        </div>
                        {(this.state.input.customer ||
                          this.state.input.rtype ||
                          this.state.input.fdate ||
                          this.state.input.tdate) && (
                          <div className="col-md-1">
                            <div className="form-group">
                              <button
                                className="btn btn-outline-danger btn-block"
                                onClick={(e) => {
                                  let { input } = this.state;
                                  input.customer = "";
                                  input.fdate = "";
                                  input.tdate = "";
                                  input.rtype = "";
                                  this.setState({ input });
                                  this.handleSearch(e);
                                }}
                              >
                                <i className="fa fa-times" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>

                      {!this.state.is_loading && transactions.length > 0 && (
                        <div className="row">
                          <div className="table-wrapper">
                            <DataTable
                              title=""
                              fixedHeader
                              columns={this.state.columns}
                              data={transactions}
                              customStyles={customStyles}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={this.state.totalRows}
                              paginationPerPage={this.state.input.limit}
                              paginationDefaultPage={this.state.currentPage}
                              paginationRowsPerPageOptions={[15, 25, 50, 100]}
                              paginationComponentOptions={{
                                rowsPerPageText: i18n.t(
                                  "transactions_per_page"
                                ),
                                rangeSeparatorText: i18n.t("out"),
                              }}
                              onChangePage={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.show_info_popup}
          onHide={() => {
            let { selected_user } = this.state;
            selected_user = "";
            this.setState({ show_info_popup: false, selected_user });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("bank_details")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2">
              <p>
                {i18n.t("bank")} :{" "}
                <b>
                  {this.state.selected_user && this.state.selected_user.bank}
                </b>
              </p>
              <p>
                {i18n.t("account")} :{" "}
                <b>
                  {this.state.selected_user &&
                    this.state.selected_user.account_number}
                </b>
              </p>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.show_finance_popup}
          onHide={() => {
            let { selected_row } = this.state;
            selected_row = "";
            this.setState({ show_finance_popup: false, selected_row });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("approve")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <form
                  id="Register"
                  className="card-body"
                  tabIndex="500"
                  autoComplete="off"
                  onSubmit={this.updateWalletRowWithFile}
                >
                  <div className="form-group">
                    <div className="form-group">
                      <label
                        for="form-label"
                        className="form-label"
                        style={{ top: "inherit" }}
                      >
                        {i18n.t("receipt")}{" "}
                        <span className="text text-danger">(*)</span>
                      </label>
                      <FileInput
                        wrapperClass="pad-adjust "
                        placeholder={i18n.t("choose_file")}
                        name="file_receipt"
                        id="file_receipt"
                        onChange={this.onFileChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-primary btn-block">
                      {i18n.t("submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
