import React from "react";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import globalize from "globalize";
import { HomeBanner } from "./homeBanner";
import i18n from "../../i18n";
import { getAllCalendarData } from "../../models/calendar";
import { getLoggedUser } from "../../state/user";

const user = getLoggedUser();
const localizer = globalizeLocalizer(globalize);
export class AuctionCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsList: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (user && user.id) {
      this.getAllData();
    }
  }

  handleSelectEvent(e) {
    window.location.href = "/auction-details/" + e.data.id;
  }

  async getAllData() {
    const response = await getAllCalendarData();
    if (response.success) {
      this.setState({ eventsList: response.data });
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        <div className="page-container m-1">
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10 p-3">
              <div className="blue-container">
                <div className="mx-3 mb-3 mobile_size">
                  <i className="fa fa-calendar text-white"></i>
                  <span className="custom-text5 ms-1 text-white">
                    {i18n.t("auction_calendar")}
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="bg-white user-brd rounded-4 px-0">
                      <Calendar
                        localizer={localizer}
                        events={this.state.eventsList}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        onSelectEvent={this.handleSelectEvent}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
