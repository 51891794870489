import React from "react";
import i18n from "../../i18n";
import { apiUrl } from "../../constants/global";
import { getLoggedUser } from "../../state/user";
const user = getLoggedUser();

export class BidderRow extends React.Component {
  constructor() {
    super();
    this.state = {
      is_stacked: false,
    };
    //this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  render() {
    let { bidder } = this.props;
    return (
      <React.Fragment>
        <div
          className="d-flex justify-content-end m-3  "
          style={{
            fontSize: "17px",
            fontWeight: "500",
            color: "#46BCFF",
            display: "none",
          }}
        >
          <span
            className="selectall"
            onClick={() => {
              this.setState({ is_stacked: !this.state.is_stacked });
            }}
          >
            {i18n.t("all")}
          </span>
        </div>

        <div
          class={
            this.state.is_stacked
              ? "scrollable-container mt-2 stacked"
              : "scrollable-container mt-2"
          }
        >
          <div className="scrollable-item me-3 mb-3">
            <div
              className="bg-white p-3 col-md-3 border"
              style={{ width: "auto", borderRadius: "18px" }}
            >
              <div className="details">
                <div className="img d-flex justify-content-start align-items-center mb-3">
                  <img
                    src="assets/images/profile-picture.png"
                    height="37"
                    width="37"
                    style={{ borderRadius: "50%", marginRight: "10px" }}
                    alt=""
                  />
                  <div
                    className="name-info mt-2"
                    style={{ fontSize: "11px", fontWeight: "500" }}
                  >
                    <div>
                      <span>ID. {bidder.id}</span>
                    </div>
                    <div className="wrap-pretty">
                      <span>
                        {i18n.t("auction_name")}: {bidder.title}
                      </span>
                    </div>
                    <div>
                      <span>
                        {i18n.t("client")}:{" "}
                        {bidder.organizationInfo
                          ? bidder.organizationInfo.organization_name
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: "0%", padding: "0%" }} />
                <div
                  className=" mt-1 mb-1 d-flex justify-content-start"
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    color: "#FA8500",
                  }}
                >
                  <span>
                    {i18n.t("winning_amount")} : {i18n.t("omr")}&nbsp;
                    {bidder.bid_amount}
                  </span>
                </div>

                <div className="d-flex justify-content-center">
                  <div
                    className="pays d-flex justify-content-between mb-2"
                    style={{ fontSize: "11px", fontWeight: "600" }}
                  >
                    <div className="img d-flex justify-content-start align-items-center">
                      <span className="m-2">{i18n.t("pay")}</span>
                      <div className="name-info">
                        <div className="d-flex align-items-center">
                          <button
                            className="btn text-center"
                            style={{
                              height: "30px",
                              fontSize: "11px",
                              width: "85px",
                              backgroundColor: "#00B76E",
                              color: "#F1F1F1",
                            }}
                            onClick={() => {
                              window.location.href = `/client-payment?auction=${bidder.id}&group=${bidder.group}`;
                            }}
                          >
                            {i18n.t("pay_now")}
                            <img
                              src="assets/images/pay-finger.png"
                              height="12"
                              width="10"
                              className=""
                              alt=""
                              style={{ marginLeft: "5px", marginBottom: "4px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="img d-flex justify-content-start align-items-center">
                      <span
                        className="m-2"
                        style={{ width: "auto", display: "inline" }}
                      >
                        {i18n.t("due_days")}
                      </span>
                      <div className="name-info">
                        <div className="d-flex align-items-center">
                          <button
                            className="btn text-center"
                            style={{
                              height: "30px",
                              fontSize: "11px",
                              width: "auto",
                              backgroundColor: "#00B76E",
                              color: "#F1F1F1",
                            }}
                          >
                            0
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="img d-flex justify-content-start align-items-center">
                      <span className="m-2">{i18n.t("tracking")}</span>
                      <div className="name-info">
                        <div className="d-flex align-items-center">
                          <button
                            className="btn text-center d-flex justify-content-center"
                            style={{
                              backgroundColor: "#00B76E",
                              color: "#F1F1F1",
                            }}
                            onClick={() => {
                              window.location.href = `/auction_tracker?auction=${bidder.id}&group=${bidder.group}&client=${bidder.organization}`;
                            }}
                          >
                            <img
                              src="assets/images/graph-new-white.png"
                              height="12"
                              width="12"
                              className="mx-auto"
                              alt=""
                              style={{ marginLeft: "5px", marginBottom: "4px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="m-0 p-0" />
              </div>
              <div
                className="files d-flex justify-content-start mt-2"
                style={{ fontSize: "11px", fontWeight: "500" }}
              >
                <div className="pdf-file-div">
                  <div className="img d-flex justify-content-start align-items-center">
                    <span className="m-2">{i18n.t("invoice")}</span>
                    <div className="name-info">
                      <div className="d-flex align-items-center">
                        <a
                          href={`${apiUrl}my_winnings/pdf?${new URLSearchParams(
                            {
                              id: user.id,
                              auction: bidder.id,
                            }
                          ).toString()}&auth=${user.token}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="assets/images/pdf-icon-big.png"
                            height="15"
                            width="12"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="excel-file-div">
                  <div className="img d-flex justify-content-start align-items-center">
                    <span className="m-2">{i18n.t("receipt")}</span>
                    <div className="name-info">
                      <div className="d-flex align-items-center">
                        <img
                          src="assets/images/pdf-icon-big.png"
                          height="15"
                          width="12"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
