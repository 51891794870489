import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";
const user = getLoggedUser();

async function createLoc(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "locations",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllLocs(q) {
  return fetch(apiUrl + "locations?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getLoc(id) {
  return fetch(apiUrl + "locations/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateLoc(id, payload) {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "locations/" + id,
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteLoc(id) {
  return fetch(apiUrl + "locations/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getLocations(q) {
  return fetch(`${apiUrl}get_locations?${q}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

export { createLoc, getAllLocs, getLoc, updateLoc, deleteLoc, getLocations };
