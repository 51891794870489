import React from "react";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import {
  getAllRequests,
  createRequest,
  deleteRequest,
  updateRequest,
} from "../../models/auction_request";
import i18n from "../../i18n";
import { HomeBanner } from "../home/homeBanner";
import { getDepartments } from "../../models/departments";
import { getLocations } from "../../models/locations";
import FileInputNew from "../helpers/fileInputNew";
import { getAllCategories } from "../../models/categories";
import DataTable from "react-data-table-component";
import { getLoggedUser } from "../../state/user";
import { Modal } from "react-bootstrap";
import { getAllGroups } from "../../models/groups";

const lng = localStorage.getItem("I18N_LANGUAGE");
const user = getLoggedUser();
const statusClasses = {
  N: "text-info",
  V: "text-warning",
  A: "text-success",
};
export class AuctionRequest extends React.Component {
  constructor() {
    super();
    this.state = {
      list: {
        limit: 20,
        page: 1,
      },
      groups: [],
      input: {},
      update: {},
      change: {},
      is_status: false,
      errors: {},
      requests: [],
      categories: [],
      departments: [],
      locations: [],
      file_attatchment: null,
      file_attatchment_update: null,
      show_add: false,
      is_edit: false,
      columns: [
        {
          id: 1,
          name: "ID",
          sortable: true,
          wrap: true,
          width: "70px",
          cell: (row) => row.id,
        },
        {
          id: 2,
          name: i18n.t("item_details"),
          cell: (row) => row.details,
          sortable: true,
        },
        {
          id: 3,
          name: i18n.t("region"),
          cell: (row) =>
            lng === "ar"
              ? row?.region_info?.region_ar
              : row?.region_info?.region,
          sortable: true,
        },
        {
          id: 4,
          name: i18n.t("department"),
          cell: (row) =>
            lng === "ar"
              ? row?.department_info?.name_ar
              : row?.department_info?.name,
        },
        {
          id: 5,
          name: i18n.t("location"),
          cell: (row) =>
            lng === "ar"
              ? row?.location_info?.name_ar
              : row?.location_info?.name,
          wrap: true,
        },
        {
          id: 6,
          name: i18n.t("category"),
          cell: (row) =>
            lng === "ar"
              ? row?.category_info?.category_name_ar
              : row?.category_info?.category_name,
          wrap: true,
        },
        {
          id: 6,
          name: i18n.t("quantity"),
          cell: (row) => row.quantity,
          wrap: true,
        },
        {
          id: 7,
          name: i18n.t("location_link"),
          cell: (row) => (
            <a
              className="text-info font-weight-normal"
              href={row.location_link}
              target="_new"
            >
              {row.location_link}
            </a>
          ),
          wrap: true,
        },
        {
          id: 8,
          name: i18n.t("attatchment"),
          cell: (row) => {
            return row.file_attatchment ? (
              <a
                className="btn border-none bg-none text-danger"
                href={row.file_attatchment}
                target="_new"
              >
                <i className="fa fa-external-link" />
                &nbsp;
                {i18n.t("view")}
              </a>
            ) : (
              ""
            );
          },
          wrap: true,
        },
        {
          id: 9,
          name: i18n.t("remarks"),
          cell: (row) => row?.remarks,
          wrap: true,
        },
        {
          id: 10,
          name: i18n.t("status"),
          cell: (row) => (
            <span className={`${statusClasses[row?.status]} font-weight-bold`}>
              {row?.status_label}
            </span>
          ),
          wrap: true,
        },
        {
          id: 11,
          name: i18n.t("action"),
          wrap: false,
          width: "250px",
          cell: (row) => {
            return (
              <div className="d-flex gap-1 mx-1 mt-1">
                {/* {row.status === "V" && user.role === "C" && (
                  <button
                    className="btn btn-outline-info btn-sm"
                    style={{
                      textWrap: "nowrap",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.changeStatus(row);
                    }}
                  >
                    {i18n.t("approve")}
                  </button>
                )} */}

                {row.status === "N" &&
                  (user.role === "A" ||
                    user.role === "PM" ||
                    user.role === "M" ||
                    user.role === "OM") && (
                    <button
                      className="btn btn-outline-info btn-sm"
                      style={{
                        textWrap: "nowrap",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.changeStatus(row);
                      }}
                    >
                      {i18n.t("verify")}
                    </button>
                  )}

                {(row?.added_by === user.id || user.role === "PM") && (
                  <>
                    <button
                      className="btn btn-outline-warning btn-sm"
                      onClick={() => {
                        this.openEdit(row);
                      }}
                    >
                      {i18n.t("edit")}
                    </button>
                    {row.status === "N" && (
                      <button
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => {
                          this.deleteRow(row.id);
                        }}
                      >
                        {i18n.t("delete")}
                      </button>
                    )}
                  </>
                )}
              </div>
            );
          },
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);

    this.handleUpdateChange = this.handleUpdateChange.bind(this);
    this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);
    this.onFileUpdateChange = this.onFileUpdateChange.bind(this);
    this.openEdit = this.openEdit.bind(this);

    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleStatusUpdate = this.handleStatusUpdate.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllCategories();
    this.loadAllRequests();
    this.loadDeps();
    this.loadAllGroups();
  }

  async loadAllGroups() {
    const response = await getAllGroups("");
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAllRequests() {
    let { list } = this.state;
    let queryString = new URLSearchParams(list).toString();
    const response = await getAllRequests(queryString);
    let requests = response.data;
    this.setState({ requests });
  }

  async loadAllCategories() {
    const response = await getAllCategories("");
    let categories = response.data;
    console.log(categories);

    this.setState({ categories });
  }

  async loadDeps() {
    const response = await getDepartments();
    let departments = response.data;
    this.setState({ departments });
  }

  async loadLocs(dep = "") {
    let q = "";
    if (dep) {
      q = `department=${dep}`;
    }
    const response = await getLocations(q);
    let locations = response.data;
    this.setState({ locations });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "department") {
      this.loadLocs(event.target.value);
    }
  }

  handleUpdateChange(event) {
    let { update } = this.state;
    update[event.target.name] = event.target.value;
    this.setState({ update });
    if (event.target.name === "department") {
      this.loadLocs(event.target.value);
    }
  }

  handleStatusChange(event) {
    let { change } = this.state;
    change[event.target.name] = event.target.value;
    this.setState({ change });
  }

  onFileChange = (event) => {
    if (!this.validateFileSize(event.target.files[0])) {
      swal("Failed", "File size should be less than 2MB", "error");
      return;
    }
    if (event.target.name === "file_attatchment") {
      this.setState({ file_attatchment: event.target.files[0] });
    }
  };

  onFileUpdateChange = (event) => {
    if (!this.validateFileSize(event.target.files[0])) {
      swal("Failed", "File size should be less than 2MB", "error");
      return;
    }
    if (event.target.name === "file_attatchment") {
      this.setState({ file_attatchment_update: event.target.files[0] });
    }
  };

  validateFileSize(file) {
    const MAX_FILE_SIZE = 2048; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    return true;
  }

  openEdit(row) {
    this.setState({ update: row, is_edit: true });
    this.loadLocs(row?.department);
  }

  changeStatus(row) {
    this.setState({ change: row, is_status: true });
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (
      !this.state.input.details ||
      !this.state.input.region ||
      !this.state.input.department ||
      !this.state.input.location ||
      !this.state.input.category ||
      !this.state.file_attatchment ||
      !this.state.input.quantity ||
      !this.state.input.location_link
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("details", this.state.input.details);
    formData.append("region", this.state.input.region);
    formData.append("department", this.state.input.department);
    formData.append("location", this.state.input.location);
    formData.append("category", this.state.input.category);
    formData.append("quantity", this.state.input.quantity);
    formData.append("location_link", this.state.input.location_link);
    formData.append("file_attatchment", this.state.file_attatchment);

    const response = await createRequest(formData);
    if (response.success) {
      swal(i18n.t("success"), i18n.t("success_message"), "success");
      this.loadAllRequests();
    } else {
      swal("Failed", response.message, "error");
    }
  }

  async handleUpdateSubmit(event) {
    event.preventDefault();

    if (
      !this.state.update.details ||
      !this.state.update.region ||
      !this.state.update.department ||
      !this.state.update.location ||
      !this.state.update.category ||
      !this.state.update.quantity ||
      !this.state.update.location_link
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("id", this.state.update?.id);
    formData.append("details", this.state.update.details);
    formData.append("region", this.state.update.region);
    formData.append("department", this.state.update.department);
    formData.append("location", this.state.update.location);
    formData.append("category", this.state.update.category);
    formData.append("quantity", this.state.update.quantity);
    formData.append("location_link", this.state.update.location_link);
    formData.append("file_attatchment", this.state.file_attatchment_update);
    formData.append("group", this.state.update.group);
    formData.append("_method", "patch");
    const response = await updateRequest(this.state.update.id, formData);
    if (response.success) {
      this.loadAllRequests();
      swal(i18n.t("success"), i18n.t("success_message"), "success");
      return;
    } else {
      swal("Failed", response.message, "error");
    }
  }

  async handleStatusUpdate(event, status) {
    event.preventDefault();

    if (!this.state.change.id) {
      swal("Failed", "ID Missing", "error");
      return;
    }

    const formData = new FormData();
    formData.append("id", this.state.change?.id);
    formData.append("remarks", this.state.change?.remarks);
    formData.append("status", status);
    formData.append("_method", "patch");
    const response = await updateRequest(this.state.change.id, formData);
    if (response.success) {
      this.loadAllRequests();
      swal(
        i18n.t("success"),
        user.role === "C"
          ? i18n.t("success_approval")
          : i18n.t("success_verification"),
        "success"
      );
      return;
    } else {
      swal("Failed", response.message, "error");
    }
  }

  async deleteRow(id) {
    swal(i18n.t("confirm_msg_continue"), {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then(async (value) => {
      if (value === "yes") {
        const response = await deleteRequest(id);
        if (response.success) {
          swal(i18n.t("success"), i18n.t("delete_message"), "success");
          this.loadAllRequests();
          return;
        }
      }
      return;
    });
  }

  render() {
    let { categories, departments, locations, requests, groups } = this.state;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={`col-xl-${
                  this.state.hideSidebar ? "12" : "9"
                } col-lg-12 col-md-12`}
              >
                <form
                  id="UpdateProfile"
                  className={`card mb-0`}
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        <i
                          className={`icon-size-${
                            this.state.hideSidebar ? "actual" : "fullscreen"
                          }`}
                        ></i>
                      </span>
                      <h3 className="card-title">
                        {i18n.t("auction_request")}
                      </h3>
                    </div>

                    {user.role === "RS" && (
                      <button
                        className="border border-primary bg-none"
                        type="button"
                        onClick={() => {
                          this.setState({ show_add: !this.state.show_add });
                        }}
                      >
                        <i
                          className={`fa fa-${
                            this.state.show_add ? "minus-circle" : "plus-circle"
                          }`}
                        />
                      </button>
                    )}
                  </div>
                  {user.role === "RS" && (
                    <div
                      className={`card-body  ${
                        !this.state.show_add ? "d-none" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("item_details")}
                            </label>
                            <input
                              type="text"
                              name="details"
                              className="form-control"
                              placeholder={i18n.t("item_details")}
                              value={this.state.input.details}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("region")}
                            </label>
                            <select
                              className="form-select bg-white textbox fs15 text-gray filter-icon rounded-2"
                              data-placeholder="Select Region"
                              name="region"
                              value={this.state.input.region}
                              onChange={this.handleChange}
                            >
                              <option value="">{i18n.t("region")}</option>
                              {departments.length > 0 &&
                                departments.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {lng === "ar"
                                        ? item.region_ar
                                        : item.region}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("department")}
                            </label>
                            <select
                              className="form-select bg-white textbox fs15 text-gray filter-icon rounded-2"
                              data-placeholder="Select Region"
                              name="department"
                              value={this.state.input.department}
                              onChange={this.handleChange}
                            >
                              <option value="">{i18n.t("department")}</option>
                              {departments.length > 0 &&
                                departments.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {lng === "ar" ? item.name_ar : item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("location")}
                            </label>
                            <select
                              className="form-select bg-white textbox fs15 text-gray filter-icon rounded-2"
                              data-placeholder="Select Region"
                              name="location"
                              value={this.state.input.location}
                              onChange={this.handleChange}
                            >
                              <option value="">{i18n.t("location")}</option>
                              {locations.length > 0 &&
                                locations.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {lng === "ar" ? item.name_ar : item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("category")}
                            </label>
                            <select
                              className="form-select bg-white textbox fs15 text-gray filter-icon rounded-2"
                              data-placeholder="Select category"
                              name="category"
                              value={this.state.input.category}
                              onChange={this.handleChange}
                            >
                              <option value="">{i18n.t("category")}</option>
                              {categories.length > 0 &&
                                categories.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {lng === "ar"
                                        ? item.category_name_ar
                                        : item.category_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("quantity")}
                            </label>
                            <input
                              type="text"
                              name="quantity"
                              className="form-control rtl-dir"
                              placeholder={i18n.t("quantity")}
                              value={this.state.input.quantity}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("location_link")}
                            </label>
                            <input
                              type="text"
                              name="location_link"
                              className="form-control rtl-dir"
                              placeholder={i18n.t("location_link")}
                              value={this.state.input.location_link}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("attatchment")}
                            </label>
                            <FileInputNew
                              placeholder={i18n.t("choose_file")}
                              className="form-control"
                              name="file_attatchment"
                              onChange={this.onFileChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3 mt-4">
                          <div className="form-group mt-2 align-items-center m-auto">
                            <button type="submit" className="btn btn-primary">
                              {i18n.t("send")} {i18n.t("auction_request")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>

                <div className="card">
                  {requests.length > 0 && (
                    <div className="table-wrapper">
                      <DataTable
                        responsive={true}
                        title=""
                        fixedHeader
                        columns={this.state.columns}
                        data={requests}
                        customStyles={this.state.customStyles}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={this.state.totalRows}
                        paginationPerPage={this.state.input.limit}
                        paginationDefaultPage={this.state.currentPage}
                        paginationRowsPerPageOptions={[15, 25, 50, 100]}
                        paginationComponentOptions={{
                          rowsPerPageText: "Requests per page:",
                          rangeSeparatorText: i18n.t("out"),
                        }}
                        onChangePage={this.handlePageChange}
                        onChangeRowsPerPage={(size) => {
                          this.setState({ rows_per_page: 15 });
                          let { input } = this.state;
                          input.limit = size;
                          this.setState({ input });
                          this.handlePageChange(1);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.is_edit}
          size="lg"
          onHide={() => {
            this.setState({ is_edit: false });
          }}
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {i18n.t("edit")} - {i18n.t("auction_request")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              id="UpdateAR"
              className={`card mb-0`}
              autoComplete="off"
              onSubmit={this.handleUpdateSubmit}
            >
              {(user.role === "RS" || user.role === "PM") && (
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("item_details")}
                        </label>
                        <input
                          type="text"
                          name="details"
                          className="form-control"
                          placeholder={i18n.t("item_details")}
                          value={this.state.update.details}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">{i18n.t("region")}</label>
                        <select
                          className="form-select bg-white textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Region"
                          name="region"
                          value={this.state.update.region}
                          onChange={this.handleUpdateChange}
                        >
                          <option value="">{i18n.t("region")}</option>
                          {departments.length > 0 &&
                            departments.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.region}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("department")}
                        </label>
                        <select
                          className="form-select bg-white textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Region"
                          name="department"
                          value={this.state.update.department}
                          onChange={this.handleUpdateChange}
                        >
                          <option value="">{i18n.t("department")}</option>
                          {departments.length > 0 &&
                            departments.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("location")}
                        </label>
                        <select
                          className="form-select bg-white textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Region"
                          name="location"
                          value={this.state.update.location}
                          onChange={this.handleUpdateChange}
                        >
                          <option value="">{i18n.t("location")}</option>
                          {locations.length > 0 &&
                            locations.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("category")}
                        </label>
                        <select
                          className="form-select bg-white textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select category"
                          name="category"
                          value={this.state.update.category}
                          onChange={this.handleUpdateChange}
                        >
                          <option value="">{i18n.t("category")}</option>
                          {categories.length > 0 &&
                            categories.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.category_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("quantity")}
                        </label>
                        <input
                          type="text"
                          name="quantity"
                          className="form-control rtl-dir"
                          placeholder={i18n.t("quantity")}
                          value={this.state.update.quantity}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("location_link")}
                        </label>
                        <input
                          type="text"
                          name="location_link"
                          className="form-control rtl-dir"
                          placeholder={i18n.t("location_link")}
                          value={this.state.update.location_link}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {i18n.t("attatchment")}
                        </label>
                        <FileInputNew
                          placeholder={i18n.t("choose_file")}
                          className="form-control"
                          name="file_attatchment"
                          onChange={this.onFileUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="form-label">{i18n.t("group")}</label>
                        <select
                          className="form-select bg-white textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Region"
                          name="group"
                          value={this.state.update.group}
                          onChange={this.handleUpdateChange}
                        >
                          <option value="">{i18n.t("group")}</option>
                          {groups.length > 0 &&
                            groups.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {lng === "ar"
                                    ? item.group_name_ar
                                    : item.group_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3 mt-4">
                      <div className="form-group mt-2 align-items-center m-auto">
                        <button type="submit" className="btn btn-primary">
                          {i18n.t("save")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.is_status}
          size="lg"
          onHide={() => {
            this.setState({ is_status: false });
          }}
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {user.role === "C" ? `${i18n.t("approve")} - ` : " "}
              {user.role === "RS" ? `${i18n.t("verify")} - ` : " "}
              {i18n.t("auction_request")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              id="UpdateAR"
              className={`card mb-0`}
              autoComplete="off"
              onSubmit={(e) => {
                this.handleStatusUpdate(e, user.role === "C" ? "A" : "V");
              }}
            >
              <div className="card-body bg-none p-0">
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {i18n.t("item_details")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={i18n.t("item_details")}
                        value={this.state.change.details}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">{i18n.t("region")}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={i18n.t("item_details")}
                        value={this.state.change?.region_info?.region_name}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {i18n.t("department")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={i18n.t("department")}
                        value={this.state.change?.department_info?.name}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">{i18n.t("location")}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={i18n.t("location")}
                        value={this.state.change?.location_info?.name}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">{i18n.t("category")}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={i18n.t("category")}
                        value={this.state.change?.category_info?.category_name}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">{i18n.t("quantity")}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={i18n.t("category")}
                        value={this.state.change?.quantity}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        {i18n.t("location_link")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={i18n.t("category")}
                        value={this.state.change?.location_link}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {i18n.t("attatchment")}
                      </label>
                      <a
                        className="form-control"
                        placeholder={i18n.t("category")}
                        href={this.state.change?.file_attatchment}
                        target="_new"
                      >
                        {i18n.t("view")} {i18n.t("attatchment")}
                      </a>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12">
                    <div className="form-group">
                      <label className="form-label m-0">
                        {i18n.t("remarks")}
                      </label>
                      <textarea
                        name="remarks"
                        className="form-control"
                        placeholder={i18n.t("remarks")}
                        rows={5}
                        onChange={this.handleStatusChange}
                        disabled={user.role === "C"}
                      >
                        {this.state.change.remarks}
                      </textarea>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 mt-1">
                    <div className="form-group d-flex align-items-center">
                      <button
                        type="submit"
                        className="btn btn-primary m-auto px-5"
                      >
                        {user.role === "C"
                          ? i18n.t("approve")
                          : i18n.t("submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
