import React from "react";
import { WalletFund } from "../helpers/walletFund";
import { HomeBanner } from "./homeBanner";
import { CustomTitle } from "../helpers/customTitle";
import i18n from "../../i18n";

export class WalletManage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        <div className="row m-auto pt-3 bg-white">
          {/* <CustomTitle
            title={i18n.t("wallet")}
            icon="wallet-icon.png"
            icon_type="img"
            need_mzad_icon={true}
          /> */}
          <div className="m-auto mt-5 mb-3 bg-white d-flex justify-content-center align-items-center gap-2">
            <h4 className="text-primary">
              <b>{i18n.t("wallet")}</b>
            </h4>
            <h6>- {i18n.t("add_fund")}</h6>
          </div>
          <div className="col-lg-6 col-xl-6 col-md-6 m-auto">
            <WalletFund />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
