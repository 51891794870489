import React from "react";
//import i18n from "../../i18n";

export class BankDetails extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="leading-Automatic border rounded-4 p-2 my-2"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <p className="pb-0 mb-1 fs-9">
            Account Name : ESTIDAMAH SMART SOLUTIONS LLC
          </p>
          <p className="pb-0 mb-1 fs-9">Account Number : 3140029348500</p>
          <p className="pb-0 mb-1 fs-9">SWIFT CODE : OMABOMRUXXX</p>
          <p className="pb-0 mb-1 fs-9">Currency : OMANI RIYAL</p>
          <p className="pb-0 fs-9">Bank/Branch : SHATI QURUM</p>
        </div>
      </React.Fragment>
    );
  }
}
