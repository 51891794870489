import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import {
  checkUserValidity,
  checkUserValidityGn,
  sendNotification,
  bidNow,
  getTopBidders,
  extraTimeCheck,
  toggleAutoBid,
} from "../../models/auctions";
import { getLoggedUser } from "../../state/user";
import { BiddersPosition } from "./biddersPosition";
import { Modal } from "react-bootstrap";
import { formatCurrency } from "../../state/utils";
import ToggleButton from "react-toggle-button";
import swal from "sweetalert";
import getServerTime from "../../models/server";

const is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
var countdownComplete = false;
const FinishBid = () => (
  <h6 className="h6 text-danger">{i18n.t("bid_time_ends")}</h6>
);
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    countdownComplete = true;
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <div className="d-flex justify-content-around align-items-center gap-2">
        <span className="sliderCountIcon">
          <i className="mdi mdi-clock" style={{ fontSize: "2rem" }} />
        </span>
        <span className="rounded-2 border d-flex align-items-center sliderCount p-1 border border-primary px-2">
          {days} {i18n.t("lbl_day")}
        </span>
        <span className="rounded-2 border d-flex align-items-center sliderCount p-1 border border-primary px-2">
          {hours} {i18n.t("lbl_hour")}
        </span>
        <span className="rounded-2 border d-flex align-items-center sliderCount p-1 border border-primary px-2">
          {minutes} {i18n.t("lbl_minute")}
        </span>
        <span className="rounded-2 border d-flex align-items-center sliderCount p-1 border border-primary px-2">
          {seconds} {i18n.t("lbl_second")}
        </span>
      </div>
    );
  }
};

let lang = localStorage.getItem("I18N_LANGUAGE");

export class BiddingComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_auto_bid_panel: false,
      is_positions_panel: false,
      is_saving: false,
      auto_bidding: false,
      bidTimeStopped: false,
      toggleArrow: false,
      bid_increment: 0,
      auto_bid_increment: 0,
      current_bid_amount: 0,
      user_bid_amount: 0,
      target_amount: 0,
      left_button_disable: false,
      is_bid_sending: false,
      bid_place_message: "",
      bid_place_error: false,
      is_loading: false,
      user_valid: false,
      date_out_of_range: false,
      need_enroll: true,
      bid_count: 0,
      winner: {},
      is_completed: false,
      auction_page: "",
      top_bidders: {},
      enroll_status: "",
      endDate: null,
      extra_time_message: false,
      increments: [1000, 2000, 5000, 10000, 15000, 20000],
      total_enrolls: 0,
      // now: () => {
      //   let time = new Date().toLocaleTimeString("en-US", {
      //     timeZone: "Asia/Muscat",
      //   });
      //   let dt = new Date().toISOString("en-US").split("T")[0];
      //   let newDt = dt + " " + time;
      //   newDt = new Date(newDt.replace(/-/g, "/"));
      //   return newDt;
      // },
      now: new Date(),
    };

    this.incrementNumber = this.incrementNumber.bind(this);
    this.decrementNumber = this.decrementNumber.bind(this);
    this.incrementAutoBidNumber = this.incrementAutoBidNumber.bind(this);
    this.decrementAutoBidNumber = this.decrementAutoBidNumber.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.bidNow = this.bidNow.bind(this);
    //this.isComplete = this.isComplete.bind(this);
    this.auctionFinished = this.auctionFinished.bind(this);
    this.checkUserAuctionValidity = this.checkUserAuctionValidity.bind(this);
    this.loadTopBidders = this.loadTopBidders.bind(this);
    this.checkTime = this.checkTime.bind(this);
    this.saveAutoBidOption = this.saveAutoBidOption.bind(this);
    this.incrementTime = this.incrementTime.bind(this);
  }

  componentDidMount() {
    let auction = this.props.auction;
    let bid_increment = 0;
    if (auction.bid_increment) {
      bid_increment = auction.bid_increment;
      this.setState({
        bid_increment: bid_increment,
      });
    }
    let auto_bid_increment = bid_increment;
    if (auction.auto_bid_increment) {
      auto_bid_increment = parseFloat(auction.auto_bid_increment);
    }
    this.setState({
      auto_bid_increment,
    });

    let start_amount = parseFloat(auction.start_amount);
    let current_bid_amount = parseFloat(start_amount);
    if (auction.latest_bid && auction.latest_bid.bid_amount) {
      current_bid_amount = parseFloat(auction.latest_bid.bid_amount);
    }
    let bid_count = auction.bid_count;
    let winner = auction.winner;
    let is_completed = auction.is_completed;
    let auction_page = this.props.auction_page;
    start_amount = parseFloat(start_amount) + parseFloat(bid_increment);
    let increments = auction.increment_numbers;
    this.setState({
      user_bid_amount: start_amount.toFixed(3),
      target_amount: start_amount.toFixed(2),
      current_bid_amount,
      is_loading: true,
      bid_count,
      winner,
      is_completed,
      auction_page,
      endDate: auction.end_date,
      increments: increments,
    });

    this.checkUserAuctionValidity();
    if (auction && auction.id) {
      this.loadTopBidders();
      let refreshInterval = setInterval(this.loadTopBidders, 10000);
      this.setState({ refreshInterval: refreshInterval });
    }
    if (auction && auction.target_amount) {
      this.setState({ target_amount: auction.target_amount });
    }
    this.setState({ auto_bidding: auction.enabled_auto_bidding });
    let intervalId = setInterval(this.checkTime, 5000);
    //let serverTimeId = setInterval(this.setServerTime, 1000);
    this.setState({ intervalId: intervalId });
    if (this.props.auction) {
      setInterval(this.incrementTime, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    clearInterval(this.state.refreshInterval);
  }

  incrementTime() {
    let { auction } = this.props;
    if (!auction) {
      console.error("auction is undefined in props");
      return;
    }
    let dt = new Date(auction.server_time);
    dt.setSeconds(dt.getSeconds() + 1);
    auction.server_time = dt;
    this.setState({ auction });
  }

  // async setServerTime() {
  //   try {
  //     let response = await getServerTime();
  //     let serverTime = new Date(response.server_time.replace(/-/g, "/"));
  //     this.setState({ now: serverTime });
  //   } catch (error) {
  //     console.error("Error fetching server time:", error);
  //   }
  // }

  async checkUserAuctionValidity() {
    let auction = this.props.auction;
    if (auction && auction.id) {
      this.setState({ is_loading: true });
      let response = await checkUserValidityGn({ auction: auction.id });
      if (is_logged) {
        response = await checkUserValidity({ auction: auction.id });
      }
      let data = response.data;
      if (data) {
        //this.setState({ is_blacklisted: data.blackList });
        this.setState({ user_valid: data.valid });
        this.setState({ date_out_of_range: data.date_out_of_range });
        this.setState({ is_loading: false });
        this.setState({ total_enrolls: data.total_enrolls });
        if (data.enroll) {
          this.setState({ need_enroll: false });
        }
        if (data.enroll_status && data.enroll_status !== "C") {
          this.setState({ enroll_status: data.enroll_status });
        }
      }
    }
  }

  // isComplete() {
  //   this.setState({ bidTimeStopped: true });
  //   //this.auctionFinished();
  // }
  async auctionFinished() {
    let auction = this.props.auction;
    await sendNotification({ auction: auction.id });
  }

  async checkTime() {
    console.log("Extra time check");
    let auction = this.props.auction;
    let endDate = this.state.endDate;

    let time = new Date().toLocaleTimeString("en-US", {
      timeZone: "Asia/Muscat",
    });
    let dt = new Date().toISOString("en-US").split("T")[0];
    let newDt = this.createDate(dt + " " + time);

    var diff = Math.abs(this.createDate(endDate) - newDt);
    var minutes = Math.floor(diff / 1000 / 60);
    console.log("minutes", minutes);
    if (auction && auction.id && minutes < 2) {
      const response = await extraTimeCheck({ auction: auction.id });
      let data = response.data;

      var g1 = new Date(endDate);
      var g2 = new Date(data.end_date);
      console.log("ex:data", data);
      if (
        data &&
        typeof data !== "undefined" &&
        typeof data.end_date !== "undefined" &&
        g1.getTime() !== g2.getTime() &&
        !this.state.bidTimeStopped
      ) {
        console.log("Extra time check", "success");
        console.log(endDate, data.end_date);
        this.createDate(data.end_date);
        this.setState({
          endDate: data.end_date,
          extra_time_message: true,
        });

        setTimeout(() => {
          this.setState({
            extra_time_message: false,
          });
        }, 3000);
      }
    }
  }

  handleChange(event) {
    let state = this.state;
    state[event.target.name] = event.target.value;
    this.setState({ state });
    if (event.target.name === "bid_increment") {
      this.setCurrentBidAmount(event.target.value);
    }
  }

  setCurrentBidAmount(new_incr) {
    let user_bid_amount = this.state.user_bid_amount;
    let current_amount = parseFloat(this.state.current_bid_amount);
    user_bid_amount = parseFloat(current_amount) + parseFloat(new_incr);
    this.setState({ user_bid_amount: user_bid_amount.toFixed(3) });
  }

  decrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) - parseFloat(bid_increment);
    let left_button_disable = false;
    let minAmount = parseFloat(current_amount) + parseFloat(bid_increment);
    if (user_bid_amount <= minAmount) {
      left_button_disable = true;
    }
    this.setState({
      user_bid_amount: user_bid_amount.toFixed(3),
      left_button_disable: left_button_disable,
    });
  }

  incrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) + parseFloat(bid_increment);
    let left_button_disable = true;
    if (user_bid_amount > current_amount) {
      left_button_disable = false;
    }
    this.setState({
      user_bid_amount: user_bid_amount.toFixed(3),
      left_button_disable: left_button_disable,
    });
  }

  decrementAutoBidNumber() {
    let {
      target_amount,
      current_bid_amount,
      bid_increment,
      auto_bid_increment,
    } = this.state;
    let minAmount = parseFloat(current_bid_amount) + parseFloat(bid_increment);
    target_amount = parseFloat(target_amount) - parseFloat(auto_bid_increment);
    console.log(target_amount, minAmount);
    if (target_amount <= minAmount) {
      swal(
        "Not Allowed",
        "Amount should be greater than current amount",
        "error"
      );
      return;
    }
    this.setState({
      target_amount: target_amount.toFixed(2),
    });
  }
  incrementAutoBidNumber() {
    let target_amount = parseFloat(this.state.target_amount);
    let bid_increment = parseFloat(this.state.auto_bid_increment);
    target_amount = parseFloat(target_amount) + parseFloat(bid_increment);
    this.setState({
      target_amount: target_amount.toFixed(2),
    });
  }

  async bidNow() {
    this.setState({ is_bid_sending: true });
    let auction = this.props.auction;
    let user_bid_amount = this.state.user_bid_amount;
    const response = await bidNow({ user_bid_amount, auction: auction.id });
    let data = response.data;
    let current_amount = data.current_amount;
    let message =
      lang === "ar" && response.message_ar && response.message_ar.trim() !== ""
        ? response.message_ar
        : response.message;
    this.setState({
      current_bid_amount: current_amount,
      bid_place_message: message,
      bid_place_error: data.bid_place_error,
    });

    if (data) {
      this.setState({ is_bid_sending: false });
      this.checkTime();
    }
    setTimeout(() => {
      this.setState({
        bid_place_message: "",
        bid_place_error: false,
      });
    }, 5000);

    this.loadTopBidders();
  }

  async loadTopBidders() {
    let auction = this.props.auction;
    if (auction) {
      this.setState({ loading: true });
      this.setState({ refresh_loading: true });
      const response = await getTopBidders({ auction: auction.id });
      let data = response.data;
      this.setState({ top_bidders: data });
      if (response) {
        this.setState({ refresh_loading: false });
      }
      if (response && response.meta && response.meta.bid_amount) {
        let current_bid_amount = parseFloat(response.meta.bid_amount);
        let bid_count = response.meta.bid_count;
        let user_bid_amount =
          parseFloat(current_bid_amount) + parseFloat(this.state.bid_increment);
        this.setState({
          current_bid_amount,
          user_bid_amount,
          bid_count,
          //target_amount: user_bid_amount,
        });
        if (
          this.props.updateRow &&
          typeof this.props.updateRow === "function"
        ) {
          this.props.updateRow(bid_count);
        }
      }
      if (response && response.meta && response.meta.myPosition) {
        let myBidData = response.meta.myPosition;
        this.setState({
          myPosition: myBidData.position,
          myPrice: myBidData.bid_amount,
        });
      }

      if (response && response.meta && response.meta.total_enrolls) {
        this.setState({ total_enrolls: response.meta.total_enrolls });
      }

      if (response && response.meta && response.meta.extra_time) {
        // let now = () => {
        //   let time = new Date().toLocaleTimeString("en-US", {
        //     timeZone: "Asia/Muscat",
        //   });
        //   let dt = new Date().toISOString("en-US").split("T")[0];
        //   let newDt = dt + " " + time;
        //   newDt = new Date(newDt.replace(/-/g, "/"));
        //   return newDt;
        // };
        // console.log("setting now");
        // this.setState({ now });
        this.setServerTime();
      }
    }
  }

  async saveAutoBidOption() {
    this.setState({ is_saving: true });
    let { target_amount, auto_bidding, auto_bid_increment } = this.state;
    if (!auto_bidding) {
      target_amount = null;
    }
    let { auction } = this.props;
    const response = await toggleAutoBid({
      target_amount,
      auto_bidding,
      auction: auction.id,
      increment: auto_bid_increment,
    });
    if (response.success) {
      this.setState({ is_saving: false });
    } else {
      this.setState({ is_saving: false });
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let auction = this.props.auction;
    let { auto_bidding } = this.state;
    let reg_status = auction.registartion_status;
    let now = this.state.now;
    let endTime = new Date(this.createDate(this.state.endDate)).getTime();
    let toggleClasses = "";
    if (this.state.toggleArrow) {
      toggleClasses =
        "nav nav-fill text-uppercase small position-relative flex-nowrap justify-content-end";
    } else {
      toggleClasses =
        "nav nav-fill text-uppercase small position-relative flex-nowrap";
    }
    let increments = this.state.increments;
    let total_enrolls = this.state.total_enrolls;
    return (
      <React.Fragment>
        <div className="card bg-none rounded-4 border-0 mt-2 bidding-comp">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <span>
              <img
                height="25"
                width="25"
                src={process.env.PUBLIC_URL + "/assets/icons/auc-icon.png"}
                alt=""
              />
            </span>
            <button className="bg-primary text-whilte p-2 rounded-3 border-0 px-5  px-1-mob">
              {i18n.t("start_bid_amount")} : {i18n.t("omr")}{" "}
              {formatCurrency(auction.start_amount)}
            </button>
            <button className="bg-primary text-whilte p-2 rounded-3 border-0 px-5  px-1-mob">
              {i18n.t("current_amount")} : {i18n.t("omr")}{" "}
              {formatCurrency(this.state.current_bid_amount)}
            </button>
          </div>
          {/* <span className="head-enroll">
                {i18n.t("enrolled_bidders")} : {total_enrolls}
              </span> */}
          <div className="card-body bg-none">
            {/* {this.state.extra_time_message && (
              <div className="alert alert-info">
                {i18n.t("extra_time_message")}
              </div>
            )} */}
            <div className="my-3">
              {this.state.endDate && (
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <Countdown
                    date={endTime}
                    renderer={renderer}
                    onComplete={() => {
                      this.setState({ bidTimeStopped: countdownComplete });
                    }}
                    now={() => new Date(auction.server_time)}
                  />
                  <button
                    className="d-flex gap-2 bg-none border-0 text-primary"
                    onClick={() => {
                      this.setState({
                        is_positions_panel: !this.state.is_positions_panel,
                      });
                    }}
                  >
                    <span>3</span>
                    <i className="mdi mdi-trophy" />
                  </button>
                </div>
              )}
            </div>
            {is_logged && auction.status !== "A" && (
              <div className="text text-danger text-center ">
                {i18n.t("not_active")}
              </div>
            )}
            {is_logged && auction.status === "A" && (
              <div>
                <div className="container mt-3 mb-2">
                  <div className="text-center text-primary my-3">
                    {i18n.t("bid_now")}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <a
                        href={true}
                        className="btn-left bg-none p-2 toggle text-dark"
                        onClick={(event) => {
                          this.setState({ toggleArrow: false });
                        }}
                      >
                        <i className="fa fa-chevron-left"></i>
                      </a>
                    </div>

                    <div
                      className="flex-grow-1 o-hidden bid-amounts"
                      style={{ width: "70%" }}
                    >
                      <ul
                        className={toggleClasses}
                        style={{ overflow: "scroll" }}
                      >
                        {increments.map((incr, i) => {
                          return (
                            <li className="nav-item" key={i}>
                              <button
                                className={
                                  Number(this.state.bid_increment) ===
                                  Number(incr)
                                    ? "btn bg-primary rounded-2 btn-block"
                                    : "btn bg-secondary rounded-2 btn-block"
                                }
                                onClick={() => {
                                  this.setState({
                                    bid_increment: incr,
                                  });
                                  this.setCurrentBidAmount(incr);
                                }}
                              >
                                {incr}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <hr />
                    <div className="flex-shrink-0">
                      <a
                        href={true}
                        className="btn-right bg-none toggle p-2 text-dark"
                        onClick={(event) => {
                          this.setState({ toggleArrow: true });
                        }}
                      >
                        <i className="fa fa-chevron-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="container mb-2">
                  <div className="form-group">
                    {/* <label className="form-label">
                      {i18n.t("latest_bid_amount")}
                    </label> */}
                    <div className="d-flex justify-content-between align-items-center bidding-comp-fields my-3">
                      <span className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-danger btn-number border border-dark rounded-5 mx-1"
                          disabled={this.state.left_button_disable}
                          onClick={this.decrementNumber}
                        >
                          <span className="glyphicon glyphicon-minus"></span>
                        </button>
                      </span>
                      <input
                        type="number"
                        name="user_bid_amount"
                        className="form-control input-number  border border-primary font-weight-bold text-center"
                        placeholder="Bid Amount"
                        value={this.state.user_bid_amount}
                        onChange={this.handleChange}
                        readOnly={true}
                        style={{ fontSize: "1.1rem" }}
                      />
                      <span className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-success btn-number border border-dark rounded-5 mx-1"
                          onClick={this.incrementNumber}
                        >
                          <span className="glyphicon glyphicon-plus"></span>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="container mb-2">
                  <div>
                    {is_logged &&
                      this.state.need_enroll &&
                      reg_status === "open" && (
                        <div>
                          {(this.state.enroll_status === "N" ||
                            this.state.enroll_status === "R" ||
                            this.state.enroll_status === "") && (
                            <div>
                              <div className="text text-center text-danger">
                                {i18n.t("msg_enroll_waiting")}
                              </div>
                              {!auction.is_enrolled && (
                                <Link
                                  to={"/enroll/" + auction.id}
                                  className="btn btn-primary btn-block mb-4 rounded-2"
                                >
                                  {i18n.t("enroll")}
                                </Link>
                              )}
                            </div>
                          )}

                          {this.state.enroll_status === "N" && (
                            <div>
                              <div className="text text-center text-danger">
                                Your enrollment verfication process is pending.
                                Please contact administrator.
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                    {this.state.enroll_status !== "A" &&
                      reg_status === "closed" && (
                        <div className="text text-center text-danger">
                          {i18n.t("registration_closed")}
                        </div>
                      )}

                    {is_logged && !this.state.need_enroll && (
                      <>
                        <div className="d-flex justify-content-start align-items-center gap-1 my-3">
                          <input
                            type="checkbox"
                            name="t_c"
                            checked={this.state.t_c_acept}
                            style={{ width: "18px", height: "18px" }}
                            onChange={(event) => {
                              this.setState({
                                t_c_acept: !this.state.t_c_acept,
                              });
                            }}
                          />
                          {i18n.t("agree_to_terms")}
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary btn-block mb-4 rounded-2"
                          onClick={this.bidNow}
                          disabled={
                            !this.state.t_c_acept ||
                            this.state.is_bid_sending ||
                            countdownComplete
                          }
                        >
                          {this.state.is_bid_sending && (
                            <span>
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Sending...
                            </span>
                          )}
                          {!this.state.is_bid_sending && (
                            <span>
                              <i className="fa fa-gavel"></i>&nbsp;
                              {i18n.t("bid_now")}
                            </span>
                          )}
                        </button>
                        {Boolean(auction.has_auto_bidding) && (
                          <div className="bg-none rounded-2">
                            <div className="">
                              <div className="d-flex justify-content-between align-items-center">
                                <span className="text-grey">
                                  {i18n.t("enable_auto_bidding")}
                                </span>{" "}
                                <ToggleButton
                                  inactiveLabel={i18n.t("no")}
                                  activeLabel={i18n.t("yes")}
                                  value={this.state.auto_bidding}
                                  className="form-control m-auto"
                                  onToggle={(value) => {
                                    this.setState({
                                      auto_bidding: !auto_bidding,
                                    });
                                  }}
                                />
                              </div>
                              {this.state.auto_bidding && (
                                <div className="row align-items-center bg-secondary border border-primary rounded-2 mt-1 p-2">
                                  <div className="col-md-4 col-sm-12">
                                    <span>{i18n.t("incr_amount")}</span>
                                    <select
                                      name="auto_bid_increment"
                                      value={this.state.auto_bid_increment}
                                      onChange={this.handleChange}
                                      className={
                                        !this.state.auto_bidding
                                          ? "form-control rounded-0 border border-primary rounded-2 text-center bg-disabled"
                                          : "form-control rounded-0 border border-primary rounded-2 text-center"
                                      }
                                      disabled={!this.state.auto_bidding}
                                      style={{ width: "60%" }}
                                    >
                                      {increments.map((incr, i) => {
                                        return (
                                          <option value={incr}>{incr}</option>
                                        );
                                      })}
                                    </select>
                                  </div>

                                  <div className="col-md-6 col-sm-12">
                                    <div>{i18n.t("target_amount")}</div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex align-items-center">
                                        <span className="input-group-btn">
                                          <button
                                            type="button"
                                            className="btn btn-default btn-number border border-primary rounded-0 mx-0"
                                            onClick={
                                              this.decrementAutoBidNumber
                                            }
                                            disabled={!this.state.auto_bidding}
                                          >
                                            <span className="glyphicon glyphicon-minus"></span>
                                          </button>
                                        </span>
                                        <input
                                          type="number"
                                          name="target_amount"
                                          className={
                                            !this.state.auto_bidding
                                              ? "form-control input-number rounded-0 border border-primary text-center bg-disabled"
                                              : "form-control input-number rounded-0 border border-primary text-center"
                                          }
                                          placeholder=""
                                          value={this.state.target_amount}
                                          onChange={this.handleChange}
                                          readOnly={true}
                                          disabled={!this.state.auto_bidding}
                                        />
                                        <span className="input-group-btn">
                                          <button
                                            type="button"
                                            className="btn btn-default btn-number border border-primary rounded-0 mx-0"
                                            onClick={
                                              this.incrementAutoBidNumber
                                            }
                                            disabled={!this.state.auto_bidding}
                                          >
                                            <span className="glyphicon glyphicon-plus"></span>
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 pt-3">
                                    <button
                                      type="button"
                                      className="btn btn-primary rounded-0"
                                      onClick={this.saveAutoBidOption}
                                    >
                                      {!this.state.is_saving && (
                                        <div>
                                          <span className="fa fa-save"></span>
                                          &nbsp;
                                          {i18n.t("save")}
                                        </div>
                                      )}
                                      {this.state.is_saving && (
                                        <div
                                          className="spinner-border spinner-border-sm text-light"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {this.state.bid_place_message && (
                      <div
                        className={
                          this.state.bid_place_error
                            ? "alert alert-danger"
                            : "alert alert-success"
                        }
                      >
                        {this.state.bid_place_message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {!is_logged && (
          <Link
            to="/login"
            className="btn btn-primary rounded-5 border btn-block custom-bg-color2 font-weight-bold"
          >
            {i18n.t("login_to_make_bids")}
          </Link>
        )}

        {is_logged && this.state.is_positions_panel && (
          <BiddersPosition
            top_bidders={this.state.top_bidders}
            loadTopBidders={this.loadTopBidders}
            refresh_loading={this.state.refresh_loading}
            user={user}
            total_enrolls={total_enrolls}
          />
        )}

        <Modal
          contentClassName="bg-primary"
          style={{ zIndex: "99999" }}
          size="sm"
          show={this.state.extra_time_message}
          onHide={() => this.setState({ extra_time_message: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-modal-sizes-title-lg"
              className="text-white"
            >
              {i18n.t("message")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="text-white">
            {i18n.t("extra_time_message")}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-light"
              onClick={() => this.setState({ extra_time_message: false })}
            >
              {i18n.t("close")}
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          contentClassName="rounded-2"
          style={{ zIndex: "99999" }}
          //size="sm"
          show={this.state.show_auto_bid_info}
          onHide={() => this.setState({ show_auto_bid_info: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg" className="h5">
              {i18n.t("information")} - {i18n.t("auto_bidding")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="text-info">
            {i18n.t("information_auto_bidding")}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
