import React from "react";
import { SideBar } from "./sidebar";
import i18n from "../../i18n";
import { getDashboardData } from "../../models/dashboard";
import { HomeBanner } from "../home/homeBanner";
import { DashboardPendingApproval } from "./helper/dashboard/client/pending_approval";
import { DashboardAuctionRequested } from "./helper/dashboard/client/auction_requested";
import { DashboardCompletedAuctions } from "./helper/dashboard/client/completed_auctions";
import { DashboardFinalAuctions } from "./helper/dashboard/client/final_auctions";
import { DashboardTotalSales } from "./helper/dashboard/client/total_sales";
import { getLocations } from "../../models/locations";

const lng = localStorage.getItem("I18N_LANGUAGE");

export class DashboardClient extends React.Component {
  constructor() {
    super();
    this.state = {
      panel_1: true,
      panel_2: false,
      panel_3: false,
      panel_4: false,
      panel_5: false,
      input: {},
      pending_approval: 0,
      auction_requested: 0,
      completed_auctions: 0,
      final_auctions_approved: 0,
      total_sales: 0,
      locations: [],
      loc_loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadDashboardData = this.loadDashboardData.bind(this);
    this.hideSideBar = this.hideSideBar.bind(this);
  }

  componentDidMount() {
    this.loadLocations();
    this.loadDashboardData();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    this.loadDashboardData({ location: event.target.value });
  }

  async loadLocations() {
    this.setState({ loc_loading: true });
    let response = await getLocations();
    if (response.success) {
      let { data } = response;
      this.setState({
        locations: data,
      });
      this.setState({ loc_loading: false });
    }
  }
  async loadDashboardData(data = false) {
    this.setState({ dd_loading: true });
    let { client, group, auction, location } = data;
    let input = this.state.input;
    input.client = typeof client !== "undefined" ? client : input.client;
    input.group = typeof group !== "undefined" ? group : input.group;
    input.auction = typeof auction !== "undefined" ? auction : input.auction;
    input.location =
      typeof location !== "undefined" ? location : input.location;

    let response = await getDashboardData(input);
    if (response.success) {
      let { data } = response;
      this.setState({
        pending_approval: data.pending_approval,
        auction_requested: data.auction_requested,
        completed_auctions: data.completed_auctions,
        final_auctions_approved: data.final_auctions_approved,
        total_sales: data.total_sales,
      });
      this.setState({ dd_loading: false });
    }
  }

  hideSideBar(is_side_bar) {
    this.setState({
      is_side_bar: is_side_bar,
    });
  }

  render() {
    let {
      pending_approval,
      auction_requested,
      completed_auctions,
      final_auctions_approved,
      total_sales,
    } = this.state;
    let { panel_1, panel_2, panel_3, panel_4, panel_5, locations } = this.state;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        <section className="sptb mt-3 mx-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.is_side_bar && <SideBar />}
              </div>
              <div
                className={
                  this.state.is_side_bar
                    ? "col-xl-12 col-lg-12 col-md-12 mt-1 dash-container"
                    : "col-xl-9 col-lg-12 col-md-12 mt-1 dash-container"
                }
              >
                <div className="d-flex justify-content-between mt-3 dash-container-head">
                  <h3 className="font-weight-bold">{i18n.t("dashboard")}</h3>
                  <div className="form-group">
                    <select
                      className="form-control border-primary"
                      data-placeholder="Select location"
                      name="location"
                      value={this.state.input.location}
                      onChange={this.handleChange}
                    >
                      <option value="">--{i18n.t("location")}--</option>
                      {locations.length > 0 &&
                        locations.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {lng === "ar" ? item.name_ar : item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="bg-white rounded-4 hide_div mb-3">
                  <div className="p-3">
                    <div className="p-2 user-brd rounded-4">
                      <div className="grid_container-cl">
                        <div
                          className={`outer_round1 flex_container ${
                            panel_1 ? "is-active" : ""
                          }`}
                          onClick={() => {
                            this.setState({
                              panel_1: !panel_1,
                              panel_2: false,
                              panel_3: false,
                              panel_4: false,
                              panel_5: false,
                            });
                          }}
                        >
                          <div className="inner_round">
                            <span>{pending_approval}</span>
                          </div>
                          <div className=" outer_text lh20">
                            <span>{i18n.t("pending")}</span>
                            {i18n.t("approval")}
                          </div>
                        </div>
                        <div
                          className={`outer_round2 flex_container ${
                            panel_2 ? "is-active" : ""
                          }`}
                          onClick={() => {
                            this.setState({
                              panel_1: false,
                              panel_2: !panel_2,
                              panel_3: false,
                              panel_4: false,
                              panel_5: false,
                            });
                          }}
                        >
                          <div className="inner_round">
                            <span>{auction_requested}</span>
                          </div>
                          <div className=" outer_text lh20">
                            <span>{i18n.t("auction")}</span>
                            {i18n.t("requested")}
                          </div>
                        </div>
                        <div
                          className={`outer_round3 flex_container ${
                            panel_3 ? "is-active" : ""
                          }`}
                          onClick={() => {
                            this.setState({
                              panel_1: false,
                              panel_2: false,
                              panel_3: !panel_3,
                              panel_4: false,
                              panel_5: false,
                            });
                          }}
                        >
                          <div className="inner_round">
                            <span>{completed_auctions}</span>
                          </div>
                          <div className=" outer_text lh20">
                            <span>{i18n.t("completed")}</span>
                            {i18n.t("auctions")}
                          </div>
                        </div>
                        <div
                          className={`outer_round4 flex_container ${
                            panel_4 ? "is-active" : ""
                          }`}
                          onClick={() => {
                            this.setState({
                              panel_1: false,
                              panel_2: false,
                              panel_3: false,
                              panel_4: !panel_4,
                              panel_5: false,
                            });
                          }}
                        >
                          <div className="inner_round">
                            <span>{final_auctions_approved}</span>
                          </div>
                          <div className=" outer_text lh20">
                            <span>{i18n.t("final_auction")}</span>
                            {i18n.t("approved")}
                          </div>
                        </div>
                        <div
                          className={`outer_round5 flex_container ${
                            panel_5 ? "is-active" : ""
                          }`}
                          onClick={() => {
                            this.setState({
                              panel_1: false,
                              panel_2: false,
                              panel_3: false,
                              panel_4: false,
                              panel_5: !panel_5,
                            });
                          }}
                        >
                          <div className="inner_round">
                            <span>{total_sales}</span>
                          </div>
                          <div className=" outer_text lh20">
                            <span>{i18n.t("total")}</span>
                            {i18n.t("sales")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {panel_1 && (
                  <div className="">
                    <DashboardPendingApproval
                      location={this.state.input.location}
                    />
                  </div>
                )}
                {panel_2 && (
                  <div className="">
                    <DashboardAuctionRequested
                      location={this.state.input.location}
                    />
                  </div>
                )}
                {panel_3 && (
                  <div className="">
                    <DashboardCompletedAuctions
                      location={this.state.input.location}
                    />
                  </div>
                )}
                {panel_4 && (
                  <div className="">
                    <DashboardFinalAuctions
                      location={this.state.input.location}
                    />
                  </div>
                )}
                {panel_5 && (
                  <div className="">
                    <DashboardTotalSales location={this.state.input.location} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
