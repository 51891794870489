import React from "react";
import {
  deleteUserNotification,
  getAllUserNotifications,
} from "../../models/notification";
import i18n from "../../i18n";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { SideBar } from "./sidebar";
import swal from "sweetalert";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#dadada",
      color: "#000000",
    },
  },
};
const lng = localStorage.getItem("I18N_LANGUAGE");
const btn_icons = {
  auctions: "fa fa-gavel",
  client_payment: "fa fa-money",
  refund: "fa fa-money",
};
//let serialNumber = 0;
export class MyNotifications extends React.Component {
  constructor() {
    super();
    this.state = {
      is_loading: false,
      items: [],
      input: {},
      notification_type: "",
      columns: [
        // {
        //   name: "ID",
        //   cell: (row) => row.id,
        //   sortable: true,
        //   width: "100px",
        // },
        // {
        //   name: "Sl#",
        //   cell: (row) => serialNumber++,
        //   sortable: true,
        //   width: "100px",
        // },
        {
          name: i18n.t("title"),
          sortable: true,
          width: "200px",
          cell: (row) => (lng === "ar" ? row.title_ar : row.title),
        },
        {
          name: i18n.t("description"),
          cell: (row) => (lng === "ar" ? row.description_ar : row.description),
        },
        {
          name: "",
          width: "200px",
          cell: (row) => {
            if (row.item_name === "auctions") {
              return (
                <div className="d-flex">
                  <button
                    className="btn btn-outline-info btn-sm"
                    onClick={() => {
                      window.location.href = "/auction-details/" + row.item_id;
                    }}
                  >
                    <i className={btn_icons[row.item_name]} />
                    &nbsp;
                    {i18n.t("view")}
                  </button>
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => {
                      this.deleteNotification(row.un_id);
                    }}
                  >
                    <i className="fa fa-times-circle" />
                    &nbsp;
                    {i18n.t("delete")}
                  </button>
                </div>
              );
            }
          },
        },
      ],
    };
    this.loadAllItems = this.loadAllItems.bind(this);
  }
  componentDidMount() {
    this.loadAllItems("");
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllItems("", qstring);
  };

  async loadAllItems(status) {
    this.setState({ is_loading: true });
    this.setState({ notification_type: status });
    let q = "";
    if (status) {
      q += `&status=${status}`;
    }
    const response = await getAllUserNotifications(q);
    if (response.success) {
      let items = response.data;
      this.setState({ items });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  deleteNotification(id) {
    swal(i18n.t("confirm_msg_continue"), {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then(async (value) => {
      const response = await deleteUserNotification({ id: id });
      if (response.success) {
        swal(
          i18n.t("success"),
          "Deleted the selected notification",
          "success",
          {
            buttons: false,
            timer: 2000,
          }
        );
        let { notification_type } = this.state;
        this.loadAllItems(notification_type);
      }
      return false;
    });
  }

  render() {
    let { items } = this.state;
    return (
      <React.Fragment>
        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      {i18n.t("notifications")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <button
                        className={
                          this.state.notification_type === ""
                            ? "btn btn-md btn-success rounded-0 mx-0 border"
                            : "btn btn-md btn-outline-success rounded-0 mx-0 border"
                        }
                        onClick={() => {
                          this.loadAllItems("");
                        }}
                      >
                        {i18n.t("all")}
                      </button>
                      <button
                        className={
                          this.state.notification_type === "N"
                            ? "btn btn-md btn-success rounded-0 mx-0 border"
                            : "btn btn-md btn-outline-success rounded-0 mx-0 border"
                        }
                        onClick={() => {
                          this.loadAllItems("N");
                        }}
                      >
                        {i18n.t("new")}
                      </button>
                      <button
                        className={
                          this.state.notification_type === "A"
                            ? "btn btn-md btn-success rounded-0 mx-0 border"
                            : "btn btn-md btn-outline-success rounded-0 mx-0 border"
                        }
                        onClick={() => {
                          this.loadAllItems("A");
                        }}
                      >
                        {i18n.t("read")}
                      </button>
                    </div>
                    {this.state.is_loading && (
                      <div className="text-center">
                        <Spinner animation="border" size="lg" />
                      </div>
                    )}

                    <div className="table-responsive ">
                      {items.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            title=""
                            fixedHeader
                            columns={this.state.columns}
                            data={items}
                            customStyles={customStyles}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            paginationPerPage={15}
                            paginationDefaultPage={this.state.currentPage}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Enrolls per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            onChangePage={this.handlePageChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
