import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import i18n from "../../i18n";
import DataTable from "react-data-table-component";
import {
  createBlacklist,
  deleteBlacklist,
  getAllBlacklists,
} from "../../models/blacklist";
import { HomeBanner } from "../home/homeBanner";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#dadada",
      color: "#000000",
    },
  },
};

export class BlackList extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      totalRows: 0,
      currentPage: 1,
      list: {
        page: 1,
        limit: 15,
      },
      errors: {},
      blacklist: [],
      managers: [],
      hse_users: [],
      collapse: true,
      enquiries: [{ label: "", value: "", label_ar: "", value_ar: "" }],
      columns: [
        {
          name: i18n.t("ID"),
          sortable: true,
          width: "70px",
          cell: (row) => row.id,
        },
        {
          name: i18n.t("Field"),
          sortable: true,
          cell: (row) => row.field,
        },
        {
          name: i18n.t("Value"),
          sortable: true,
          cell: (row) => row.value,
        },
        {
          cell: (row) => {
            return (
              <button
                className="btn btn-danger btn-sm text-white m-1"
                onClick={(e) => {
                  this.deleteRow(row.id);
                }}
              >
                <i className="fa fa-times"></i>&nbsp; Delete
              </button>
            );
          },
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    let { list } = this.state;
    let qstring = new URLSearchParams(list).toString();
    this.loadAllLists(qstring);
  }

  handlePageChange = (page) => {
    let { list } = this.state;
    let currentPage = page;
    list["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ list: list });
    let qstring = new URLSearchParams(list).toString();
    this.setState({ query: qstring });
    this.loadAllLists(qstring);
  };

  async loadAllLists(qstring) {
    const response = await getAllBlacklists(qstring);
    let blacklist = response.data;
    let meta = response.meta;
    this.setState({ blacklist });
    this.setState({ totalRows: meta.total });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async handleSubmit(event) {
    event.preventDefault();
    let { input } = this.state;
    if (!input.field) {
      swal("Failed", "Please select a field", "error");
      return;
    }
    if (!input.value) {
      swal("Failed", "Please enter a value", "error");
      return;
    }
    const formData = new FormData();
    formData.append("field", input.field);
    formData.append("value", input.value);
    const response = await createBlacklist(formData);
    if (response.success) {
      swal(i18n.t("success"), response.message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        this.setState({ input: {} });
        swal("Failed", response.message, "error");
      });
      let { list } = this.state;
      let qstring = new URLSearchParams(list).toString();
      this.loadAllLists(qstring);
    } else {
      let error_message = response.message;
      let resObject = response.data;
      for (const key in resObject) {
        if (Object.hasOwnProperty.call(resObject, key)) {
          let element = resObject[key];
          error_message += "\n" + element;
        }
      }
      swal("Failed", error_message, "error");
    }
  }

  deleteRow(id) {
    swal({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this data.?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = deleteBlacklist(id);
          console.log(response);
          swal(i18n.t("success"), "Item deleted", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((response) => {
              let { list } = this.state;
              let qstring = new URLSearchParams(list).toString();
              this.loadAllLists(qstring);
            })
            .catch((response) => {
              swal("Failed", "Something went wrong", "error");
            });
        } catch (error) {
          console.error("Error deleting saved card:", error);
        }
      }
    });
  }

  render() {
    let blacklist = this.state.blacklist;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <p style={{ textAlign: "right" }}>
                  <button
                    className="btn btn-primary"
                    onClick={(event) => {
                      this.setState({ collapse: !this.state.collapse });
                    }}
                  >
                    {this.state.collapse ? "Add Blacklist" : "Hide Panel"}
                  </button>
                </p>
                <div className={this.state.collapse ? "collapse" : ""}>
                  <form
                    id="UpdateProfile"
                    className="card mb-0"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="card-header">
                      <h3 className="card-title">Add to blacklist</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("Field")}
                            </label>
                            <select
                              className="form-control"
                              data-placeholder="Select Field"
                              name="field"
                              value={this.state.input.field}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              <option value="Mobile">Mobile</option>
                              <option value="Email">Email</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">Value</label>
                            <input
                              type="text"
                              name="value"
                              className="form-control"
                              placeholder="Enter a value"
                              value={this.state.input.value}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <button type="submit" className="btn btn-primary">
                              Add to Blacklist
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={(event) => {
                                this.setState({
                                  collapse: !this.state.collapse,
                                });
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <hr />
                </div>

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">Blacklisted Data</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {blacklist.length < 1 && (
                        <div>{i18n.t("no_data_found")}</div>
                      )}
                      <div className="table-responsive ">
                        {blacklist.length > 0 && (
                          <div className="table-wrapper">
                            <DataTable
                              title=""
                              fixedHeader
                              columns={this.state.columns}
                              data={blacklist}
                              customStyles={customStyles}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={this.state.totalRows}
                              paginationPerPage={this.state.list.limit}
                              paginationDefaultPage={this.state.currentPage}
                              paginationRowsPerPageOptions={[15, 25, 50, 100]}
                              paginationComponentOptions={{
                                rowsPerPageText: "Data per page:",
                                rangeSeparatorText: i18n.t("out"),
                              }}
                              onChangePage={this.handlePageChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
