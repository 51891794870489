import React from "react";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import { uploadAdditional, getAuction } from "../../models/auctions";
import { AuctionTabs } from "./helper/auction_tabs";
import { Spinner } from "react-bootstrap";
import { HomeBanner } from "../home/homeBanner";
import i18n from "../../i18n";
import FileInputNew from "../helpers/fileInputNew";

const allowedFormats = [
  "pdf",
  "doc",
  "docx",
  "txt",
  "jpg",
  "jpeg",
  "png",
  "bmp",
  "gif",
];

export class AdditionalPanel extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      auction: {},
      is_loading: false,
      is_loading_additional: false,
      auctionId: auctionId,
      input: {},
      errors: {},
      file_additional_information: null,
    };
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    this.loadAuction();
  }

  onFileChange = (event) => {
    let file_format = event.target.files[0].name.split(".").pop().toLowerCase();
    if (!allowedFormats.includes(file_format)) {
      swal("Failed", "Wrong file format", "error");
      return;
    }
    if (event.target.name === "file_additional_information") {
      this.setState({ file_additional_information: event.target.files[0] });
      this.uploadFile(event, "T");
    }
  };

  async loadAuction() {
    this.setState({ is_loading: true });
    const response = await getAuction(this.state.auctionId);
    if (response) {
      let auction = response.data;
      this.setState({ auction });
      this.setState({ is_loading: false });
    }
  }

  async uploadFile(event, fileType) {
    event.preventDefault();
    if (!this.state.auctionId || !event.target.files[0].name) {
      swal("Failed", "File Missing", "error");
      return;
    }
    const formData = new FormData();
    this.setState({ is_loading_additional: true });
    formData.append(
      "file_additional_information",
      event.target.files[0],
      event.target.files[0].name
    );
    formData.append("auction_id", this.state.auctionId);
    const response = await uploadAdditional(formData);
    if (response) {
      this.setState({ is_loading_additional: false });
      if (response.success) {
        this.setState({ auction: response.data });
      } else {
        swal(
          "Failed",
          "Some error occured!! Please make sure that you are uploading proper file.",
          "error"
        );
      }
    }
  }

  render() {
    let { auction } = this.state;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div
                className="col-xl-9 col-lg-12 col-md-12"
                style={{ padding: 0 }}
              >
                <AuctionTabs activeTab="additional" />

                <div className="container mt-2 bg-white border p-2 rounded-4 m-1">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-8 border p-3 rounded-3 m-2 mx-3">
                      <div>
                        <h4 className="h5">
                          {i18n.t("upload")} {i18n.t("additional_info")}
                        </h4>
                        <span style={{ fontSize: "0.8rem" }}>
                          ({i18n.t("allowed_formats")} :{" "}
                          {JSON.stringify(allowedFormats)})
                        </span>
                      </div>
                      <div className="form-group ">
                        <FileInputNew
                          className="form-control"
                          placeholder={i18n.t("choose_file")}
                          default_placeholder={i18n.t("choose_file")}
                          id="file_additional_information"
                          name="file_additional_information"
                          onChange={this.onFileChange}
                        />
                      </div>
                      {this.state.is_loading_additional && (
                        <div
                          style={{
                            width: "97%",
                            height: "60px",
                            background: "#f4f4f4",
                            marginTop: "0.5rem",
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                            textAlign: "center",
                            padding: "1rem",
                          }}
                        >
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!this.state.is_loading_additional &&
                        auction.file_additional_information && (
                          <div
                            style={{
                              width: "97%",
                              height: "60px",
                              background: "#f4f4f4",
                              marginTop: "0.5rem",
                              marginLeft: "0.5rem",
                              marginRight: "0.5rem",
                              textAlign: "center",
                              padding: "1rem",
                            }}
                          >
                            <a
                              target="_new"
                              href={auction.file_additional_information}
                              className="text-danger"
                            >
                              <i className="fa fa-file-o" />
                              &nbsp;
                              {i18n.t("view")}/{i18n.t("download")}
                            </a>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
