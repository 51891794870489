import { apiUrl } from "../constants/global";
import i18n from "../i18n";
import { getLoggedUser } from "../state/user";

const statuses = {
  A: i18n.t("approved"),
  N: "Not Approved",
  D: "Deleted",
};

const roles = {
  A: i18n.t("administrator"),
  CE: i18n.t("ceo"),
  GM: i18n.t("general_manager"),
  M: i18n.t("manager"),
  C: i18n.t("rop_admin"),
  RS: i18n.t("rop_staff"),
  PM: i18n.t("project_manager"),
  OM: i18n.t("operation_manager"),
  U: i18n.t("user"),
  HSE: i18n.t("hse_user"),
  F: i18n.t("finance_manager"),
};
const role_section_1 = {
  A: i18n.t("administrator"),
  C: i18n.t("client"),
  M: i18n.t("manager"),
  PM: i18n.t("project_manager"),
  OM: i18n.t("operation_manager"),
};
const role_section_2 = {
  CE: i18n.t("ceo"),
  GM: i18n.t("general_manager"),
};

const user = getLoggedUser();

async function getUsers(q) {
  return fetch(apiUrl + "users?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getUsersByRoles(q) {
  return fetch(apiUrl + "users_by_roles?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getUser(id) {
  return fetch(apiUrl + "users/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function getUserInfo(id) {
  return fetch(apiUrl + "users/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function createUser(payload) {
  return fetch(apiUrl + "users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function updateUser(payload, id) {
  return fetch(apiUrl + "users/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteUser(id) {
  return fetch(apiUrl + "users/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getUserChartData() {
  return fetch(apiUrl + "user_reg_chart_data", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function googleLogin(payload) {
  return fetch(apiUrl + "google/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function facebookLogin(payload) {
  return fetch(apiUrl + "facebook/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function sendBulkMail(payload) {
  return fetch(apiUrl + "bulk_mail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function sendProfileOTP() {
  return fetch(apiUrl + "send_profile_otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function verifyOTP(payload) {
  return fetch(apiUrl + "verify_otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function sendOTP(type) {
  return fetch(apiUrl + "send_otp/" + type, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    //body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function verifyOTPCreds(payload) {
  return fetch(apiUrl + "verify_otp_creds", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getWalletUsers() {
  return fetch(apiUrl + "wallet_users", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

function approveClient(id) {
  return fetch(apiUrl + "approve_client", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify({ status: 1, id: id }),
  }).then((data) => data.json());
}

async function getAucApprvUsers(q) {
  return fetch(apiUrl + "auc_appr_users?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createUser,
  getUsers,
  deleteUser,
  getUser,
  updateUser,
  getUserChartData,
  googleLogin,
  facebookLogin,
  sendBulkMail,
  sendProfileOTP,
  verifyOTP,
  sendOTP,
  verifyOTPCreds,
  getUserInfo,
  getUsersByRoles,
  getWalletUsers,
  approveClient,
  getAucApprvUsers,
  statuses,
  roles,
  role_section_1,
  role_section_2,
};
