import React from "react";
import i18n from "../../../i18n";
import { getAllAuctionsByGroup } from "../../../models/auctions";
import { MzadLoader } from "../../helpers/mzadLoader";

const tableHeaderStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "0.5rem 0.5rem",
  border: "1px solid #efefef",
  fontSize: "0.8rem",
};
const tableCellStyle = {
  border: "1px solid #efefef",
  fontSize: "0.8rem",
};
const statuses = {
  A: i18n.t("completed"),
  E: i18n.t("expired"),
  N: i18n.t("new"),
  undefined: i18n.t("unknown"),
};
class AuctionsListingPop extends React.Component {
  constructor() {
    super();
    this.state = {
      group: {},
      auctions: [],
      loading: false,
      selected_location: false,
    };
    this.loadAllAuctions = this.loadAllAuctions.bind(this);
  }

  componentDidMount() {
    this.setState({ group: this.props.group });
    this.loadAllAuctions();
  }

  async loadAllAuctions() {
    this.setState({ loading: true });
    let { group } = this.props;
    const response = await getAllAuctionsByGroup(group.id);
    if (response.success) {
      this.setState({ auctions: response.data });
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { auctions, loading } = this.state;
    return (
      <>
        <div
          className="rounded-3 table-responsive"
          style={{
            maxHeight: "300px",
            overflow: "scroll",
            padding: "1rem",
          }}
        >
          <MzadLoader
            animation="grow"
            size="md"
            loading={loading}
            style={{ position: "relative" }}
          />
          {!loading && (
            <table className="table table-stripped table-sm">
              <thead>
                <tr>
                  <th style={tableHeaderStyle}>{i18n.t("sl_no")}</th>
                  <th style={tableHeaderStyle}>{i18n.t("ID")}</th>
                  <th style={tableHeaderStyle}>{i18n.t("title")}</th>
                  <th style={tableHeaderStyle}>{i18n.t("start_date")}</th>
                  <th style={tableHeaderStyle}>{i18n.t("end_date")}</th>
                  <th style={tableHeaderStyle}>{i18n.t("status")}</th>
                </tr>
              </thead>
              <tbody>
                {auctions.map((item, i) => (
                  <tr key={item.id}>
                    <td style={tableCellStyle}>{i + 1}</td>
                    <td style={tableCellStyle}>{item?.id}</td>
                    <td style={{ ...tableCellStyle, textWrap: "nowrap" }}>
                      {item?.title}
                    </td>
                    <td style={tableCellStyle}>{item?.start_date_formatted}</td>
                    <td style={tableCellStyle}>{item?.end_date_formatted}</td>
                    <td style={tableCellStyle}>{statuses[item?.status]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </>
    );
  }
}

export default AuctionsListingPop;
