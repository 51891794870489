import React from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import i18n from "../i18n";
import { getCategories } from "../models/categories";
import { getAllAuctions } from "../models/auctions";
import { getOrganizations } from "../models/organization";
import { Spinner } from "react-bootstrap";
import { AuctionCardHorz } from "./home/helpers/auctionCardHorz";
import { AuctionCard } from "./home/helpers/auctionCard";
import { MzadLoader } from "./helpers/mzadLoader";
import { HomeBanner } from "./home/homeBanner";
import { AuctionRowGrouped } from "./home/helpers/auctionRowGrouped";
import DatePicker from "react-date-picker";
import Pagination from "./helpers/pagination";

let current_lng = localStorage.getItem("I18N_LANGUAGE");
const CalendarIcon = () => {
  return (
    <span className="date-icon">
      <i className="mdi mdi-calendar text-success" />
    </span>
  );
};
const ClearIcon = () => {
  return <fa className="mdi mdi-close" />;
};
export class Auctions extends React.Component {
  constructor() {
    super();
    this.state = {
      total: 0,
      pagination: {
        limit: 30,
        page: 1,
      },
      catArrs: [],
      subCatArrs: [],
      categories: [],
      orgArrs: [],
      organizations: [],
      auctions: [],
      is_loading: false,
      is_cat_loading: false,
      lightboxImages: [],
      is_active_light_box: false,
      grid: false,
      section: "",
      type: "",
      category: "",
      organization: "",
      expand_orgs: false,
      expand_cats: false,
      input: {},
      vehicle_categories: [],
      //section: new URLSearchParams(this.props.location.search).get("sec"),
    };
    this.getAllAuctionsList = this.getAllAuctionsList.bind(this);
    this.getImagePopup = this.getImagePopup.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    this.loadAllCategories(params);
    this.loadAllOrganizations(params);
    this.getAllAuctionsList(params);
    if (params.sec === "vehicle") {
      this.loadVehicleCategories();
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleDateChange(date, name) {
    let input = this.state.input;
    if (date) {
      const dateObject = new Date(date);
      const day = dateObject.getDate();
      const month = dateObject.getMonth() + 1;
      const year = dateObject.getFullYear();
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDate =
        `${year}-${formattedMonth}-${formattedDay}`.toString();
      input[name] = formattedDate;
      this.setState({ input, [name]: date });
    } else {
      input[name] = "";
      this.setState({ input, [name]: null });
    }
  }

  async loadAllCategories(params) {
    this.setState({ is_cat_loading: true });
    if (typeof params.category !== "undefined") {
      var category = params.category;
      this.setState({ catArrs: [parseInt(category)] });
    }
    const response = await getCategories("active=1");
    if (response) {
      let categories = response.data;
      this.setState({ categories });
      this.setState({ is_cat_loading: false });
    }
  }

  async loadVehicleCategories() {
    const response = await getCategories("vehicles=1");
    if (response) {
      let vehicle_categories = response.data;
      this.setState({ vehicle_categories });
    }
  }

  async loadAllOrganizations(params) {
    this.setState({ is_org_loading: true });
    if (typeof params.organization !== "undefined") {
      var org = params.organization;
      this.setState({ orgArrs: [parseInt(org)] });
    }
    let q = params.type === "active" ? "is_active=1" : "";
    const response = await getOrganizations(q);
    if (response) {
      let organizations = response.data;
      this.setState({ organizations });
      this.setState({ is_org_loading: false });
    }
  }

  async getAllAuctionsList(params) {
    this.setState({ is_loading: true });
    let q = "";
    if (typeof this.state.pagination.limit !== "undefined") {
      q += "limit=" + this.state.pagination.limit;
    }
    if (typeof this.state.pagination.page !== "undefined") {
      q += "&page=" + this.state.pagination.page;
    }
    if (typeof params.sec !== "undefined") {
      q += "&section=" + params.sec;
    }
    if (typeof params.type !== "undefined") {
      q += "&type=" + params.type;
    }
    if (typeof params.category !== "undefined") {
      q += "&category=" + params.category;
    }
    if (typeof params.department !== "undefined") {
      q += "&department=" + params.department;
    }
    if (typeof params.location !== "undefined") {
      q += "&location=" + params.location;
    }
    if (typeof params.organization !== "undefined") {
      q += "&organization=" + params.organization;
    }
    if (typeof params.search_text !== "undefined") {
      q += "&text=" + params.search_text;
    }
    if (typeof params.is_end !== "undefined") {
      q += "&is_end=" + params.is_end;
    }
    q += "&lang=" + current_lng;

    let { fdate, tdate } = this.state.input;
    if (fdate && typeof fdate !== "undefined") {
      q += "&fdate=" + fdate;
    }
    if (tdate && typeof tdate !== "undefined") {
      q += "&tdate=" + tdate;
    }

    this.setState({
      section: params.sec,
      type: params.type,
      category: params.category,
      organization: params.organization,
    });

    const response = await getAllAuctions(q);
    if (response) {
      let { meta, data } = response;
      this.setState({ auctions: data, is_loading: false, total: meta.total });
    }
  }

  createCategory(id, sub = false) {
    if (sub) {
      var subCatArrs = this.state.catArrs;
      if (subCatArrs.includes(id)) {
        const index = subCatArrs.indexOf(parseInt(id));
        if (index > -1) {
          subCatArrs.splice(index, 1);
        }
      } else {
        subCatArrs.push(parseInt(id));
      }
      this.setState({ subCatArrs: subCatArrs });
      this.applyFilters();
    } else {
      if (!id) {
        this.setState({ catArrs: [] });
        this.applyFilters();
        return;
      }
      var catArrs = this.state.catArrs;
      if (catArrs.includes(id)) {
        const index = catArrs.indexOf(parseInt(id));
        if (index > -1) {
          catArrs.splice(index, 1);
        }
      } else {
        catArrs.push(parseInt(id));
      }
      this.setState({ catArrs: catArrs });
      this.applyFilters();
    }
  }

  applyFilters() {
    var { type, section, pagination } = this.state;
    let { input } = this.state;

    var { catArrs, subCatArrs } = this.state;

    var cats = catArrs.join(",");
    if (catArrs.length < 1) {
      cats = "";
    }

    var vehicle_category = subCatArrs.join(",");
    if (subCatArrs.length < 1) {
      vehicle_category = "";
    }

    pagination.limit = 15;
    pagination.page = 1;
    this.setState({ pagination });

    this.setState({
      category: cats,
      vehicle_category: cats,
      type: type,
      section: section,
      organization: input.organization,
      text: input.auction,
    });

    this.getAllAuctionsList({
      sec: section,
      type: type,
      category: cats,
      vehicle_category: cats,
      organization: input.organization,
      search_text: input.search_text,
    });
  }

  getImagePopup(active, images) {
    let lightboxImages = [];
    if (images) {
      images.forEach(function (arrayItem) {
        lightboxImages.push({
          url: arrayItem.image,
        });
      });
      this.setState({
        is_active_light_box: active,
        lightboxImages: lightboxImages,
      });
    }
  }

  handlePageChange = (pageNumber) => {
    let { pagination } = this.state;
    pagination.page = pageNumber;
    this.setState({ pagination });
    this.getAllAuctionsList({
      sec: this.state.section,
      type: this.state.type,
      category: this.state.category,
      vehicle_category: this.state.category,
      organization: this.state.organization,
    });
  };

  render() {
    let {
      categories,
      vehicle_categories,
      organizations,
      auctions,
      catArrs,
      subCatArrs,
    } = this.state;
    //let orgArrs = this.state.orgArrs;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        <div className="row overflow-hidden" id="auctions-list">
          <div className="head-section m-auto mt-5 bg-white">
            <span className="text-primary px-5">
              {this.state.type === "active" && i18n.t("active_auctions")}
              {this.state.type === "upcoming" && i18n.t("upcoming_auctions")}
              {this.state.type === "previous" && i18n.t("previous_auctions")}
              {this.state.type === "featured" && i18n.t("featured_auctions")}
              {this.state.section === "main" &&
                typeof this.state.type === "undefined" &&
                i18n.t("auctions")}
              {this.state.section === "vehicle" && i18n.t("vehicle_auctions")}
              {!this.state.section && i18n.t("auctions")}
            </span>
          </div>

          <section className="bg-white">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  {/*Lists*/}
                  <div className=" mb-0">
                    <div className>
                      <div className="item2-gl " id="auction-grouped">
                        <div
                          className="showing-auction m-auto"
                          style={{ width: "100%" }}
                        >
                          <span className="showing-label">
                            {i18n.t("showing")}{" "}
                            {this.state.is_loading ? "0" : auctions.length}{" "}
                            {i18n.t("auctions")}
                          </span>
                        </div>
                        <div
                          className="button-sort-section m-auto"
                          style={{ width: "100%" }}
                        >
                          {/* <div className="dropdown search-section-dropdown">
                            <button
                              className="btn btn-secondary dropdown-toggle burger-menu"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={() => {
                                this.setState({
                                  show_cat_dd: !this.state.show_cat_dd,
                                });
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/burger menu.png"
                                }
                                width="30"
                                alt=""
                              />
                            </button>
                            <ul
                              className={
                                this.state.show_cat_dd
                                  ? "dropdown-menu d-block"
                                  : "dropdown-menu"
                              }
                            >
                              {categories.map((item, i) => {
                                return (
                                  <li>
                                    <a
                                      className={
                                        catArrs.includes(item.id)
                                          ? "dropdown-item active"
                                          : "dropdown-item"
                                      }
                                      href={true}
                                      onClick={() => {
                                        this.createCategory(item.id);
                                        this.setState({
                                          show_cat_dd: false,
                                        });
                                      }}
                                    >
                                      {current_lng === "ar"
                                        ? item.category_name_ar
                                        : item.category_name}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div> */}
                          {/* <div className="buttons-section justify-content-start">
                            <button
                              className={
                                catArrs.length === 0
                                  ? "btn btn-primary sort-box-btn active"
                                  : "btn btn-primary sort-box-btn"
                              }
                              type="button"
                              onClick={() => {
                                this.createCategory(false);
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/All btn.png"
                                }
                                width="20"
                                alt="All"
                              />
                              <span>{i18n.t("all")}</span>
                            </button>
                            <div className="category-buttons">
                              {categories.map((item, i) => {
                                return (
                                  <button
                                    type="button"
                                    className={
                                      catArrs.includes(item.id)
                                        ? "btn btn-primary sort-box-btn active"
                                        : "btn btn-primary sort-box-btn"
                                    }
                                    onClick={() => {
                                      this.createCategory(item.id);
                                    }}
                                  >
                                    <img
                                      src={item.file_category_image}
                                      width="20"
                                      alt="All"
                                    />
                                    <span>
                                      {current_lng === "ar"
                                        ? item.category_name_ar
                                        : item.category_name}
                                    </span>
                                  </button>
                                );
                              })}
                            </div>
                          </div> */}
                          <div className="sort-section">
                            <button
                              className="sort-button sbtn-1"
                              onClick={() => {
                                this.setState({
                                  show_filter_pop: !this.state.show_filter_pop,
                                });
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/sort.png"
                                }
                                width="15"
                                alt="sort"
                              />
                            </button>
                            <div className="search">
                              <input
                                type="text"
                                name="search_text"
                                className="bg-none border-0"
                                placeholder={i18n.t(
                                  "search_by_name_loc_seller"
                                )}
                                value={this.state.input.search_text}
                                onChange={this.handleChange}
                              />
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/search.png"
                                }
                                width="20"
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={this.applyFilters}
                              />
                            </div>
                            {/* <button className="sort-button-right">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/sort-icon.png"
                                }
                                width="20"
                                alt=""
                              />
                              <span>{i18n.t("sort")}</span>
                            </button> */}
                            <button
                              className={
                                this.state.grid
                                  ? "sort-button sbtn-1 d-mob-none"
                                  : "sort-button sbtn-2 d-mob-none"
                              }
                              onClick={() => {
                                this.setState({ grid: true });
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/grid.png"
                                }
                                width="20"
                                alt="sort"
                                className={this.state.grid ? "grid-invert" : ""}
                              />
                            </button>
                            <button
                              className={
                                !this.state.grid
                                  ? "sort-button sbtn-1 d-mob-none"
                                  : "sort-button sbtn-2 d-mob-none"
                              }
                              onClick={() => {
                                this.setState({ grid: false });
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/burger menu.png"
                                }
                                width="20"
                                alt="sort"
                                className={
                                  !this.state.grid ? "" : "grid-invert"
                                }
                              />
                            </button>
                          </div>
                        </div>
                        {this.state.show_filter_pop && (
                          <div>
                            <div className="row border mx-1 mt-1 p-1 rounded-2 bg-light">
                              <div className="col-xl-3 col-lg-3 col-md-3 d-flex justify-content-center align-items-center">
                                {/* <div className="datepicker-toggle">
                                  <span className="datepicker-toggle-button"></span>
                                  <input
                                    type="date"
                                    className="form-control border rounded-5 datepicker-input"
                                    placeholder={i18n.t("from_date")}
                                    name="fdate"
                                    value={this.state.input.fdate}
                                    onChange={this.handleChange}
                                  />
                                </div> */}
                                <label>{i18n.t("from_date")}</label>
                                <DatePicker
                                  placeholder={i18n.t("from_date")}
                                  name="fdate"
                                  className="p-1"
                                  calendarClassName="calendar-wrapper p-1"
                                  value={this.state.fdate}
                                  selected={this.state.input.fdate}
                                  onChange={(e) => {
                                    this.handleDateChange(e, "fdate");
                                  }}
                                  format="dd/MM/y"
                                  calendarIcon={<CalendarIcon />}
                                  clearIcon={<ClearIcon />}
                                />
                              </div>

                              <div className="col-xl-3 col-lg-3 col-md-3 d-flex justify-content-center align-items-center">
                                {/* <div className="datepicker-toggle">
                                  <span className="datepicker-toggle-button"></span>
                                  <input
                                    type="date"
                                    className="form-control border rounded-5 datepicker-input"
                                    placeholder={i18n.t("to_date")}
                                    name="tdate"
                                    value={this.state.input.tdate}
                                    onChange={this.handleChange}
                                  />
                                </div> */}
                                <label>{i18n.t("to_date")}</label>
                                <DatePicker
                                  placeholder={i18n.t("from_date")}
                                  name="tdate"
                                  className="p-1"
                                  calendarClassName="calendar-wrapper "
                                  value={this.state.tdate}
                                  selected={this.state.input.tdate}
                                  onChange={(e) => {
                                    this.handleDateChange(e, "tdate");
                                  }}
                                  format="dd/MM/y"
                                  calendarIcon={<CalendarIcon />}
                                  clearIcon={<ClearIcon />}
                                />
                              </div>
                              {/* <div className="col-xl-3 col-lg-3 col-md-3">
                                <select
                                  className="form-control border rounded-5"
                                  data-placeholder="Select organization"
                                  name="organization"
                                  value={this.state.input.organization}
                                  onChange={this.handleChange}
                                >
                                  <option value="">{i18n.t("seller")}</option>
                                  {!this.state.is_org_loading &&
                                    organizations.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {current_lng === "ar"
                                            ? item.organization_name_ar
                                            : item.organization_name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div> */}
                              <div className="col-xl-3 col-lg-3 col-md-3 d-flex justify-content-center align-items-center">
                                {(this.state.input.fdate ||
                                  this.state.input.tdate ||
                                  this.state.input.organization) && (
                                  <button
                                    className="btn btn-outline-danger btn-sm p-1 px-2"
                                    onClick={() => {
                                      let { input } = this.state;
                                      input.fdate = "";
                                      input.tdate = "";
                                      input.organization = "";
                                      this.setState({
                                        input,
                                        fdate: "",
                                        tdate: "",
                                      });
                                      this.applyFilters();
                                    }}
                                  >
                                    <i className="fa fa-times" />
                                  </button>
                                )}

                                <a
                                  href={true}
                                  className="btn btn-primary btn-block rounded-1"
                                  onClick={() => {
                                    this.applyFilters();
                                  }}
                                >
                                  {i18n.t("search")}
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                        {vehicle_categories &&
                          vehicle_categories.length > 0 && (
                            <div className="mt-3 border p-2 rounded-2">
                              <h5>{i18n.t("category")}</h5>
                              <div className="d-flex">
                                {vehicle_categories.map((item, i) => {
                                  return (
                                    <a
                                      className={
                                        subCatArrs.includes(item.id)
                                          ? "btn btn-outline-danger btn-sm rounded-3 px-3 py-3 text-danger"
                                          : "btn btn-outline-dark btn-sm rounded-3 px-3 py-3 text-dark "
                                      }
                                      href={true}
                                      onClick={() => {
                                        this.createCategory(item.id, true);
                                        this.setState({
                                          show_cat_dd: false,
                                        });
                                      }}
                                      style={{ width: "115px" }}
                                    >
                                      <img
                                        src={item.icon}
                                        width="15"
                                        alt="All"
                                        className="mx-1"
                                      />
                                      <br />
                                      {current_lng === "ar"
                                        ? item.category_name_ar
                                        : item.category_name}
                                    </a>
                                  );
                                })}
                              </div>
                              {/* <div className="pagination mx-2 my-3">
                            Page 1 of 1
                          </div> */}
                            </div>
                          )}
                        {this.state.total > this.state.pagination.limit && (
                          <div>
                            <Pagination
                              currentPage={this.state.pagination.page}
                              totalPages={this.state.total}
                              onPageChange={this.handlePageChange}
                            />
                          </div>
                        )}
                        <div className="tab-content rounded-4 mb-3 mt-3">
                          <div className="tab-pane active" id="tab-11">
                            <MzadLoader
                              loading={this.state.is_loading}
                              size="md"
                            />
                            <div>
                              {!this.state.is_loading &&
                                !this.state.grid &&
                                auctions.length > 0 &&
                                auctions.map((item, i) => {
                                  let _data = {
                                    auction: item,
                                    auctionId: item.id,
                                    total_items: auctions.length,
                                    company: "Mzadcom",
                                    price: item.start_amount,
                                    title: item.title,
                                    title_ar: item.title_ar,
                                    description: "Diesel Generators",
                                    curr_amount: item.current_amount,
                                    end_date: item.end_date_formatted,
                                    start_date: item.start_date_formatted,
                                    images: item.images,
                                    total_views: item.total_views,
                                    type: this.state.type,
                                    can_bid: false,
                                    status: item.status,
                                  };

                                  return (
                                    <AuctionRowGrouped
                                      key={i}
                                      {..._data}
                                      getImagePopup={this.getImagePopup}
                                    />
                                  );
                                })}
                            </div>

                            <div className="row">
                              {!this.state.is_loading &&
                                this.state.grid &&
                                auctions.length > 0 &&
                                auctions.map((item, i) => {
                                  let _data = {
                                    auction: item,
                                    auctionId: item.id,
                                    total_items: auctions.length,
                                    company: "Mzadcom",
                                    price: item.start_amount,
                                    title: item.title,
                                    title_ar: item.title_ar,
                                    description: "Diesel Generators",
                                    curr_amount: item.current_amount,
                                    end_date: item.end_date_formatted,
                                    start_date: item.start_date_formatted,
                                    images: item.images,
                                    total_views: item.total_views,
                                    type: this.state.type,
                                    can_bid: false,
                                    status: item.status,
                                  };

                                  return (
                                    <div className="col-md-4">
                                      <AuctionCard
                                        key={i}
                                        {..._data}
                                        getImagePopup={this.getImagePopup}
                                        isDetailsPage={true}
                                      />
                                    </div>
                                  );
                                })}
                            </div>

                            {!this.state.is_loading && !auctions.length && (
                              <div className="help text-danger text-center">
                                {i18n.t("no_auctions_found")}
                              </div>
                            )}
                          </div>
                        </div>
                        {this.state.total > this.state.pagination.limit && (
                          <div>
                            <Pagination
                              currentPage={this.state.pagination.page}
                              totalPages={this.state.total}
                              onPageChange={this.handlePageChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*/Lists*/}
                </div>
              </div>
            </div>
          </section>
        </div>

        {this.state.is_active_light_box && (
          <Lightbox
            images={this.state.lightboxImages}
            onClose={() => {
              this.setState({ is_active_light_box: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
